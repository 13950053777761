import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { FreeFluidField, TestTableName } from '../stage-test-results/stage-test-results.constant';
import { IFactsAvailableSlurry } from 'libs/models/ifact/ifacts-available-slurry';

@Component({
  selector: 'free-fluid-test-result',
  templateUrl: './free-fluid-test-result.component.html',
  styleUrls: ['./free-fluid-test-result.component.scss']
})
export class FreeFluidTestResultComponent extends PumpScheduleTestResultBase {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
  }
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
  }
  @Input() canEdit: boolean;
  // eslint-disable-next-line
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();

  FreeFluidField = FreeFluidField;

  get test(): PumpScheduleStageTest | null {
    if (this.tests.length === 1 && this.tests[0]) {
        return this.tests[0];
    }

    const validatedOrFinishedTests = this.tests.filter(this.isTestFinishedOrValidated);

    if (validatedOrFinishedTests.length === 1 && validatedOrFinishedTests[0]) {
      return validatedOrFinishedTests[0];
    }

    return null;
  }

  constructor() {
    super(TestTableName.FreeFluidAPI10B2);
  }

  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }
}
