import { Injectable } from "@angular/core";
import { HttpService } from ".";
import { Observable, map } from "rxjs";
import { ChemicalSearchCriteriaModel, ISAPMaterialModel, ISAPMaterialProduct, ISapProfitCenterHierarchyModel, SapMaterialProfitCenterSearchModel, LocationFilterType, ChemicalSearchResultsFilter } from "libs/models";
import { environment } from "libs/environment";



@Injectable()
export class ChemicalSearchService {
    constructor(
        private httpService: HttpService
    ) {
    }

    getSapMaterialDescriptionByKeyword(keyword: string): Observable<ISAPMaterialProductModel[]> {
        const key = encodeURI(keyword);
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/sap-material?key=${key}`);
    }

    getGroups(): Observable<ISAPMaterialProduct[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/groups`);
    }

    getFamilies(groupCode: string): Observable<ISAPMaterialProduct[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/families?groupcode=${groupCode}`);
    }

    getProducts(familyCode: string): Observable<ISAPMaterialProduct[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/products?familycode=${familyCode}`);
    }

    getProfitCenterHierarchy(type: LocationFilterType, categoryCode: string): Observable<ISapProfitCenterHierarchyModel[]> {
        const url: string = categoryCode == null ? `${environment.baseUrl}/api/chemical/filter/pchierarchy?type=${type}`
        : `${environment.baseUrl}/api/chemical/filter/pchierarchy?type=${type}&categorycode=${categoryCode}`;

        return this.httpService.Get(url);
    }

    getMaterials(criteria: ChemicalSearchCriteriaModel): Observable<SapMaterialProfitCenterSearchModel[]> {
        const q: string = this.formatCriteriaQuery(criteria);
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/search?${q}`);
    }

    getMaterialsCsvUrl(criteria: ChemicalSearchCriteriaModel, filter: ChemicalSearchResultsFilter): Observable<string> {
        let q: string = this.formatCriteriaQuery(criteria);
        const fq = this.formatValueFilterQuery(filter);
        if (fq.length > 0) q += "&" + fq;
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/report_url?${q}`).pipe(
            map(r => `${environment.baseUrl}/api/chemical/report?${r['queryParam']}`)
        );
    }

    private formatCriteriaQuery(criteria: ChemicalSearchCriteriaModel): string {
        let q = `mftype=${criteria.materialFilter}&mfvalue=${encodeURI(criteria.materialCriteria)}&lftype=${criteria.pcFilter}`;
        if (criteria.pcFilter !== LocationFilterType.global) {
            q += `&lfvalue=${encodeURI(criteria.pcCriteria)}`
        }
        return q;
    }

    private formatValueFilterQuery(filter: ChemicalSearchResultsFilter){
        const queries = [
            filter.isExcess ? "ex=true" : null,
            filter.isRedeployment ? "rd=true" : null,
            filter.isSlowMove ? "sm=true" : null
        ];
        return queries.filter(q => q != null).join("&");
    }
}

export class ISAPMaterialProductModel implements ISAPMaterialModel {
    materialName: string;
    materialNumber: string;
    groupCode: string;
    familyCode: string;
    productCode: string;
}

