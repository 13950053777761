import { environment } from "libs/environment";
import { HttpService } from "./http.service";
import { Observable, interval, of } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { BaDataResult, BaGraphData, BaScoreResult, CDIFieldValue } from "apps/vida/src/modules/control-point/models/ba-results";
import { Injectable } from '@angular/core';

@Injectable()
export class BaService {
    constructor(
        private http: HttpService
    ){}

    GetBaScore(jobId: string): Observable<BaScoreResult> {
        return this.http.Get<BaScoreResult>(`${environment.baseUrl}/api/ba/${jobId}/ba_score`);
    }

    GetScenarioBaScore(jobId: string, fields: CDIFieldValue[]): Observable<BaScoreResult> {
        const body = JSON.stringify(fields);
        return this.http.Post<BaScoreResult>(`${environment.baseUrl}/api/ba/${jobId}/ba_scenario_score`, body);
    }

    GetBaData(jobId: string): Observable<BaGraphData> {
        return this.http.Get<BaDataResult>(`${environment.baseUrl}/api/ba/${jobId}/ba_graph`).pipe(
            switchMap(res => {
                if (res.status == 'done'){
                    return of(res.data);
                }
                return this.waitForComplete(res.actionId);
            })
        )
    }

    CheckBaDataStatus(actionId: string): Observable<BaDataResult> {
        return this.http.Get<BaDataResult>(`${environment.baseUrl}/api/ba/${actionId}/status`);
    }

    private waitForComplete(actionId: string): Observable<BaGraphData> {
        if (actionId == null){
            throw 'action id is null';
        }

        const checkStatusFiltered$: Observable<BaDataResult> = this.CheckBaDataStatus(actionId).pipe(
            filter((result: BaDataResult) => {
                const status = result.status;
                return status == 'completed'
                    || status == 'failed'
                    || status == 'notfound';
            })
        );
        let i:number = 0;
        return interval(1500).pipe(
            filter(()=> {
                i = i + 1;
                if (i > 1800){
                    throw 'ba graph data waiting timeout. Please, check if ba function is in active state.'
                }
                return i < 8 ? true : i < 40 ? i % 2 == 0 : i % 4 == 0;
            }),
            switchMap(() =>
                checkStatusFiltered$.pipe(
                    tap((res) => {
                        if (res.status == 'failed') {
                            throw res.message;
                        }
                        else if (res.status == 'notfound') {
                            throw 'Action was not found'
                        }
                        else if (res.status == 'completed' && res.data == null) {
                            throw 'Ba Graph data error'
                        }
                    })
                )
            ),
            take(1),
            map((result: BaDataResult) => result.data)
        );
    }
}

