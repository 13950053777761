import { Directive, ElementRef, HostListener } from '@angular/core';
import { ApplicationStateService } from 'libs/shared/services';

@Directive({
  selector: '[numericTextBox]'
})

export class NumericTextBoxDirective  {
  // eslint-disable-next-line
  regex = /[\d\-\.]/;

  constructor(private el: ElementRef, private applicationStateService: ApplicationStateService) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {

    if (e.ctrlKey || e.altKey || this.getKey(e) === 13) {
      return;
    }

    const c = this.getCharCode(e);
    const cc: any = String.fromCharCode(c);

    if (isNaN(cc)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e) {
    const clipboardData = e.clipboardData && e.clipboardData.getData('text/plain') || (<any>window).clipboardData.getData('text');
    if (isNaN(parseFloat(clipboardData)) || !isFinite(clipboardData)) {
      e.preventDefault();
    }
  }

  getKey(e: KeyboardEvent) {
    const k = e.keyCode || e.charCode;
    return k;
  }

  getCharCode(e: KeyboardEvent) {
    return e.charCode || e.keyCode || e.which;
  }
}
