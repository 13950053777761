import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

import { PDropDownModel } from 'libs/ui';
import { MasterDataService, IfactService, FluidService } from 'libs/shared/services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FluidModel, Job } from 'libs/models';

@Injectable()
export class FluidsAdapter {
  fluids$ = new BehaviorSubject<FluidModel[]>([]);
  fluidForm$ = new BehaviorSubject<UntypedFormGroup>(this.fb.group({}));
  testTypeData$ = new BehaviorSubject<PDropDownModel[]>([]);
  slurryTypeData$ = new BehaviorSubject<PDropDownModel[]>([]);

  SAPMaterialMapping = [];
  updateSAPMaterial$ = new Subject<any>();
  removeSAPMaterial$ = new Subject<any>();
  updateSAPMaterialFromPump$ = new Subject<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private ifactService: IfactService,
    private fluidService: FluidService
  ) {
    this.initMasterData();
  }

  correctFluids() {
    this.fluids$.next(this.fluids$.value.map(fluid => {
      fluid.displayName = this.fluidService.buildDisplayName(fluid);
      return fluid;
    }));
  }

  loadingMoreDataForFluids(job: Job, fluids: FluidModel[]): Observable<FluidModel[]> {
    return this.fluidService.loadFromIFacts(job, null, ...fluids).pipe(
      map(fluids => fluids.map(fluid => {
        fluid.displayName = this.fluidService.buildDisplayName(
          fluid,
          this.testTypeData$.value.map(ddv => {
            return { id: ddv.value, name: ddv.label };
          }));
        return fluid;
      })),
      tap(fluids => this.fluids$.next(fluids))
    );
  }

  private addSAPMaterialMapping(slurryModel, material) {
    material.controls.forEach(fluidMaterial => {
      const {
        controls: {
          materialId: { value: materialId },
          materialName: { value: materialName },
          sapMaterialNumber: { value: sapMaterialNumber }
        }
      } = fluidMaterial;
      if (sapMaterialNumber && materialId && materialName) {
        const existed = this.SAPMaterialMapping.find(x => x.sapMaterialNumber === sapMaterialNumber
          && x.iFactNumber === materialId
          && !x.isGroup);

        if (!existed) {
          this.SAPMaterialMapping.push({
            isGroup: false,
            iFactNumber: materialId,
            iFactName: materialName,
            sapMaterialNumber: sapMaterialNumber,
            slurry: slurryModel.slurry,
            slurryId: slurryModel.slurryId
          });
        }
      }
    });

  }
  initMasterData() {
    this.masterDataService.listTestTypes()?.pipe(
      map(res => res.map(itm => new PDropDownModel(itm.name, itm.id)))
    ).subscribe(res => this.testTypeData$.next(res));

    this.masterDataService.listSlurryTypes()?.pipe(
      map(res => res.map(itm => new PDropDownModel(itm.name, itm.id)))
    ).subscribe(res => this.slurryTypeData$.next(res));
  }
}
