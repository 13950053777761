import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, ViewChildren, QueryList, ViewChild, ChangeDetectionStrategy, Injector, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { debounceTime, delay, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { FluidMaterialModel, ITestType, ISlurryType, IMaterialTypeModel, ISAPMaterialModel, Job } from 'libs/models';
import { FormControlContainer } from 'libs/ui';
import { UnitType, SlurrySource, ERROR_MESSAGE, ERROR_TYPE, MATERIAL_TYPE } from 'libs/constants';
import { ConfirmDialogService } from 'libs/ui';
import { ErrorMessageModel } from 'libs/ui/validations/error-message.model';
import { MasterDataService, FluidService } from 'libs/shared/services';
import { FluidFormFactory } from '../fluid-container/fluid-form.factory';
import { IMasterData } from '../../../edit-job/models';
import { FluidMaterialsComponent } from '../fluid-materials/fluid-materials.component';
import { FluidRequestInfoComponent } from '../fluid-request-info/fluid-request-info.component';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { PumpScheduleStateFactory } from '../../../pump/state/state-factory';

@Component({
  selector: 'fluid-detail',
  templateUrl: './fluid-detail.component.html',
  styleUrls: ['./fluid-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FluidDetailComponent extends FormControlContainer implements OnInit, OnChanges, OnDestroy {

  @Input() fluidFormGroup: UntypedFormGroup;
  @Input() isFoamJob: boolean;
  @Input() index: number;
  @Input() job: Job;
  @Input() concentrationUnitList: any[] = [];
  @Input() canEdit: boolean;

  UnitType = UnitType;
  slurrySource: any = SlurrySource;
  isCement: boolean;
  MATERIAL_TYPE = MATERIAL_TYPE;

  @Output() deleteFluidDetail = new EventEmitter();
  @Output() copyRequest = new EventEmitter();
  @Output() createRequest = new EventEmitter();
  // eslint-disable-next-line
  @Output() onActualVolumeChange = new EventEmitter();
  @ViewChild(FluidRequestInfoComponent, { static: true }) fluidRequest: FluidRequestInfoComponent;
  @ViewChildren(FluidMaterialsComponent) fluidMaterials: QueryList<FluidMaterialsComponent>;

  isExpanded: boolean = false;
  testTypeData: ITestType[];
  slurryTypeData: ISlurryType[];
  waterData: IMasterData[];
  slurryTypes: ISlurryType[];
  materialTypes: IMaterialTypeModel[];
  sapMaterialData: ISAPMaterialModel[];
  sapMaterialAllFieldData: ISAPMaterialModel[];
  testTypes: ITestType[];
  loadingSubscription: Subscription;
  OriginalJobId: string;

  private subscription: Subscription = new Subscription();


  errorMessages = {
    testTypeId: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Test Type'))
    ],
    slurryTypeId: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Slurry Type'))
    ],
    density: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Slurry Density'))
    ],
    sapMaterialName: [
      new ErrorMessageModel('notExisted', 'SAP Material does not exist. Please input another value.')
    ]
  };

  constructor(
    protected inj: Injector,
    private cdRef: ChangeDetectorRef,
    private masterDataService: MasterDataService,
    private fluidService: FluidService,
    private confirmDialogService: ConfirmDialogService,
    public editJobAdapter: EditJobAdapter,
    private readonly _fluidFormFactory: FluidFormFactory,
    private readonly _scheduleStateFactory: PumpScheduleStateFactory,
  ) {
    super(inj);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.initMasterData();

    if (this.job && this.job.originalJobId) {
      this.OriginalJobId = this.job.originalJobId;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { fluidFormGroup } = changes;
    if (fluidFormGroup && fluidFormGroup.currentValue) {
      this.formControl = this.fluidFormGroup;
    }
  }

  initMasterData() {
    this.masterDataService.listSlurryTypes().subscribe(slurryTypes => this.slurryTypes = slurryTypes);
    this.masterDataService.listMaterialTypes().subscribe(res => this.materialTypes = res);
    this.masterDataService.listTestTypes().subscribe(res => this.testTypes = res);
  }

  addMaterial() {
    const materialForm = this.fluidFormGroup.get('fluidMaterial') as UntypedFormArray;
    const newMaterialFormControl = this._fluidFormFactory.createMaterialForm(new FluidMaterialModel(), SlurrySource.ManualFluid);
    materialForm.push(newMaterialFormControl);
  }

  deleteFluid() {
    this.deleteFluidDetail.next(null);
  }

  copyRequestFluid() {
    this.copyRequest.next(null);
  }

  createRequestFluid() {
    this.createRequest.next(null);
  }

  public updateFluidInAllStages() {
    this._scheduleStateFactory.updateFluidInAllStages(
      this.fluidFormGroup.value.requestId,
      this.fluidFormGroup.value.slurryNo,
      this.fluidFormGroup.getRawValue()
    );
  }

  addNewSupplemental() {
    const supMat = new FluidMaterialModel();
    supMat.materialType = 'Supplemental';
    supMat.materialTypeId = '9B9049B3-C942-4511-BE6E-F6156B905EAD';
    supMat.fluidTempId = Math.random().toString(10).substring(2, 15);

    const newMaterialFormControl = this._fluidFormFactory.createMaterialForm(supMat, SlurrySource.ManualFluid);
    const fluidMaterialForm = this.fluidFormGroup.controls.fluidMaterial as UntypedFormArray;
    const supplementalFluidForm = this.fluidFormGroup.get('supplementalMaterial') as UntypedFormArray;
    fluidMaterialForm.push(newMaterialFormControl);
    supplementalFluidForm.push(newMaterialFormControl);
    this.editJobAdapter.updateFluids$();
    this.updateFluidInAllStages();

    const supplementalFluid = supplementalFluidForm.controls.find(x => x.value.fluidTempId === supMat.fluidTempId);
    this.subscription.add(
      supplementalFluid.valueChanges
        .pipe(
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(() => this.updateFluidInAllStages())
    )
  }

  onSupplementalRemoved(index: number) {
    const fluidMaterialForm = this.fluidFormGroup.controls.fluidMaterial as UntypedFormArray;
    const supplementalMaterialForm = this.fluidFormGroup.get('supplementalMaterial') as UntypedFormArray;
    const fmIndex = fluidMaterialForm.controls.findIndex(fm => fm === supplementalMaterialForm.controls[index]);
    fluidMaterialForm.removeAt(fmIndex);
    supplementalMaterialForm.removeAt(index);
    fluidMaterialForm.controls.forEach((fm: UntypedFormGroup) => fm.controls.materialName.updateValueAndValidity());
    this.cdRef.markForCheck();
    this.editJobAdapter.updateFluids$();
    this.updateFluidInAllStages()
  }

  showConfirmDeleteFluid() {
    this.confirmDialogService.show({
      message: `Are you sure to remove this Fluid?`,
      header: 'Confirmation',
      accept: () => {
        this.deleteFluidDetail.next(null);
      },
      reject: () => {
      },
      acceptLabel: 'Yes',
      rejectLabel: 'No'
    });
  }

  isCementDefault(): boolean {
    return true;
  }

  expand() {
    this.isExpanded = true;
    this.cdRef.detectChanges();
  }

  onFluidChange() {
    this.loadingSubscription = this.fluidService.loadFromIFacts(null, this.fluidFormGroup.getRawValue()).pipe(
      tap(fluids => this.fluidFormGroup.patchValue(fluids[0])),
      delay(1000)
    ).subscribe();
  }

  getHeaderMaterialName(controls, type) {
    const length = controls ? controls.length : 0;
    const map = {
      Blend: `Blend Materials (${length})`,
      Additives: `Additives (${length}) (* = Exclude from Total Mix Fluid)`,
      Supplemental: `Supplemental Materials (${length})`
    };
    return map[type];
  }

  markAsTouched() {
    super.markAsTouched();
    this.fluidMaterials.forEach(f => f.markAsTouched());
  }
}
