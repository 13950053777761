import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";

import { MasterDataService } from "./master-data.service";
import { FluidModel, IPrimaryStatus, IPumpScheduleFluidType, ISlurryType, ITestType, PumpScheduleEventModel, PumpScheduleStageModel } from "libs/models";
import { SlurrySource } from "apps/vida/src/modules/shared/constant/slurry-source";

@Injectable({ providedIn: 'root' })
export class PumpScheduleStageService {
    private fluidsInfo: any[] = [];

    constructor(private readonly masterDataService: MasterDataService) {
        forkJoin([
            this.masterDataService.listTestTypes(),
            this.masterDataService.listSlurryTypes(),
            this.masterDataService.listPumpScheduleFluidTypes(),
            this.masterDataService.listPrimaryStatuses()
        ]).subscribe((data: any[]) => this.fluidsInfo = data);
    }

    get testTypes(): ITestType[] {
        const [testTypes] = this.fluidsInfo;
        return testTypes as ITestType[];
    }

    get slurryTypes(): ISlurryType[] {
        const [, slurryTypes] = this.fluidsInfo;
        return slurryTypes as ISlurryType[];
    }

    get pumpScheduleFluidTypes(): IPumpScheduleFluidType[] {
        const [, , pumpScheduleFluidType] = this.fluidsInfo;
        return pumpScheduleFluidType as IPumpScheduleFluidType[];
    }

    get primaryStatuses(): IPrimaryStatus[] {
        const [, , , primaryStatuses] = this.fluidsInfo;
        return primaryStatuses as IPrimaryStatus[];
    }

    public generateStage(density: number, slurryType: string, stageIdx: number, pumpSchedulefluidType: string, fluidTestType: string, status: string, isCement: boolean): PumpScheduleStageModel {
        const stage = new PumpScheduleStageModel();
        stage.events.push(new PumpScheduleEventModel());
        stage.order = stageIdx;
        stage.number = stageIdx + 1;

        const fluidType = this.getPumpScheduleFluidType(pumpSchedulefluidType);
        stage.pumpScheduleFluidTypeId = fluidType.id;
        stage.pumpScheduleFluidTypeName = fluidType.name;

        stage.slurry = this.generateFluid(density, slurryType, fluidTestType, status, isCement);

        return stage;
    }

    public generateFluid(density: number, slurryType: string, fluidTestType: string, status: string, isCement: boolean, fluidName: string = ''): FluidModel {
        const fluid = new FluidModel();

        const testType = this.getTestType(fluidTestType);
        fluid.testTypeId = testType.id;
        fluid.testType = testType.name;

        const slurryTypeItm = this.getSlurryTypeByName(slurryType);
        fluid.slurryTypeId = slurryTypeItm.id;
        fluid.slurryType = slurryTypeItm.name;

        const primaryStatus = this.getPrimaryStatus(status);
        fluid.primaryStatusId = primaryStatus.id;
        fluid.primaryStatus = primaryStatus.name;

        fluid.name = fluidName;
        fluid.density = density ?? 0;
        fluid.isCement = isCement;
        fluid.slurrySource = SlurrySource.ManualFluid;

        return fluid;
    }

    private getTestType = (description: string): ITestType => this.testTypes.find(x => this.compareDescription(x.name, description));

    private getSlurryTypeByName = (slurryType: string): ISlurryType => this.slurryTypes.find(x => this.compareDescription(x.name, slurryType));

    private getPumpScheduleFluidType = (description: string): IPumpScheduleFluidType => this.pumpScheduleFluidTypes.find(x => this.compareDescription(x.name, description));

    private getPrimaryStatus = (description: string): IPrimaryStatus => this.primaryStatuses.find(x => this.compareDescription(x.name, description));

    private compareDescription = (name: string, description: string): boolean => name.toLowerCase() === description.toLowerCase();
}
