import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobClassificationState } from 'apps/vida/src/modules/edit-job/components';
import { ControlPointType } from 'apps/vida/src/modules/shared/constant';
import { environment } from 'libs/environment';
import { ISaleOrderNumberModel, Job, JobCasingEquipment, JobCasingEquipmentSalesOrderModel, UpdateFromSalesOrderParameters } from 'libs/models';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApplicationStateService, JobService } from '..';
import { CustomerService } from './customer.service';

@Injectable()
export class SaleOrderNumberService {
    private seleOrderEntered:ISaleOrderNumberModel;
    private saleOrderSubject: Subject<void> = new Subject();
    public updateFromSalesOrder$: Subject<UpdateFromSalesOrderParameters> = new Subject();
    public updatedSalesOrder$: Subject<ISaleOrderNumberModel> = new Subject();
    public successUpdateCasingEquipmentFromSalesOrder$: Subject<JobCasingEquipment[]> = new Subject<JobCasingEquipment[]>();
    public successSetCasingEquipmentFromSalesOrder$: Subject<JobCasingEquipment[]> = new Subject<JobCasingEquipment[]>();
    public isSONumberCleared$ = new BehaviorSubject<boolean>(false);

    constructor(private httpclient: HttpClient,
        private customerService: CustomerService,
        private jobService: JobService,
        private messageService: MessageService,
        private readonly _appStateService: ApplicationStateService) {
    }

    public get saleOrderChanged$(): Observable<void> {
        return this.saleOrderSubject.asObservable();
    }

    public get saleOrderResult(): ISaleOrderNumberModel {
        return this.seleOrderEntered;
    }

    private _salesOrderCache: { [so: string]: ISaleOrderNumberModel[]} = {};

    private handleSap(err) {
        const isDown = (err.error.message.indexOf('Sales Order') == -1); // when Sales Order not found - its OK
        this._appStateService.notifySapDown$.next(isDown);
   }

    getSaleOrderNumber(salesNumber: string): Observable<ISaleOrderNumberModel[]> {
        if (!salesNumber) return of<ISaleOrderNumberModel[]>([]);

        if (this._salesOrderCache != undefined && this._salesOrderCache[salesNumber] != null) {
            return of(this._salesOrderCache[salesNumber]);
        }

        return this.httpclient.get<ISaleOrderNumberModel[]>(`${environment.baseUrl}/api/sap/job-detail/${salesNumber}`).pipe(
            tap(res => this._salesOrderCache[salesNumber] = res,
                (error) => { this.handleSap(error); } )
        );
    }

    validateSalesOrderNumber(salesOrderNumber: string, customerId: string, jobState:JobClassificationState): Observable<any> {
        if (salesOrderNumber == null || salesOrderNumber.length !== 10) {
            return of({isValid: false, errMessage: 'Invalid Sales Order: Enter valid Sales Order number.'});
        }

        return this.getSaleOrderNumber(salesOrderNumber).pipe(
            switchMap((salesOrders) => {
                //check for Product Sale (ZOR) SO Document type
                if (!jobState.ceDirectSales) {
                    salesOrders = salesOrders ? salesOrders.filter(x => x.bomTypeId != null) : [];
                }

                if (!salesOrders || salesOrders.length === 0) {
                    return of({isValid: false, errMessage: 'Invalid Sales Order: Enter valid Sales Order number.'});
                }

                const customerSalesOrder = salesOrders.find(x => x.customerId === customerId);
                if (customerSalesOrder) {
                    return of({isValid: true, errMessage: ''});
                }

                const salesOrderCustomerId = salesOrders[0].customerId;
                if (!salesOrderCustomerId) {
                    return of({isValid: false, errMessage: 'Invalid Sales Order: Enter valid Sales Order number.'});
                }

                return this.customerService.getCustomerById(salesOrderCustomerId)
                    .pipe(
                        switchMap(saleOrderCustomer => {
                            if (!saleOrderCustomer) {
                                return of({isValid: false, errMessage: `Invalid Sales Order: SAP Customer Name (${salesOrderCustomerId}) does not match VIDA Customer Name.`});
                            }

                            this.seleOrderEntered=salesOrders[0];
                            this.seleOrderEntered.customerName=saleOrderCustomer.customerName;
                            this.saleOrderSubject.next();
                            return of({isValid: false, errMessage: `Invalid Sales Order: SAP Customer Name ${saleOrderCustomer.customerName} (${salesOrderCustomerId}) does not match VIDA Customer Name.`});
                        }),
                        catchError((response) => throwError(response))
                    );
            }),
            catchError((response) => throwError(response))
        );
    }

    getCasingEquipment(salesOrderNumber: string): Observable<JobCasingEquipmentSalesOrderModel[]> {
        return this.httpclient.get<JobCasingEquipmentSalesOrderModel[]>(`${environment.baseUrl}/api/sap/job-casing-equipment/${salesOrderNumber}`)
        .pipe(catchError(err => {
            this.handleSap(err);
            return of({} as JobCasingEquipmentSalesOrderModel[]);
        })
        );
    }

    setCasingEquipment(salesOrderNumber: string, allowPopulate: boolean): Observable<boolean> {
        if (!allowPopulate) {
            return of(false);
        }

        if (salesOrderNumber) {
            return this.getCasingEquipment(salesOrderNumber).pipe(
                switchMap((casingEquipmentFromSO) => {
                    return this.setCasingEquipment$(casingEquipmentFromSO).pipe(
                        tap(() => {
                            if (casingEquipmentFromSO && casingEquipmentFromSO.length > 0) {
                                this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'CE Tools successfully added/updated.' });
                            }
                        })
                    );
                }),
                catchError(() => of(false))
            );
        } else {
            return this.setCasingEquipment$([]).pipe(
                tap(() => {
                })
            );
        }
    }

    updateCasingEquipment(job: Job, salesOrderNumber: string, allowUpdate: boolean, callServer = true): Observable<boolean> {
        if (!allowUpdate) {
            return of(false);
        }

        const cp1Complete = job.controlPoints ? job.controlPoints.find(x => x.type == ControlPointType.ControlPoint1).controlPointState.displayName == "Completed" : false;
        const cp2Complete = job.controlPoints ? job.controlPoints.find(x => x.type == ControlPointType.ControlPoint2).controlPointState.displayName == "Completed" : false;

        return this.jobService.getJobCasingEquipment(job.id).pipe(
            switchMap((casingEquipmentFromDB) => {
                if (salesOrderNumber) {
                    return this.getCasingEquipment(salesOrderNumber).pipe(
                        switchMap((casingEquipmentFromSO) => {
                            return this.updateCasingEquipment$(job, casingEquipmentFromDB, casingEquipmentFromSO, callServer).pipe(
                                tap(() => {
                                    if (callServer && casingEquipmentFromSO && casingEquipmentFromSO.length > 0) {
                                        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'CE Tools successfully added/updated.' });
                                    }
                                })
                            );
                        }),
                        catchError(() => of(false))
                    );
                }
                else if (!cp1Complete || !cp2Complete) {
                    return this.updateCasingEquipment$(job, casingEquipmentFromDB, [], callServer).pipe(
                        tap(() => {
                            if (callServer) {
                                this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'CE Tools from previous sales order were removed.' });
                            }
                        })
                    );
                }
            }),
            catchError(() => of(false))
        );
    }

    private setCasingEquipment$(casingEquipmentFromSO: JobCasingEquipmentSalesOrderModel[]): Observable<boolean> {
        const items: JobCasingEquipment[] = [];

        casingEquipmentFromSO.forEach((item) => {
            const ce: JobCasingEquipment = this.createCasingEquipment(item);
            items.push(ce);
        });

        items.sort(this.sortCasingEquipment);

        this.successSetCasingEquipmentFromSalesOrder$.next(items);

        return of(true);
    }

    private updateCasingEquipment$(
        job: any,
        casingEquipmentFromDB: JobCasingEquipment[],
        casingEquipmentFromSO: JobCasingEquipmentSalesOrderModel[],
        callServer = true,
    ): Observable<boolean> {

        const cp1Complete = job.controlPoints ? job.controlPoints.find(x => x.type == ControlPointType.ControlPoint1).controlPointState.displayName == "Completed" : false;
        const cp2Complete = job.controlPoints ? job.controlPoints.find(x => x.type == ControlPointType.ControlPoint2).controlPointState.displayName == "Completed" : false;

        const items = casingEquipmentFromDB;

        casingEquipmentFromSO.forEach((item) => {
            const index = items.findIndex(({ sapMaterialNumber }) => sapMaterialNumber === item.sapMaterialNumber);
            if (index === -1) {
                const ce: JobCasingEquipment = this.createCasingEquipment(item);

                if(cp1Complete && cp2Complete)
                    ce.numberOfItems = 0;

                items.push(ce);
            } else {
                this.editCasingEquipment(items[index], item);
            }
        });

        items.sort(this.sortCasingEquipment);

        if (!callServer) {
            this.successUpdateCasingEquipmentFromSalesOrder$.next(items);
            return of(false);
        }

        return this.jobService.setJobCasingEquipmentCommon(job.id, items).pipe(
            tap(() => {
                this.successUpdateCasingEquipmentFromSalesOrder$.next(items);
            }),
            catchError(() => of(false))
        );
    }

    private createCasingEquipment(model: JobCasingEquipmentSalesOrderModel): JobCasingEquipment {
        const ce: JobCasingEquipment = new JobCasingEquipment();
        Object.assign(ce, model, {
            id: null,
            surveyCompletionDate: '',
            maxPressure: null,
        });

        return ce;
    }

    private editCasingEquipment(target: JobCasingEquipment, source: JobCasingEquipmentSalesOrderModel): JobCasingEquipment {
        Object.assign(target, {
            /* Update the SAP Material Number value doesn't make sense since we are using it as search criteria in order to replace other values */
            sapMaterialDescription: source.sapMaterialDescription,
            productCode: source.productCode,
            productName: source.productName,
            productFamily: source.productFamily,
            productGroup: source.productGroup,
            className: source.className,
            classDescription: source.classDescription,
            casingSize: source.casingSize,
            casingGrade: source.casingGrade,
            casingThread: source.casingThread,
            casingWeight: source.casingWeight,
            numberOfItems: source.numberOfItems,
            dataSource: source.dataSource,
            /* don't update maxPressure/maxTemperature because they don't come from SAP. */
        });

        return target;
    }

    private sortCasingEquipment(a: JobCasingEquipment, b: JobCasingEquipment) {
        return a.productGroup.localeCompare(b.productGroup) || a.productFamily.localeCompare(b.productFamily) || a.productName.localeCompare(b.productName) || 0;
    }
}
