import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FileStatus } from 'libs/models/files/fileStatus';
import { FileService } from 'libs/shared/services/file.service';
import { ConfirmDialogService } from 'libs/ui';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { VidaFileType, VidaFileTypeFilenameLimit } from '../../../shared/constant/index';
import { FormControl } from '@angular/forms';
import { isNil } from 'lodash';
import { environment } from 'libs/environment';


@Component({
  selector: 'job-uploader',
  templateUrl: './job-uploader.component.html',
  styleUrls: ['./job-uploader.component.scss']
})

export class JobUploaderComponent implements OnDestroy, OnInit, AfterViewInit {

  uploadState: string;
  uploadStatus: string = null;
  uploadedPercent: number = null;
  @Input() fileDescription: string;
  isLoading = false;
  isJobProgramSourceVisible: boolean = false;
  @Input() isDisabled: boolean;
  @Input() listFiles = [];
  @Input() jobId: string;
  @Input() destinationFolderPath: string;
  @Input() isEnableDescriptionInput: boolean;
  @Input() drapDropDescription: string;
  @Input() title: string;
  @Input() header: string;
  @Input() badgeNumber: boolean;
  @Input() jobProgramFileName: string;
  @Input() vidaFileType: VidaFileType;
  @Input() displayMode: string;
  @Input() isMultipleFile: boolean;
  @Input() multipleFileSubject: BehaviorSubject<any>;
  @Output() uploadSuccessEmitter = new EventEmitter<any>();
  @Output() uploadInprogressEmitter = new EventEmitter<boolean>();
  @Output() uploadFinishedEmitter = new EventEmitter<boolean>();
  @Output() uploadCancelEmitter = new EventEmitter<any>();
  @Output() descriptionChangingEmitter = new EventEmitter<string>();
  @Output() fileUploaded = new EventEmitter<any>();
  isError: boolean;
  @ViewChild('fileInput') fileInputElement: FileUpload;
  @Input() descriptionTitle: string;
  @Input() descriptionPlaceHolder: string;
  @Input() showFileDescription: boolean = true;
  @Input() showProgressBar: boolean = true;

  pause$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resume$: Subject<any> = new Subject();

  // this is for moving out download button to outside
  // eslint-disable-next-line
  @Output() onSelect = new EventEmitter<any>();
  isMaxLengthError: boolean;
  private _uploadSubscription: Subscription;

  multipleFile:any = [];

  fileNameInput = new FormControl('')
  originalFileName = new FormControl('')
  constructor(
    private readonly _fileService: FileService,
    private messageService: MessageService,
    private confirmDialogService: ConfirmDialogService,
  ) {
  }

  ngOnInit(): void {
    if (!this.descriptionTitle) {
      this.descriptionTitle = 'Description/Comments';
    }
  }

  ngAfterViewInit(): void {
    if(this.vidaFileType === VidaFileType.WellCustomerInfo || this.isDisabled){
      this.hideInputFile();
      this.fileNameInput.disable();
    }
  }

  ngOnDestroy(): void {

    if (this._uploadSubscription && !this._uploadSubscription.closed) {

      this._uploadSubscription.unsubscribe();
    }
  }

  uploadFileHandler(event: any) {
    console.log(event)
  }

  async uploadFile(fileInput) {
    // Only support upload single file for now
    const file: File = fileInput.files[0];

    if (!this.isMultipleFile) {

      const canUpload = await this._fileService.canUploadFile(file);
      if (!canUpload)
        return;
      this.isMaxLengthError = false;
      if (file) {
        this.fileNameInput.setValue(this._fileService.addOrigenFileExtension(this.fileNameInput.value, file))

        if (this.vidaFileType === VidaFileType.iCem) {
          if (!file.name.toLowerCase().endsWith('.icem') && !file.name.toLowerCase().endsWith('.icemx')) {
            this.uploadStatus = 'Your file is the incorrect file type.';
            this.isError = true;
            this.uploadState = 'warning';
            return;
          }
        }
        else if (VidaFileTypeFilenameLimit[this.vidaFileType]
          && this.fileNameInput.value
          && this.fileNameInput.value.length > VidaFileTypeFilenameLimit[this.vidaFileType]) {
          this.uploadStatus = `Maximum file name is limited to ${VidaFileTypeFilenameLimit[this.vidaFileType]} characters.`;
          this.isMaxLengthError = true;
          this.uploadState = 'warning';
          return;
        }
        this.isError = false;
        this.pause$.next(false);
        this.checkExistFileName(file, fileInput, this.vidaFileType);
        this.fileUploaded.emit();
      }
    } else {
      this.isError = false;
      this.pause$.next(false);
      this.fileUploaded.emit();
      const rejectedExtensions = ['exe', 'bat', 'ps1', 'svg'];
      fileInput.files.forEach(file => {
        const name = file.name;
        const fileExtension = name.split('.').pop()?.toLowerCase();
        if(rejectedExtensions.includes(fileExtension)){
          if(file.size)
            this.messageService.add({
            life: environment.messagePopupLifetimeMs,
            severity: 'error', summary: 'File Not Uploaded:',
            detail: '.exe, .bat, .ps1 and .svg files cannot be uploaded.'});
          else
            this.messageService.add({
            life: environment.messagePopupLifetimeMs,
            severity: 'error', summary: 'Error:',
            detail: 'File does not exist.'});
        }else{
        this.multipleFile.push({ file, fileInput, name });
        }
      });
      fileInput.files = [];
      this.multipleFileSubject.next(this.multipleFile);
    }
  }

  checkExistFileName(file: any, fileInput: any, vidaFileType: number, forSave: boolean = true) {
    if (!this.listFiles) {
      this.listFiles = [];
    }

    const fileName = this.fileNameInput.value

    if (vidaFileType === VidaFileType.MaterialLoadSheet) {
      const mlsFile = this.listFiles.find(x => x.getFileName().toLowerCase() === fileName.toLowerCase());
      // eslint-disable-next-line
      if (!!mlsFile) {
        const fileNameWithNoExtenson = this.removeFileExtension(mlsFile.name);
        this.showConfirmationForMLS(file, fileInput, fileNameWithNoExtenson, this.listFiles);
      }
      else {
        if (forSave) {
          this.uploadAfterConfirmation(file, fileInput, true);
        }
      }
    }
    else {
      if (this.listFiles.some(x => {
        if (vidaFileType === VidaFileType.iCem || vidaFileType === VidaFileType.iCemRealtime) {
          return x.fileName.toLowerCase() === fileName.toLowerCase();
        }
        else {
          return x.getFileName().toLowerCase() === fileName.toLowerCase();
        }
      })) {
        this.showConfirmation(file, fileInput);
      }
      else {
        if (forSave) {
          this.uploadAfterConfirmation(file, fileInput, true);
        }
      }
    }
  }

  uploadMultipleFile(destinationFolderPath: string = null, wellId: string = null, wellNumber: string = null){
    let _internalSubscription = new Subscription;
    _internalSubscription = this.multipleFileSubject.subscribe(element => {
      if(element.length > 0) {
        element.forEach(element => {
          this.uploadAfterConfirmation(element.file, element.fileInput, true, destinationFolderPath,true);
        });
      }else{
        this._fileService.createFolder(wellId, wellNumber);
      }
    });

    return _internalSubscription;
  }

  private showConfirmation(file: any, fileInput: any) {
    this.confirmDialogService.show({
      message: `There is one file with the matching name. Do you want to overwrite the existing file?`,
      header: 'Message',
      accept: () => {
        this.uploadAfterConfirmation(file, fileInput, true);
      },
      reject: () => {
      }
    });
  }

  private uploadAfterConfirmation(file: any,
                                  fileInput: any,
                                  isOverwrite: boolean,
                                  destinationFolderPath: string = null,
                                  isMultipleFile: boolean = false,
                                  changedFileName: string = null) {
    this.isLoading = true;
    this.uploadedPercent = 0;
    this.uploadState = '';
    this.uploadStatus = 'Uploading file';
    this.uploadInprogressEmitter.emit(true);
    const _internalSubscription = new Subscription;

    if(isMultipleFile ){
      this.fileNameInput.setValue(file.name);
      this.fileDescription = '';
      this.destinationFolderPath = destinationFolderPath;
    }
    const upload$ = this._fileService.upload(
      this.jobId,
      file,
      this.destinationFolderPath,
      this.fileDescription,
      this.vidaFileType,
      isOverwrite,
      null,
      null,
      isNil(changedFileName) ? this.fileNameInput.value : changedFileName,
      this.pause$,
      this.resume$);

    this._uploadSubscription = upload$.subscribe(progress => {
          this.uploadedPercent = Math.round(progress.percentage);

          if (progress.status === FileStatus.Success) {

            this.uploadStatus = 'File upload complete. Your file is ready.';
            this.uploadState = 'success';
            this.isLoading = false;
            const event = {
              isSuccess: true,
              virtualRelativeFilePath: progress.uploadedFile.virtualRelativeFilePath,
              fileName: this.fileNameInput.value
            };
            this.uploadSuccessEmitter.emit(event);
            fileInput.clear();
            this.fileNameInput.reset();
            this.originalFileName.reset();
            this.fileDescription = '';

          } else if (progress.status === FileStatus.Finalizing) {
            this.uploadFinishedEmitter.emit(true);

          } else if (progress.status === FileStatus.Failed) {
            this.uploadStatus = 'There was an error uploading your file.';
            this.uploadState = 'error';
            fileInput.clear();
            this.fileNameInput.reset()
            this.originalFileName.reset()
            const event = { isSuccess: false, virtualRelativeFilePath: null };
            this.uploadSuccessEmitter.emit(event);
            this.isLoading = false;
          }
    });

    if(isMultipleFile){
      _internalSubscription.add(this._uploadSubscription);
      return _internalSubscription;
    }
  }

  cancelUpload() {
    this.pause$.next(true);
    if (this._uploadSubscription && !this._uploadSubscription.closed) {
      this.confirmDialogService.show({
        message: 'Are you sure to terminate uploading file?',
        header: 'Warning message',
        accept: () => {
          this._uploadSubscription.unsubscribe();
          this.cancelUploaderUI();
          const event = { isSuccess: false, virtualRelativeFilePath: null };
          this.uploadCancelEmitter.emit(event);

          this.pause$.next(false);
          this.resume$.next(null);
        },
        reject: () => {
          this.pause$.next(false);
          this.resume$.next(null);
        }
      });
    } else {
      const event = { isSuccess: false, virtualRelativeFilePath: null };
      this.uploadCancelEmitter.emit(event);
      this.fileInputElement.clear();
      this.resetUploaderUI();
    }
  }

  changeDescription(event) {
    this.descriptionChangingEmitter.emit(event);
  }

  onFileSelect(event: any) {
    this.resetUploaderUI();
    this.fileNameInput.setValue(event.currentFiles[0].name)
    this.originalFileName.setValue(event.currentFiles[0].name)
  }

  resetUploaderUI() {
    this.isLoading = false;
    this.fileDescription = '';
    this.uploadedPercent = null;
    this.uploadState = null;
    this.uploadStatus = null;
    this.isMaxLengthError = false;
    if (this.onSelect) {
      this.onSelect.emit({ fileInput: this.fileInputElement });
    }
    this.fileNameInput.reset();
    this.originalFileName.reset();
  }

  cancelUploaderUI() {
    this.isLoading = false;
    if (this.uploadState !== 'success') {
      this.uploadStatus = 'The file uploading was terminated manually.';
      this.uploadState = 'error';
      this.isMaxLengthError = false;
    }
    if (this.onSelect) {
      this.onSelect.emit({ fileInput: this.fileInputElement });
    }
  }

  showJobProgramSideBar() {
    this.isJobProgramSourceVisible = true;
  }

  checkIsDirty() {
    return !this.isLoading && this.fileInputElement.hasFiles();
  }

  hideInputFile(){
    const nattiveElement: Element = this.fileInputElement.content.nativeElement;
    const inputElement: any = nattiveElement.parentElement.getElementsByClassName('p-fileupload-buttonbar')[0];
    inputElement.style.display = 'none';
  }

  onBrowse(){
    this.hideInputFile();
    const nattiveElement: Element = this.fileInputElement.content.nativeElement;
    const inputElement = nattiveElement.parentElement.getElementsByTagName('input')[0];
    inputElement.click();
  }

  private showConfirmationForMLS(file: any, fileInput: any, fileName: string, files: any[]) {
    this.confirmDialogService.show({
      message: `Material Load Sheet with the name '${fileName}' already exists. Do you wish to create a duplicate version of this file?`,
      header: 'Message',
      accept: () => {
        // if we have the same file name, get a duplicated name with suffix
        const mls = files.map(x => ({...x, name: this.removeFileExtension(x?.name)}));
        const newFileName = this.getNewFileName(fileName, mls);
        const fileExtension = file.name.split('.').at(-1);
        this.uploadAfterConfirmation(file, fileInput, true, null, false, `${newFileName}.${fileExtension}`);
      }
    });
  }

  private getNewFileName(fileName: string, files: any[]): string {
    let fileCopyCount = 1;
    let newFileName = `${fileName} (${fileCopyCount})`;
    files = files.reverse();
    for (let i = 0; i < files.length; i++) {
      for (let j = 1; j < files.length; j++) {
        const alreadyExist = files[i].name === `${fileName} (${j})`;
        if (alreadyExist && fileCopyCount <= j) {
            fileCopyCount = j + 1;
            newFileName = `${fileName} (${fileCopyCount})`;
            break;
        }
      }
    }

    return newFileName;
  }

  private removeFileExtension(fileName: string): string {
    return fileName.replace(/\.[^/.]+$/, "");
  }
}
