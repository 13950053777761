export const sum = (a: number, b: number) => a + b;

declare global {
    interface Array<T> {
        /**
         * Sum elements in an array
         * @param mapper The map function to get the number from array element
         * @returns The result of sum
         */
        sum(mapper?: (x: T) => number): number;
        /**
         * Find the min value in an array
         * @param mapper The map function to get the number from array element
         * @returns return a minimum value of the array
         */
        min(mapper?: (x: T) => number): number;
        /**
         * Find the min value in an array
         * @param mapper The map function to get the number from array element
         * @returns return a maximum value of the array
         */
        max(mapper?: (x: T) => number): number;
        /**
         * Map an array to a dictionary
         * @param getKey The function to get key for an item
         * @returns return a dictionary
         */
        toDictionary(getKey?: (x: T) => any): any;
        /**
         * Map an array to a dictionary
         * @param getHash The function to get key for an item
         * @returns return a dictionary
         */
        distinct(getHash?: (x: T) => any): Array<T>;
    }
}

if (!Array.prototype.sum) {
    Array.prototype.sum = function <T>(mapper?: (x: T) => number): number {
        if (mapper == null) return this.reduce(sum, 0);
        return this.map(mapper).filter((x: T) => x != null).reduce(sum, 0);
    };
}

if (!Array.prototype.min) {
    Array.prototype.min = function <T>(mapper?: (x: T) => number): number {
        return find<T>(this, mapper, Math.min);
    };
}

if (!Array.prototype.max) {
    Array.prototype.max = function <T>(mapper?: (x: T) => number): number {
        return find<T>(this, mapper, Math.max);
    };
}

if (!Array.prototype.toDictionary) {
    Array.prototype.toDictionary = function <T>(getKey?: (x: T) => any): any {
        const dic = {};
        if (getKey == null) getKey = t => t;
        if (this == null || this.length === 0) return dic;
        this.forEach((element: T) => {
            const key = getKey(element);
            if (key == null || dic[key] != null) return;
            dic[key] = element;
        });
        return dic;
    };
}

if (!Array.prototype.distinct) {
    Array.prototype.distinct = function <T>(getHash?: (x: T) => any): any {
        return Object.values(this.toDictionary(getHash));
    };
}

function find<T>(array: Array<T>, mapper: (x: T) => any, finder: any) {
    const mapFunc = mapper || (x => x);
    const mappedArr = array.filter((x: T) => x != null).map(mapFunc).filter(x => x != null);
    return mappedArr.length > 0 ? finder(...mappedArr) : null;
}
