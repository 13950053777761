import { AbstractControl } from '@angular/forms';
import { isFormDirty, isFormInvalid, operateFormState} from 'libs/helpers';
import { BehaviorSubject } from 'rxjs';
import { ChangeDetectorRef, Injector } from '@angular/core';

export abstract class FormControlContainer {
  _cdRef: ChangeDetectorRef;
  private _formControl: AbstractControl;
  private _touched$ = new BehaviorSubject<boolean>(false);
  private _dirty$ = new BehaviorSubject<boolean>(false);
  // public shouldDisplayError: boolean = false;

  constructor(inj: Injector) {
    this._cdRef = inj.get(ChangeDetectorRef);
  }
  get formControl() {
    return this._formControl;
  }

  set formControl(value: AbstractControl) {
    this._formControl = value;
    // this._touched$.subscribe(() => {
    //   this._updateShouldDisplayError();
    // });
    this.onFormControlChanged();
  }

  onFormControlChanged() {
    this.reset();
  }

  reset() {
    this._touched$.next(false);
    this._dirty$.next(false);
  }

  isTouched(): boolean {
    return this._touched$.value || this._formControl.touched;
  }

  isInvalid(): boolean {
    return isFormInvalid(this._formControl);
    // return this._formControl && this._formControl.invalid;
  }

  isDisabled(): boolean {
    return this._formControl && this._formControl.disabled;
  }

  isDirty(): boolean {
    return this._dirty$.value || (this._formControl && isFormDirty(this._formControl));
    // return this.isInvalid() && !this.isDisabled() && this.isTouched();
  }

  markAsTouched() {
    !this._formControl ?  this.findForm(this) : operateFormState(this._formControl, 'markAsTouched')
    this._touched$.next(true);
    this._cdRef.detectChanges();
    this._cdRef.markForCheck();
  }

  markAsUntouched() {
    this._touched$.next(false);
    this._formControl.markAsUntouched();
    this._cdRef.markForCheck();
  }

  markAsPristine() {
    !this._formControl ?  this.findForm(this) : operateFormState(this._formControl, 'markAsPristine')
  }

  findForm(that){
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(that)) {
      if(typeof that[key] === 'object' && that[key] !== null &&  that[key].formControl) {
        operateFormState(that[key].formControl, 'markAsPristine')
      }
    }
  }
  markAsDirty() {
    this._dirty$.next(true);
    this._formControl.markAsDirty();
  }

  get shouldDisplayError() {
    return this.isInvalid() && !this.isDisabled() && this.isTouched();
  }

  // private _updateShouldDisplayError() {
  //   this.shouldDisplayError = this.isInvalid() && !this.isDisabled() && this.isTouched();
  // }

  getSanitizedData?(): any;
}
