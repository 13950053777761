import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, ViewChild, ChangeDetectionStrategy, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { map, distinctUntilChanged, takeUntil, filter, delay } from 'rxjs/operators';

import { Job, PumpSchedule, FluidModel, PumpScheduleStageModel } from 'libs/models';
import { PumpScheduleService, PumpScheduleLogic } from '../../services';
import { ActionState, Guid, ControlPointState } from '../../../shared/constant';
import { isFormValid, isFormDirty } from 'libs/helpers/form-helpers';
import { FLUID_TYPE_SCHEDULE, SlurrySource, SpacerMixMethod } from 'libs/constants';
import { createPumpScheduleForm, createPumpScheduleStage } from './pump-schedule.form.factory';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { FormControlContainer } from 'libs/ui';
import { PumpScheduleAdapter } from '../../adapters';
import { PumpStageComponent } from '../pump-stage/pump-stage.component';
import { ApplicationStateService, JobStateService } from 'libs/shared/services';
import { createEmptyEvent } from '../pump-event/pump-event-form.factory';

import { MailService } from 'libs/shared/services/mail.service';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { PumpScheduleShoeTrackComponent } from '../pump-schedule-shoetrack/pump-schedule-shoetrack.component';

@Component({
  selector: 'pump-schedule',
  templateUrl: './pump-schedule.component.html',
  styleUrls: ['./pump-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PumpScheduleComponent extends FormControlContainer implements OnInit, OnDestroy {
  private defaultIndex = 0

  @Input()
  public job: Job;

  @Input()
  public controlpointState: ControlPointState;

  @Input()
  public set models(value: PumpSchedule[]) {
    this._models = value;
    this._model = value[this.defaultIndex];

    //dont execute new data processing before initialization
    if (this.formControl){
      this.onModelChange();
    }
  }

  public get models(): PumpSchedule[]{
    return this._models
  }

  public get model(): PumpSchedule {
    return this.models[this.defaultIndex];
  }

  @ViewChild(PumpScheduleShoeTrackComponent)
  public shoeTrackComponent: PumpScheduleShoeTrackComponent;

  @ViewChildren(PumpStageComponent)
  public pumpStageComponents: QueryList<PumpStageComponent>;

  public pumpScheduleForm: UntypedFormGroup;
  public loadingSubscription: Subscription;
  public ControlPointState = ControlPointState;
  public jobStateCancel: boolean = false;
  public jobState: string;

  public get stageFormArray(): UntypedFormArray {

    return this.pumpScheduleForm?.get('stages') as UntypedFormArray;
  }

  private readonly destroy$ = new Subject();
  private watchStagesSubscription: Subscription;
  private subscription: Subscription = new Subscription();
  private _model: PumpSchedule;
  private _models: PumpSchedule[];
  constructor(
    private inj: Injector,
    private fb: UntypedFormBuilder,
    private pumpScheduleService: PumpScheduleService,
    private unitConversionService: UnitConversionService,
    private applicationStateService: ApplicationStateService,
    private jobStateService: JobStateService,
    public mailService: MailService,
    protected editJobAdapter: EditJobAdapter,
    public pumpScheduleAdapter: PumpScheduleAdapter,
    public pumpScheduleLogic: PumpScheduleLogic
  ) {
    super(inj);
  }

  public ngOnInit() {

    this.initPumpSchedule();

    this.initFluidsSubscription();

    this.initUnitsUpdateSubscription();

    this.pumpScheduleLogic.allowSave();
    this.subscription.add(this.applicationStateService.materialCostCalculated$
      .subscribe(() => {
        const pumpFluidDetails = this.pumpStageComponents ? this.pumpStageComponents.toArray().map(x => x.pumpFluidDetailComponent) : [];
        if (pumpFluidDetails.length && pumpFluidDetails.every(x => !x || x.isCogsCalculationComplete())) {
          if (this._model && pumpFluidDetails.every(x => !x || !x.isDirty()) && pumpFluidDetails.length === this._model.stages.length) {
            // eslint-disable-next-line
            this.pumpScheduleLogic.updateTotalCOGS([].concat.apply([], pumpFluidDetails.filter(x => x).map(x => x.getMaterialCOGSCalculationResult())), this._model);
          }
          if (this.pumpScheduleAdapter.updateFormsWithCOGSSum(this.pumpScheduleForm, pumpFluidDetails.map(x => x ? x.getStageTotalCOGS() : 0))) {
            this.applicationStateService.cogsRecalculationNeeded$.next(false);
          }
        }
      })
    );
    this.subscription.add(this.applicationStateService.cogsRecalculationNeeded$
        .pipe(delay(0), filter(x => x))
        .subscribe(() => this.applicationStateService.materialCostCalculated$.next(null)
    ));

    this.onModelChange();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public isFormDirty(): boolean {
    return this.pumpScheduleService.isFormChange || isFormDirty(this.pumpScheduleForm);
  }

  public isInvalid(): boolean {
    return (this.pumpStageComponents && this.shoeTrackComponent)
      ? (this.pumpStageComponents.toArray().some(stage => stage.isInvalid()) || !this.shoeTrackComponent.jptFormArray.valid)
      : false;
  }

  public markAsTouched() {
    super.markAsTouched();
    const stages = this.pumpStageComponents.toArray();
    stages.forEach(stage => stage.markAsTouched());
  }

  public markAsPristine() {
    super.markAsPristine();
    this.pumpScheduleForm.markAsPristine();
    const stages = this.pumpStageComponents.toArray();
    stages.forEach(stage => stage.markAsPristine());
  }

  public getSanitizedData() {

    const pumpSchedule = this.pumpScheduleForm.getRawValue() as PumpSchedule;
    const thickeningTimeListJpt = this.shoeTrackComponent.jptFormArray.getRawValue();

    pumpSchedule.stages.forEach((stage, idx) => {

      if (thickeningTimeListJpt.length) {

        const cementType = this.pumpScheduleAdapter.fluidTypes.find(f => f.name === FLUID_TYPE_SCHEDULE.CEMENT);
        const stageExistInJpt = thickeningTimeListJpt.find(f => stage.number >= 0 && f.stageNumber === stage.number + 1);

        if (stageExistInJpt && stage.pumpScheduleFluidTypeId === cementType.id) {

          stage.thickeningTime = stageExistInJpt.thickeningTime;
          stage.minThickeningTime = stageExistInJpt.minThickeningTime;
          stage.actualSafety = stageExistInJpt.actualSafety;
        }
      }

      if (typeof stage.slurry === 'string') {

        stage.slurry = { ...new FluidModel, id: null, displayName: stage.slurry, slurrySource: SlurrySource.PumpScheduleFluid };

      }

      const pumpStageComponent = this.pumpStageComponents.toArray()[idx];

      if (pumpStageComponent && pumpStageComponent.pumpFluidDetailComponent) {

        const pumpFluidDetailData = pumpStageComponent.pumpFluidDetailComponent.getSanitizedData();

        Object.keys(pumpFluidDetailData).forEach(key => {
        // eslint-disable-next-line
          if (pumpFluidDetailData.hasOwnProperty(key)) {
            stage[key] = pumpFluidDetailData[key];
          }
        });
      }
    });

    if (isFormValid(this.pumpScheduleForm)) {
      if (this.model) {
        pumpSchedule.isImportDataFromiCem = this.model.isImportDataFromiCem;
        pumpSchedule.isImportDataFromHDF = this.model.isImportDataFromHDF;
      }

      // reset fluid data when import Icem file
      this.applicationStateService.isCreateImportIcem = false;
    }

    if (this.job.isClonedJob) {

      const pumpStageModify = pumpSchedule.stages.map(stage => {

        stage.events = stage.events.map(event => ({ ...event, pumpScheduleEventId: null }));

        if (stage.slurry) {

          stage.fluidMaterials = this.materialModify(stage.fluidMaterials);
        }

        const slurry = { ...stage.slurry, id: null, jobId: Guid.Empty };

        if (stage.slurry) {
          slurry.fluidMaterial = this.copyMaterial(stage.slurry.fluidMaterial);
        }
        return { ...stage, slurry };
      });

      pumpSchedule.stages = pumpStageModify;
    }

    return pumpSchedule;
  }

  public onInsertStageBefore(stageIndex: number) {
    const stageControls = this.stageFormArray;
    stageControls?.insert(stageIndex, createPumpScheduleStage(this.fb));
    this.formChanged();
  }

  public onInsertStageAfter(stageIndex: number) {
    const stageControls = this.stageFormArray;

    stageControls?.insert(stageIndex + 1, createPumpScheduleStage(this.fb));
    this.formChanged();
  }

  public onDeleteStage(stageIndex: number) {

    const stageControls = this.stageFormArray;
    const fluidTypeId = stageControls.controls[stageIndex].value.pumpScheduleFluidTypeId;
    const topFluidTypeData = this.pumpScheduleAdapter.fluidTypes.find(x => x.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT);

    if (topFluidTypeData && fluidTypeId === topFluidTypeData.id) {
      this.pumpScheduleAdapter.disableSelectedTopArray = this.pumpScheduleAdapter.getFluidTypeStage(false);
      this.pumpScheduleService.updateType$.next(true);
    }

    stageControls.removeAt(stageIndex);
    this.formChanged();
  }

  public onReportMail() {
    this.mailService.sendReportSAPMismap();
  }

  public onSelectedFluid() {

    const pumpScheduleData = this.pumpScheduleForm.getRawValue() as PumpSchedule;
    const fluids = pumpScheduleData && pumpScheduleData.stages
      ? pumpScheduleData.stages.filter(stage => stage.slurry && stage.slurry)
        .map(stage => stage.slurry)
      : [];

    this.editJobAdapter.updateUsageFluid$(fluids);
  }

  private initUnitsUpdateSubscription() {

    this.unitConversionService.onSaveUnit$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {

        this.initPumpSchedule();

        // convert
        const placementMethodShutdown = this.pumpScheduleAdapter.placementMethodFirstStage$.value.find(e => e.name === 'Shutdown');
        const placementMethodShutdownId = placementMethodShutdown !== null ? placementMethodShutdown.id : null;

        this.pumpScheduleAdapter.convertToUserUnit(this.pumpScheduleForm.getRawValue(), placementMethodShutdownId);
        this.editJobAdapter.correctFluids();
      });
  }

  private initFluidsSubscription() {

    this.editJobAdapter.fluids$
      .pipe(takeUntil(this.destroy$))
      .subscribe(fluids => {
        this._cdRef.detach();
        if (this.editJobAdapter.isLoadingNewJob) {
          this.setFluid(fluids);
          this.editJobAdapter.isLoadingNewJob = false;
        }
        this.setDuration();
        this._cdRef.reattach();
      });
  }

  private setDuration() {

    if (!this.pumpScheduleForm) return;

    const stageForm = this.stageFormArray;

    stageForm.controls.forEach((stage: UntypedFormGroup) => {

      const events = stage.get('events') as UntypedFormArray;

      events.controls.forEach((event: UntypedFormGroup) => {

        if (event.controls.volume.value && event.controls.rate.value) {

          let duration = 0;

          if (+(event.controls.rate.value)) {
            duration = +(event.controls.volume.value) / +(event.controls.rate.value);
          }

          event.controls.duration.setValue(duration);

        } else if (!isNaN(event.controls.duration.value) && event.controls.duration.value && event.controls.duration.value !== null) {

          event.controls.duration.setValue((event.controls.duration.value));

        } else if (isNaN(event.controls.duration.value)) {

          const defaultValue = 0;

          event.controls.duration.setValue(defaultValue);
        }

        this.togglePumpEventControl(event);
      });
    });
  }

  private setOriginIndexForObjective() {

    // be use in case Duplicate Job;
    const stageControls = this.stageFormArray;

    stageControls.controls.forEach((stage: UntypedFormGroup) =>
      stage.controls.syncObjectiveId.setValue(stage.controls.order.value)
    );
  }

  private setFluid(fluids: FluidModel[]) {

    if (this.model && this.model.stages) {

      this.updateFluidProps(fluids);

      //WARNING: this.pumpScheduleForm.patchValue(this.job.pumpSchedule); - this cause form reset which was initialized during request
      this.updateStagesFormGroup(this.pumpScheduleForm);

      this.watchStageChanges(this.pumpScheduleForm.controls.stages as UntypedFormArray, this.job);

      this.applicationStateService.validateCompletedPumpSchedule$
        .pipe(takeUntil(this.destroy$))
        .subscribe((valueChange) => {

          if (valueChange) {

            const _formArr = this.pumpScheduleForm.controls.stages as UntypedFormArray;

            _formArr.controls.forEach((data) => {

              const form = data as UntypedFormGroup;

              const stageNumber = form.controls.number.value + 1;

              if (valueChange.stageNumber === stageNumber) {

                form.controls.isManualyThickeningTime.setValue(
                  form.controls.thickeningTime.value !== valueChange.thickeningTime,
                  {
                    emitEvent: !!form.controls.thickeningTime.value
                  });
              }
            });
          }
        });
    }
  }

  private updateStagesFormGroup(fg: UntypedFormGroup) {
    const stagesFa: UntypedFormArray = fg.controls.stages as UntypedFormArray;
    const orderDeviation = this.getOrderDeviation(stagesFa, this.model.stages);

    this.model.stages.forEach((stage: any) => {
      if (stage.slurry) {
        const findPredicateFunc = stage.id
          ? (fg: UntypedFormGroup) => fg.controls.id.value === stage.id
          : (fg: UntypedFormGroup) => fg.controls.order.value === stage.order - orderDeviation;
        const stageFg: UntypedFormGroup = stagesFa.controls.find(findPredicateFunc) as UntypedFormGroup;
        if (stageFg) {
          const slurryFg: UntypedFormControl = stageFg.controls.slurry as UntypedFormControl;
          if (slurryFg) {
            slurryFg.patchValue(stage.slurry);
          }
        }
      }
    });
  }

  // TODO: Remove this later. Incorrect order have to be fixed!
  private getOrderDeviation(formStages: UntypedFormArray, modelStages: PumpScheduleStageModel[]): number {
    formStages.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup) => a.controls.order.value - b.controls.order.value);
    modelStages.sort((a: PumpScheduleStageModel, b: PumpScheduleStageModel) => a.order - b.order);

    const firstFormStage = formStages.controls[0] as UntypedFormGroup;
    const firstModelStage = modelStages[0];

    return firstFormStage && firstModelStage ? firstModelStage.order - firstFormStage.controls.order.value : 0;
  }

  private updateTTT(stages: UntypedFormArray, fluids: FluidModel[]) {
    stages.controls.forEach((stage: UntypedFormGroup) => {
      const stageValue = stage.getRawValue();
      const slurryValue = stageValue.slurry;
      if (slurryValue) {
        slurryValue.thickeningTime = this.lookupTTT(stageValue, fluids);
        stage.controls.slurry.patchValue({ ...slurryValue });
      }
    });
  }

  private lookupTTT(stage: PumpScheduleStageModel, fluids: FluidModel[]): string[] {
    let thickeningTime = null;

    if (stage.slurry) {
      const slurryFluid = fluids.find(fluid =>
        this.isSameFlurryNo(fluid, stage.slurry) ||
        (
          this.isSameDensity(fluid, stage.slurry)
          && this.isSameTestTypeId(fluid, stage.slurry)
          && this.isSameSlurryTypeId(fluid, stage.slurry)
        )
      );

      const isPull = !stage.isManualyThickeningTime
        && this.job.jobTypeName && this.pumpScheduleAdapter.ThickeningTimeJobType.includes(this.job.jobTypeName.toLowerCase().trim());

      if (isPull) {
        if (slurryFluid && slurryFluid.status !== 'Draft') {
          thickeningTime = slurryFluid.thickeningTime || slurryFluid.originThickeningTime;
        } else {
          thickeningTime = null;
        }
      } else {
        thickeningTime = stage.thickeningTime;
      }
    }

    return thickeningTime;
  }

  private updateFluidProps(fluids: FluidModel[]) {
    this.model.stages.forEach(stage => {
      if (stage.slurry) {
        const thickeningTime = this.lookupTTT(stage, fluids);
        stage.slurry.thickeningTime = thickeningTime;
        const slurryFluid = fluids.find(fluid =>
          this.isSameFlurryNo(fluid, stage.slurry) ||
          (
            this.isSameDensity(fluid, stage.slurry)
            && this.isSameTestTypeId(fluid, stage.slurry)
            && this.isSameSlurryTypeId(fluid, stage.slurry)
          )
        );

        if (slurryFluid) {
          stage.slurry.displayName = slurryFluid.displayName;
        }

        const pumpScheduleStatus = fluids.find(x => x.slurryId === stage.slurry.slurryId);
        if (pumpScheduleStatus) {
          stage.slurry.status = pumpScheduleStatus.status;
        }
      }
    });
  }

  private prepareDataForEditing() {
    if (this.job && this.model) {
      // Add stages
      if (this.model.stages && this.model.stages.length > 0) {
        const stagesFormGroups = this.model.stages.map(stage => {
          const stagesFormGroup = createPumpScheduleStage(this.fb);
          stagesFormGroup.controls.events = this.fb.array(stage.events.map(() => createEmptyEvent(this.fb, stage.order)));
          return stagesFormGroup;
        });
        this.initStages(stagesFormGroups);
      }

      // Load default stage when edit pump
      if (!this.model.stages || (this.model.stages && this.model.stages.length === 0)) {
        this.model.stages = [];
        this.initDefaultStage();
      }

      this.pumpScheduleAdapter.placementMethodFirstStage$.pipe(takeUntil(this.destroy$))
        .subscribe(x => {

          const placementMethodShutdown = x.find(e => e.name === 'Shutdown');
          const placementMethodShutdownId = placementMethodShutdown ? placementMethodShutdown.id : null;

          this.pumpScheduleAdapter.convertToUserUnit(this.pumpScheduleForm.getRawValue(), placementMethodShutdownId);
          this.pumpScheduleForm.patchValue(this.model);
        });

      this.setDuration();

      // Reset field enable when load data edit not TOP
      const stageControls = this.stageFormArray;

      this.pumpScheduleAdapter.fluidTypePumpScheduleData$
        .pipe(filter(x => !!x.length), takeUntil(this.destroy$))
        .subscribe(x => {

          const topFluidTypeData = x.find(x => x.name === FLUID_TYPE_SCHEDULE.PLUG_DART || x.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT);

          stageControls.controls.forEach((stage: UntypedFormGroup) => {
            if (stage.controls.pumpScheduleFluidTypeId.value !== topFluidTypeData.id) {
              // Reset enable data for fluid type when create pump
              this.pumpScheduleAdapter.initFluidType();
            }
          });
        });

      if (!this.job.canEdit) {
        this.pumpScheduleForm.disable();
        // reset when edit is disable
      } else {
        this.pumpScheduleForm.controls.shoeTrackLength.enable();
        this.pumpScheduleForm.controls.shoeTrackVolume.enable();
      }

      // Check disable all field when job is Cancel
      if ((this.job ? this.job.jobStatus === this.jobStateService.findJobState('Cancelled') : false) || (this.job ? this.job.jobStatus === this.jobStateService.findJobState('Closed') : false)) {
        this.jobStateCancel = true;
      } else {
        this.jobStateCancel = false;
      }
    }
  }

  materialModify(fluidMaterial) {
    return fluidMaterial
      ? fluidMaterial.map((material: any) => ({ ...material, id: null, fluidMaterialId: null }))
      : null;
  }

  copyMaterial(fluidMaterial) {
    return fluidMaterial
      ? fluidMaterial.map((material: any) => ({ ...material, id: null, slurryId: null }))
      : null;
  }

  private onModelChange(): void {

    forkJoin({
      firstStageSource: this.pumpScheduleAdapter.initPlacementMethodFirstStage(),
      otherStageSource: this.pumpScheduleAdapter.initPlacementMethodOtherStage(),
      fluidTypePumpScheduleDataSource: this.pumpScheduleAdapter.initFluidTypePumpScheduleData()
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {

        this.subscribeToDialogAction();

        if (this.pumpScheduleForm) {
          this.initDefaultStage();
        }

        if (this.job.id !== Guid.Empty && this.job.id) {

          this._cdRef.detach();
          this.setFluid(this.editJobAdapter.fluids$.value);
          this._cdRef.reattach();
        }

        if (this.model) {
          this.prepareDataForEditing();

          if (this.job.isClonedJob) {
            this.setOriginIndexForObjective();
          }
        }

        this.jobState = this.job && this.job.jobStatus === this.jobStateService.findJobState('Completed') ? 'Completed' : '';

        this._cdRef.markForCheck();
      });
  }

  private subscribeToDialogAction() {

    this.applicationStateService.createAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res === ActionState.Created) {
          this.job = new Job();
          this.pumpScheduleForm.reset();

          // reset when edit is disable
          this.pumpScheduleForm.controls.shoeTrackLength.enable();
          this.pumpScheduleForm.controls.shoeTrackVolume.enable();
          this.pumpScheduleForm.controls.scheduledShutdown.enable();
          this.pumpScheduleForm.controls.targetSafetyFactor.enable();
          this.pumpScheduleForm.controls.batchMixingTime.enable();
          this.pumpScheduleForm.controls.spacerMixMethod.enable();

          this.pumpScheduleForm.controls.spacerMixMethod.setValue(SpacerMixMethod.MixOnTheFly);
          this.pumpScheduleForm.controls.isManuallySpacerMixMethod.setValue(false);

          const defaultValue = 0;
          const stages = this.stageFormArray.controls;
          const firstStages = stages[0] as UntypedFormGroup;
          // const firstEvents = (((firstStages).get('events') as FormGroup).controls[0] as FormGroup).controls;
          // TODO potential regression bug.

          // firstEvents.volume.setValue(defaultValue);

          firstStages.controls.volume.setValue(defaultValue);

          // Init Stage
          this.initDefaultStage();
        }
      });
  }

  private initDefaultStage() {
    this.reset();

    this.pumpScheduleAdapter.fluidTypePumpScheduleData$
      .pipe(filter(x => !!x.length), takeUntil(this.destroy$))
      .subscribe(x => {

        const defaultpumpScheduleFluidType = x.find(e => e.name === FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD);

        const stagesFormGroup = createPumpScheduleStage(this.fb);

        if (defaultpumpScheduleFluidType) {

          stagesFormGroup.controls.pumpScheduleFluidTypeId.setValue(defaultpumpScheduleFluidType.id);
        }

        this.initStages([stagesFormGroup]);

        this._cdRef.markForCheck();
      });
  }

  private initStages(stages: UntypedFormGroup[]) {

    if (this.watchStagesSubscription) {

      this.watchStagesSubscription.unsubscribe();
    }
    this.pumpScheduleForm = createPumpScheduleForm(this.fb);
    this.pumpScheduleForm.controls.stages = this.fb.array(stages);

    this.applicationStateService.jobTypeChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {

        const stagesFormArray = this.pumpScheduleForm.controls.stages as UntypedFormArray;

        this.job.jobTypeName = value.label;

        this.updateTTT(stagesFormArray, this.editJobAdapter.fluids$.value);
      });

    this.watchStageChanges(this.pumpScheduleForm.controls.stages as UntypedFormArray, this.job);

    this._cdRef.markForCheck();
  }

  private initPumpSchedule() {

    this.formControl = this.pumpScheduleForm = createPumpScheduleForm(this.fb);
  }

  private watchStageChanges(stages: UntypedFormArray, job: Job) {
    this.pumpScheduleAdapter.watchStageChanges(stages, job);
    this.watchStagesSubscription = stages.valueChanges.pipe(
      map(res => res.length),
      distinctUntilChanged()
    ).subscribe(() =>
      this.pumpScheduleAdapter.watchStageChanges(stages, job)
    );
  }

  formChanged() {
    this.pumpScheduleService.isFormChange = true;
    this.applicationStateService.cogsRecalculationNeeded$.next(true);
  }

  private togglePumpEventControl(event: UntypedFormGroup) {
    const controlOptions = {
      emitEvent: false,
      onlySelf: true
    };
    const eventsElementAtt = [['rate', true], ['volume', true], ['topOfFluid', true], ['length', true], ['duration', false], ['bulkCement', true]];
    const placementMethodId = event.value.placementMethod;

    if (placementMethodId) {

      const selectedPlacementMethod = this.pumpScheduleAdapter.placementMethodFirstStage$.value.find(e => e.id === placementMethodId);

      if (selectedPlacementMethod && selectedPlacementMethod.name === 'Shutdown') {

        const disableElements = eventsElementAtt.filter(value => value[1]);
        const enableElements = eventsElementAtt.filter(value => !value[1]);

        disableElements.forEach(element => {
          event.get(element[0].toString()).disable(controlOptions);
        });

        controlOptions.onlySelf = false;


        enableElements.forEach(element =>
          event.get(element[0].toString()).enable(controlOptions)
        );
      }
    }

  }

  private isSameFlurryNo(fluid, slurry) {
    return fluid.requestId && fluid.requestId === slurry.requestId && fluid.slurryNo === slurry.slurryNo;
  }

  private isSameDensity(fluid, slurry) {
    return !fluid.requestId && fluid.density && fluid.density === slurry.density;
  }

  private isSameTestTypeId(fluid, slurry) {
    return fluid.testType === slurry.testType || fluid.testTypeId === slurry.testTypeId;
  }

  private isSameSlurryTypeId(fluid, slurry) {
    return fluid.slurryTypeId === slurry.slurryTypeId;
  }

  openStageCogsHelp(stage: PumpScheduleStageModel): void {
    const stageComponent = this.pumpStageComponents.find((psc: PumpStageComponent) => {
      return stage.id ? psc.stageForm.value.id === stage.id : psc.stageForm.value.order === stage.order;
    });
    const fluid = stageComponent.pumpFluidDetailComponent.getSanitizedData();

    this.pumpScheduleLogic.openCogsHelp([fluid], this.job.groupName, this.job.group);
  }

  openJobCogsHelp(): void {
    const pumpStageComponents = this.pumpStageComponents.toArray()
      .filter((c: PumpStageComponent) => {
        const res = c.stageForm.controls.pumpScheduleFluidTypeId.value;
        const selectedFluidType = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === res);
        return c.pumpFluidDetailComponent
          && (!selectedFluidType ||
            (selectedFluidType.name !== FLUID_TYPE_SCHEDULE.BOTTOM_PLUG
              && selectedFluidType.name !== FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT));
      });

    const fluids = pumpStageComponents.map((s: PumpStageComponent) => s.pumpFluidDetailComponent.getSanitizedData());
    this.pumpScheduleLogic.openCogsHelp(fluids, this.job.groupName, this.job.group);
  }
}
