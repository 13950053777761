import { Component, Input } from '@angular/core';

@Component({
  selector: 'dash-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {


  @Input() title: string;
  @Input() search: boolean = false;
  @Input() delete: boolean = false;
  @Input() share: boolean = false;
  @Input() overflow: boolean = false;


  constructor() { }



}
