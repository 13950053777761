export class NoCogsMaterialModel {
    id: number;
    ifactsMeterilaName: string;
    sapMaterialName: string;
    sapMaterialNumber: string;
    appearenceInSlurrySet: number;
    genMaterialId: number;

    constructor(id: number,
        ifactsMeterilaName: string,
        sapMaterialName: string,
        sapMaterialNumber: string,
        appearenceInSlurrySet: number,
        genMaterialId: number) {

        this.id = id;
        this.ifactsMeterilaName = ifactsMeterilaName;

        if (sapMaterialName && sapMaterialNumber) {
            const sapMaterialNumberWithoutLeadingZeros = parseFloat(sapMaterialNumber);
            this.sapMaterialName = `${sapMaterialName}  (${sapMaterialNumberWithoutLeadingZeros})`;
        } else {
            this.sapMaterialName = ''
        }

        this.sapMaterialNumber = sapMaterialNumber;
        this.appearenceInSlurrySet = appearenceInSlurrySet;
        this.genMaterialId = genMaterialId;
    }
}

export class GroupTabModel {
    tabIndex: number;
    groupId: string;

    constructor(tabIndex: number, groupId: string) {
        this.tabIndex = tabIndex;
        this.groupId = groupId;
    }
}

export type MissingDataMaterial = NoCogsMaterialModel;
