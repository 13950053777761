import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { environment } from 'libs/environment';
import { HttpService } from './http.service';
import { ICustomerModel } from 'apps/vida/src/modules/shared/models';
import { tap } from 'rxjs/operators';

@Injectable()
export class CustomerService {
  constructor(private http: HttpService) { }

  searchCustomers(keyword: string): Observable<ICustomerModel[]> {
    // eslint-disable-next-line
    if (!!keyword) {
      return this.http.Post<ICustomerModel[]>(`${environment.baseUrl}/api/customers/search`, JSON.stringify({ keyword }));
    }
    return of([]);
  }

  searchParentCustomers(keyword: string): Observable<string[]> {
    // eslint-disable-next-line
    if (!!keyword) {
      return this.http.Post<string[]>(`${environment.baseUrl}/api/customers/search-parent`, JSON.stringify({ keyword }));
    }
    return of([]);
  }

  private _customerResponseCache: {[customerId: string]: ICustomerModel} = {};
  getCustomerById(customerId: string): Observable<ICustomerModel> {
    if (!customerId) {
      return of(null);
    }

    if (this._customerResponseCache[customerId] != null) {
      return of(this._customerResponseCache[customerId]);
    }
    return this.http.Get(`${environment.baseUrl}/api/customers/ByCustomerId/${customerId}`).pipe(
      tap((response: ICustomerModel) => {
        if (response){
          this._customerResponseCache[customerId] = response;
        }
      }));
  }
  getAllCustomer(): Observable<ICustomerModel> {
    return this.http.Get(`${environment.baseUrl}/api/Customers?startindex=0&pagesize=100000`);
  }
}
