import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';

import { WellModel } from 'libs/models';

import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import {
  CollectionService,
  WellService,
  EventHubService,
  ApplicationStateService,
  RoleService,
  MasterDataService,
  SessionService,
  ControlPointService2,
  UserSettingService,
  AdminService,
} from 'libs/shared/services';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { UnitType } from 'libs/constants';
import { LazyLoadingService } from 'libs/shared/services/lazy-loading.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { Guid } from '../../../shared/constant/string-comparision';
import { environment } from 'libs/environment';
import { PlantManagementService } from 'libs/shared/services/plant-management.service';

@AutoUnsubscribe()
@Component({
  selector: 'dash-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})

export class LeftNavComponent implements OnInit {

  @ViewChild('wellIcon') wellIcon: ElementRef;
  @ViewChild('wellTitle') wellTitle: ElementRef;
  @ViewChild('collectionIcon') collectionIcon: ElementRef;
  @ViewChild('collectionTitle') collectionTitle: ElementRef;

  UnitType = UnitType;

  openModal: boolean = false;
  modalDispay: boolean = false;
  isAdmin: boolean = false;
  isEnhanced: boolean = false;
  hasScope3access = false;
  isBulkBoardEnabled = false;

  isShowUserSettingSidebar: boolean;
  isShowLeftNav: any;
  createCollection: string;
  destroy$ = new Subject();

  listWellLib: WellModel[] = [];
  listWell: any;
  // TODO: use custom component for collapse/expand
  collectionExpanded = false;
  wellExpanded = false;
  toolExpanded = false;
  isIFactsVisualizerVisible: boolean = false;
  collections = [];
  activeItem = '';
  collectionChildExpanded = '';
  wellChildExpanded = '';


  isLoadingBulkBoardLink: boolean = false;

  srcArrowRight = '../../../../assets/images/arrow.png';
  srcArrowDown = '../../../../assets/images/arrow-down.png';

  appianKPISystemLink = environment.appianKPISystemLink;

  appianBulkBoardPageLink = environment.appianBulkBoardPageLink;

  shouldDisplayBulkBoardItem$: Observable<boolean>;

  get inventoryToolLink() {
    return environment.inventoryToolLink;
  }

  private wellItemStatus = { isActive: false };
  private collectionItemStatus = { isActive: false };
  private subscription = new Subscription();


  constructor(
    public modalManagementService: ApplicationStateService,
    private roleService: RoleService,
    private collectionService: CollectionService,
    private wellService: WellService,
    private eventHubService: EventHubService,
    private router: Router,
    private masterDataService: MasterDataService,
    private bsModalService: BsModalService,
    private lazyLoadingService: LazyLoadingService,
    private sessionService: SessionService,
    private controlPointService: ControlPointService2,
    private userSettingService: UserSettingService,
    private renderer: Renderer2,
    private plantManagementService: PlantManagementService,
    private adminService: AdminService,
  ) {
    this.isAdmin = this.roleService.isAdmin;
    this.isEnhanced = this.roleService.isEnhanced;
    this.isShowLeftNav = this.roleService.roles.length;
    this.setBulkBoardStatus();
  }

  ngOnInit() {
    this.activeItem = this.getUrlActive();
    this.collectionService.getAllCollections().subscribe(data => {
      this.collections = data;
    });

    this.subscription.add(this.wellService.getWellLib().subscribe(res => {
      res.map(well => {
        well['groupNameByWell'] = `${well.wellName} (${well.groupName})`;
        return well;
      });
      this.listWell = res;
    }));

    this.subscription.add(this.eventHubService.updateWell$.subscribe(() => {
      this.wellService.getWellLib().subscribe(res => {
        res.map(well => {
          well['groupNameByWell'] = `${well.wellName} (${well.groupName})`;
          return well;
        });
        this.listWell = res;
        this.wellExpanded = true;
      });
    }));

    this.collectionService.saveCollectionSuccess$.subscribe(() => {
      this.getAllCollection();
    });

    this.collectionService.refreshCollection$.subscribe(() => {
      this.getAllCollection();
    });

    this.subscription.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((x: NavigationEnd) => {
      if (x.url.indexOf(this.activeItem) === -1 && x.url.indexOf('/job-simple') === -1 && x.url.indexOf('/control-point/cp-detail') === -1 && x.url !== '/') {
        this.activeItem = x.url.replace('/', '');
      }

      if (x.url.includes('wells')) {
        this.shouldActivateClass(this.wellIcon, this.wellTitle, this.wellItemStatus, true);
      }
      else if (this.wellItemStatus.isActive) {
        this.shouldActivateClass(this.wellIcon, this.wellTitle, this.wellItemStatus, false);
      }

      if (x.url.includes('collections')) {
        this.shouldActivateClass(this.collectionIcon, this.collectionTitle, this.collectionItemStatus, true);
      }
      else if (this.collectionItemStatus.isActive) {
        this.shouldActivateClass(this.collectionIcon, this.collectionTitle, this.collectionItemStatus, false);
      }
    }));


    this.subscription.add(this.controlPointService.hasScope3Access(this.userSettingService.selectedLocation.groupId).subscribe(hasScope3access => {
      this.hasScope3access = hasScope3access;
    }));

    this.eventHubService.onGroupUpdated$.pipe(
      filter((action: string) => action === 'selectGroup'),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.controlPointService.hasScope3Access(this.userSettingService.selectedLocation.groupId).subscribe(hasScope3access => {
        this.hasScope3access = hasScope3access;
      });

      this.setBulkBoardStatus();
    });

    this.masterDataService.getBuildNumber();

    this.isIFactsVisualizerVisible = this.roleService.isReviewerOrAboveInAnyGroup();

    this.plantManagementService.onBulkBoardStatusChanged$.subscribe(data => {
      if (data.groupId === this.userSettingService.selectedLocation.groupId) {
        this.isBulkBoardEnabled = data.isBulkBoardEnabled;
        if (this.isBulkBoardEnabled) {
          this.shouldDisplayBulkBoardItem$ = this.plantManagementService.getAccessToBBlink(this.userSettingService.selectedLocation.groupId);
        }
      }
    });
  }

  private shouldActivateClass(iconEl: ElementRef, titleEl: ElementRef, itemStatus: { isActive: boolean }, setActive: boolean) {
    const iconElement = iconEl?.nativeElement;
    const titleElement = titleEl?.nativeElement;

    if (iconElement && titleElement) {
      if (setActive) {
        this.renderer.addClass(iconElement, 'icon-active');
        this.renderer.addClass(titleElement, 'title-active');
        itemStatus.isActive = true;
      }
      else {
        this.renderer.removeClass(iconElement, 'icon-active');
        this.renderer.removeClass(titleElement, 'title-active');
        itemStatus.isActive = false;
      }
    }
  }

  getAllCollection() {
    this.collectionService.getAllCollections().subscribe(data => {
      this.collections = data;
    });
  }

  showUserSetting() {
    this.modalManagementService.openUserSetting$.next(null);
  }

  showCreateOption() {
    this.modalManagementService.openCreateOption$.next(null);
  }

  showSecurityAdmin() {
    this.modalManagementService.openSecurityAdmin$.next(null);
  }

  showCollection() {
    this.modalManagementService.openCollection$.next(this.createCollection = null);
  }

  activeClass(activeItem) {
    this.activeItem = activeItem;
  }

  getUrlActive() {
    let urlPathName = window.location.pathname.replace('/', '');
    const urlQueryParam = window.location.search.replace('?id=', '');
    if (urlPathName === '') {
      urlPathName = 'active-jobs';
    }
    if (urlPathName === 'collections' && urlQueryParam) {
      this.collectionExpanded = true;
      this.collectionChildExpanded = urlQueryParam;
    }
    if (urlPathName === 'wells' && window.location.search) {
      this.wellExpanded = true;
      this.wellChildExpanded = urlQueryParam;
    }
    return urlPathName;
  }

  getSrc(isExpanded) {
    return isExpanded ? this.srcArrowDown : this.srcArrowRight;
  }

  showIfactSearchPage() {
    this.lazyLoadingService.loadIFactsSearchModule().then((m) => {
      this.bsModalService.show(
        m.components.IFactsSearchComponent,
        {
          initialState: {
            data: {
              jobId: Guid.Empty,
              jobStatus: null,
              saveRequestFn: null,
              prefUserName: this.sessionService.user.prefUserName,
              autoZIndex: true,
              groupId: null,
              groupName: null,
              wasCalledFromJob: false
            }
          },
          class: "full-screen-modal",
          ignoreBackdropClick: true,
          keyboard: false,
          id: MODAL_ID.IFACTS_BASIC_SEARCH,
        }
      );
    });
  }

  showCarbonFootprintSidebar() {
    this.modalManagementService.openCarbonFootprintSidebar$.next(null);
  }

  setBulkBoardStatus() {
    this.plantManagementService.getBulkBoardStatusByGroupId(this.userSettingService.selectedLocation.groupId)
      .subscribe(isBulkBoardEnabled => {
        this.isBulkBoardEnabled = isBulkBoardEnabled;
        if (this.isBulkBoardEnabled) {
          this.shouldDisplayBulkBoardItem$ = this.plantManagementService.getAccessToBBlink(this.userSettingService.selectedLocation.groupId);
        }
      });
  }

  updateAppianBulkBoardSettings() {
    this.userSettingService.updateAppianBulkBoardSettings( this.userSettingService.selectedLocation.groupId )
      .subscribe(() => {
          window.open(this.appianBulkBoardPageLink, "appianPageOpen");
      }
       );
  }

  showChemicalInventorySearchPage() {
    this.lazyLoadingService.loadChemicalSearchModule().then(m => {
      this.bsModalService.show(m.components.ChemicalSearchMainComponent,
        {
          id: MODAL_ID.CHEMICAL_SEARCH,
          class: 'full-screen-modal',
          initialState: {},
          ignoreBackdropClick: true,
          keyboard: false
        });
    });
  }

  openEditWell(event, wellId:string) {
    this.wellChildExpanded = wellId;
    this.activeClass('wells');

    const editWellParameters = {
      wellId: wellId
    };
    this.modalManagementService.openWellEdit$.next(editWellParameters);
  }
}
