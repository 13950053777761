import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { EventHubService, MemberSearchService, SessionService } from 'libs/shared/services';
import { HttpCommanderService, ApplicationStateService } from 'libs/shared/services';
import { ControlPointService2 } from 'libs/shared/services';
import { ConfirmDialogService } from 'libs/ui';
import { HandOver } from '../../../control-point/models';
import { HandOverComponentBase } from '../hand-over-base/hand-over-base';
import { VidaFileType } from '../../constant';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';

@AutoUnsubscribe()
@Component({
  selector: 'dash-hand-over-edit',
  templateUrl: './hand-over-edit.component.html',
  styleUrls: ['./hand-over-edit.component.scss']
})
export class HandOverEditComponent extends HandOverComponentBase implements OnInit {

  constructor(
    protected fb: UntypedFormBuilder,
    protected membersSearchService: MemberSearchService,
    protected controlPointService: ControlPointService2,
    protected HCService: HttpCommanderService,
    protected confirmDialogService: ConfirmDialogService,
    protected applicationStateService: ApplicationStateService,
    protected eventHubService: EventHubService,
    protected sessionService: SessionService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fb, membersSearchService, controlPointService, HCService, confirmDialogService, applicationStateService, sessionService, eventHubService, cdr)
  }

  @Input() flagDisableFields: boolean;
  @Input() flagCP1Approved: boolean;
  @Input() showHandower: boolean = false;
  @Input() handoverInput: HandOver;
  @Output() closeHandover: EventEmitter<any> = new EventEmitter();

  submitting: boolean = false;

  ngOnInit() {
    this.getFile();
    this.initAutoComplete();
    this.getHandOver();
    this.CP1NotCompletedOrSubmitted = this.flagDisableFields;
    this.CP1Approved =this.flagCP1Approved;
    this.applyHandOverValidation = true;

    this.applicationStateService.cp2HandoverFileChanged$.subscribe((message) => {
      if (message === 'deleted') this.handoverFile = null;
      else this.getFile();
    });
  }

  public get flagBlockFields() : boolean {
    const result = this.handOverDisabled && (this.flagDisableFields && !this.flagCP1Approved);
    return result;
  }

  submit(event: Event) {
    if (this.submitting) return;
    this.submitting = true;

    const result = this.saveHandOver().subscribe(() => {
      this.submitting = false;
      this.applicationStateService.cp2HandoverSubmitted$.next(result);
      this.close(event);
    }, (err) => {
      this.submitting = false;
      console.error(err);
    });
  }

  close(event: any) {
    this.closeHandover.emit(event);
  }

  fileUploaded(e: any) {
    const virtualPath: string = e.virtualRelativeFilePath;
    this.HCService.setPrimaryFile(virtualPath
      , this.jobId
      , this.wellId
      , VidaFileType.Handover)
      .subscribe((data: any) => {
        if (data.statusCode === 200) {
          this.setHandoverFile(data.result);
          this.applicationStateService.cp2HandoverFileChanged$.next('uploaded');
        }
      });
  }

}
