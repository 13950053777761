import { Injectable } from '@angular/core';
import { map, tap, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable, of, forkJoin } from 'rxjs';

import { PDropDownModel } from 'libs/ui';
import { MasterDataService, IfactService, FluidService, JobsService } from 'libs/shared/services';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MaterialNumberAssignedFlag, SlurrySource } from '../../shared/constant/slurry-source';
import { FluidModel, Job, PumpSchedule, ObjectiveModel, PumpScheduleStageModel, MaterialManagementMappingModel } from 'libs/models';
import { ObjectState, Guid, JobActionMode } from '../../shared/constant';
import { uniqWith } from 'libs/helpers/lodash-helper';
import { FluidFormFactory } from '../../fluids/components';
import { PrimaryStatus } from 'libs/constants';
import { JobMappingModel, JobMaterialSAPMapping, fnJobMapping } from '../../shared/logic/material-sap-mapping.logic';

// TODO consider making this service as per component, rather than singleton
@Injectable({ providedIn: 'root' })
export class EditJobAdapter {
  isLoadingNewJob: boolean = false;
  isFluidReady$ = new BehaviorSubject<boolean>(false);
  fluids$ = new BehaviorSubject<FluidModel[]>([]);
  fluidForm$ = new BehaviorSubject<UntypedFormGroup>(this.fb?.group({
    slurry: this.fb.array([])
  }));
  get fluidFormArray(): UntypedFormArray { return this.fluidForm$.value.controls.slurry as UntypedFormArray; }
  testTypeData$ = new BehaviorSubject<PDropDownModel[]>([]);
  slurryTypeData$ = new BehaviorSubject<PDropDownModel[]>([]);
  updateFluidsFromFluidTab$: Subject<object> = new Subject();
  isStageJob: boolean;

  //It should be deleted
  public availableSapMappings: MaterialManagementMappingModel[] = [];
  public getAvailableSapMappings$(groupId: string): Observable<MaterialManagementMappingModel[]> {
    return this._materialSAPMappingLogic.loadSAPMapping(groupId).pipe(
      tap((x) => { this.availableSapMappings = x; })
    );
  }
  updateSAPMaterial$ = new Subject<any>();
  updateSAPMaterialForCP$ = new Subject<any>();
  removeSAPMaterial$ = new Subject<any>();
  updateSAPMaterialFromPump$ = new Subject<any>();
  availableSapMappingsGroupChanged$ = new Subject<any>();

  public pumpScheduleModel$: Observable<PumpSchedule[]>;
  public slurryListModel$: Observable<FluidModel[]>;

  private readonly _fluidSlurryTypeChangesSrc = new Subject<FluidModel>();
  private readonly _materialSAPMappingLogic: JobMaterialSAPMapping;
  public readonly slurryTypeChanges$: Observable<FluidModel> =
    this._fluidSlurryTypeChangesSrc.asObservable().pipe(shareReplay());

  constructor(
    private fb: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private ifactService: IfactService,
    private fluidService: FluidService,
    private jobService: JobsService,
    private readonly _fluidFormFactory: FluidFormFactory
  ) {
    this.initMasterData();
    this._materialSAPMappingLogic = new JobMaterialSAPMapping(ifactService, this.getMaterialJobMapping);
  }

  updateFluids$() {
    const fluids = this.fluidFormArray.getRawValue() as FluidModel[];
    const existed = fluids.map(x => x.id);
    const manualFluidInPump = this.fluids$.value.filter(x => !existed.includes(x.id) && x.slurrySource === SlurrySource.PumpScheduleFluid); // will not show in fluid tab
    this.fluids$.next([...fluids, ...manualFluidInPump].sort(FluidService.sortFluid));
  }

  updateSlurryData(fluidMaterialDetail) {
    const slurries =
      this.fluidFormArray.getRawValue() as FluidModel[];

    const slurriesToUpdate = slurries.filter(x => x.fluidMaterial.find(x => x.materialId == fluidMaterialDetail.controls.materialId.value));

    slurriesToUpdate.forEach(selectedSlurry => {
      const materialsCopy = selectedSlurry.fluidMaterial;

      const byConcentrationDesc = materialsCopy
        .filter(fluid => fluid.materialType === 'Cement')
        .sort((a, b) => b.concentration - a.concentration);

      if (byConcentrationDesc.length === 0) {
        return null;
      }

      selectedSlurry.cementName = byConcentrationDesc[0].materialName;
    });
    this.updateFluidsCement(slurriesToUpdate);
    this.updateFluids$()
  }

  updateFluidsCement(fluid) {
    this.fluidFormArray.controls.forEach((f: UntypedFormGroup) =>{
      const updateSlurry = fluid.find(e => (e.tempId && f.controls.tempId.value && e.tempId === f.controls.tempId.value) ||
        (e.id && f.controls.id.value && e.id === f.controls.id.value));
      if(updateSlurry){
        f.patchValue(updateSlurry);
      }
    });
  }

  updateFluidsFromPump(fluid) {
    this.fluidFormArray.controls.forEach((f: UntypedFormGroup) => {
      if (f.controls.id.value === fluid['id'] || f.controls.tempId.value === fluid['id']) {
        f.controls.name.setValue(fluid['name'])
      }
    })
  }


  updateUsageFluid$(fluids: FluidModel[]) {
    this.fluidFormArray.controls.forEach((f: UntypedFormGroup) => {
      const formValue = f.getRawValue() as FluidModel;
      const usedFluid = this._findFluid(fluids, formValue);
      // eslint-disable-next-line
      if (!!usedFluid) {
        if(!this.isStageJob)
        {
          if(usedFluid.pumpScheduleNumber == 1)
          {
            f.controls.primaryStatus.setValue('Planned');
            f.controls.primaryStatusId.setValue(PrimaryStatus.Planned);
          }else{
            f.controls.primaryStatus.setValue(null);
            f.controls.primaryStatusId.setValue(null);
          }
        }else if(this.isStageJob){
          f.controls.primaryStatus.setValue('Planned' + usedFluid.pumpScheduleNumber);
          f.controls.primaryStatusId.setValue(PrimaryStatus.Planned);
        }
      } else {
        f.controls.primaryStatus.setValue(null);
        f.controls.primaryStatusId.setValue(null);
      }
    });
    this.fluidFormArray.controls = this.fluidFormArray.controls.sort(FluidService.sortFluidForm);
  }

  correctFluids() {
    this.fluids$.next(this.fluids$.value.map(fluid => {
      fluid.displayName = this.fluidService.buildDisplayName(fluid);
      return fluid;
    }).sort(FluidService.sortFluid)
    );
  }

  loadingMoreDataForFluids(job: Job, fluids: FluidModel[]) {
    return this.fluidService.loadFromIFacts(job, ...fluids).pipe(
      map(fluids => fluids.map(fluid => {
        fluid.displayName = this.fluidService.buildDisplayName(
          fluid,
          this.testTypeData$.value.map(ddv => {
            return { id: ddv.value, name: ddv.label };
          })
        );
        return fluid;
      })),
      tap(fluids => this.fluids$.next(fluids.sort(FluidService.sortFluid)))
    );
  }

  updateSourceSAPMaterialMapping(materialRowIndex: number, materialType: string, material: any) {
    this.updateSAPMaterial$.next({ materialRowIndex, materialType, material });
    this.updateFluids$();
  }

  public readJobObjectives(jobId: string, isClonedJob: boolean, originalJobId: string): Observable<ObjectiveModel> {

    return (jobId && jobId !== Guid.Empty || isClonedJob)
      ? this.jobService.getJobObjectives(!isClonedJob ? jobId : originalJobId)
        .pipe(tap(x => isClonedJob ? this.initCopiedJobObjectives(x) : x))
      : of(new ObjectiveModel());
  }

  public initPumpScheduleModelSource(jobId: string, isClonedJob: boolean, importedModel: PumpSchedule[], pumpSchedules$: Observable<PumpSchedule[]>): Observable<PumpSchedule[]> {

    return this.pumpScheduleModel$ = importedModel
      ? of(importedModel)
      : (jobId && jobId !== Guid.Empty
        ? pumpSchedules$
          .pipe(tap(x => x.map(item => isClonedJob ? this.initCopiedPumpSchedule(item) : item)), shareReplay())
        : of([]));
  }

  public loadDataAndInsertFluids(job: Job,...fluids: FluidModel[]): Observable<FluidModel[]> {

    fluids = this.removeDuplicatedFluid(...fluids);
    return forkJoin([
            this.fluidService.loadFromIFacts(job, ...fluids)
            , this.getAvailableSapMappings$(job.group)])
      .pipe(
        tap(([fluidModels, materialMappings]) => {
          // set sap materials for the added fluids
          fluidModels.forEach((f: FluidModel) => {
            this.updateFluidMaterialsSAPNumber(f, materialMappings);
          });

          this.insertFluids(job, ...fluidModels);
        }),
        // eslint-disable-next-line
        map(([fluidModels, _]) => fluidModels)
      );
  }

  public updateFluidMaterialsSAPNumber(fluidModel: FluidModel, materialMappings: MaterialManagementMappingModel[]){
    if (!fluidModel.fluidMaterial) return;
    fluidModel.fluidMaterial
      .filter(m => m.sapMaterialNumber == null || m.sapMaterialNumber == '')
      .forEach(fluid => {
          const material = materialMappings.find(mm => mm.materialId == fluid.materialId);
          if (material !== undefined
              && material.sapMaterialNumber != null
              && material.sapMaterialNumber != '') {
              fluid.sapMaterialNumber = material.sapMaterialNumber;
              fluid.sapMaterialName = material.sapMaterialName;
              fluid.materialNumberAssignedFlag = MaterialNumberAssignedFlag.SAPFromMaterialTable;
          }
      });
  }

  private removeDuplicatedFluid(...fluids: FluidModel[]): FluidModel[] {
    const newFluids = fluids.filter(f => {
      if (!f) {
        return false;
      }
      if (!f.slurryId || !f.requestId)
        return true;
      const foundF = this.fluidFormArray.controls.find(fc => {
        const frmFluid = fc as UntypedFormGroup;
        return frmFluid.controls.slurryId.value && frmFluid.controls.requestId.value &&
          f.slurryId.toString() === frmFluid.controls.slurryId.value.toString() &&
          f.requestId.toString() === frmFluid.controls.requestId.value.toString();
      });
      if (foundF && !foundF.value.id){
        foundF.get("id").patchValue(f.id);
        foundF.get("fluidMaterial").patchValue(f.fluidMaterial);
      }
      return !foundF;
    });
    return newFluids;
  }

  public insertFluids(job, ...fluids: any[]) {
    fluids.sort(FluidService.sortFluid).map(fluidModel => {
      const form = this._fluidFormFactory.createFluidForm(fluidModel)
      this.fluidFormArray.push(
        form)
      this.updateFluidsFromFluidTab$.next({
        id: fluidModel.id,
        name: fluidModel.name
      })
    });
    if (!job.canEdit) {
      this.fluidForm$.value.disable({ emitEvent: false, onlySelf: true });
    }
    this.updateFluids$();
  }

  public initSlurryListModelSource(jobId: string, isClonedJob: boolean, importedSlurryList: FluidModel[], jobActionMode: JobActionMode, pumpSchedules$: Observable<PumpSchedule[]>): Observable<FluidModel[]> {

    if (importedSlurryList && jobActionMode !== JobActionMode.CreateManual) {

      this.slurryListModel$ = of(importedSlurryList);

    } else if (jobId && jobId !== Guid.Empty) {

      if (isClonedJob) {

        if (!this.pumpScheduleModel$) {

          this.initPumpScheduleModelSource(jobId, isClonedJob, null, pumpSchedules$);
        }

        this.slurryListModel$ = this.pumpScheduleModel$
          .pipe(map(x => {
            const pumpSlurryList = this.getSlurryListFromPumpSchedules(x,isClonedJob,jobActionMode);
            return this.getSlurryListNonPumpSchedule(pumpSlurryList, importedSlurryList);
            }
        ));
      } else {

        this.slurryListModel$ = this.jobService.getSlurryList(jobId);
      }

    } else {

      this.slurryListModel$ = of(null);
    }

    return this.slurryListModel$.pipe(shareReplay());
  }

  raiseGroupChangedEvent(groupId) {
    this.getAvailableSapMappings$(groupId).subscribe(materialMappings => {
      this.availableSapMappingsGroupChanged$.next(materialMappings);
    });
  }

  fillMaterial(fluidMaterialDetail, index = null, isSap = false) {
    let slurry = '';
    if (index !== null) {
      const fluidForm = this.fluidFormArray.controls[index];
      const fluid = fluidForm as UntypedFormGroup;
      const mappingDetails = this.getMappingDetails(fluid);
      // eslint-disable-next-line
      slurry = mappingDetails.slurry;
    }

    const materialId = fluidMaterialDetail.controls.materialId ? fluidMaterialDetail.controls.materialId.value : fluidMaterialDetail.controls.ifactMaterialId.value;
    const sapMaterialNumber = fluidMaterialDetail.controls.sapMaterialNumber ? fluidMaterialDetail.controls.sapMaterialNumber.value : null;

    // sapmaterial
    if (materialId && !sapMaterialNumber && !isSap) {
      const item: JobMappingModel = this._materialSAPMappingLogic.findSAP(materialId, null, false);
      if (item) {
        let patchValue = null;
        if (item.isMaterialMappingTable){
          patchValue = {
            sapMaterialNumber: item.sapMaterialNumber,
            materialNumberAssignedFlag: MaterialNumberAssignedFlag.SAPFromMaterialTable,
            sapMaterialNumberAssignedValue: null
          }
        }
        else {
          patchValue = {
            sapMaterialNumber: item.sapMaterialNumber,
            materialNumberAssignedFlag: MaterialNumberAssignedFlag.SAPFromFluid,
            sapMaterialNumberAssignedValue: item.details.slurry
          }
        }
        fluidMaterialDetail.controls.sapMaterialNumber.setErrors(null);
        fluidMaterialDetail.patchValue(patchValue, { emitEvent: true });
      }
    }

    // ifact material
    else if (!materialId && sapMaterialNumber && isSap) {
        const item: JobMappingModel = this._materialSAPMappingLogic.findIFacts(sapMaterialNumber, null, false);
        if (item) {
          let patchValue = null;
          if (item.isMaterialMappingTable){
            patchValue = {
              materialId: item.materialId,
              materialName: item.iFactMaterialName,
              materialNumberAssignedFlag: MaterialNumberAssignedFlag.FromMaterialTable,
              sapMaterialNumberAssignedValue: null
            }
          }
          else {
            patchValue = {
              materialId: item.materialId,
              materialName: item.iFactMaterialName,
              materialNumberAssignedFlag: MaterialNumberAssignedFlag.FromFluid,
              sapMaterialNumberAssignedValue: item.details.slurry
            }
          }
          fluidMaterialDetail.controls.materialName.setErrors(null);
          fluidMaterialDetail.patchValue(patchValue, { emitEvent: true });
        }
    }
  }

  initMasterData() {
    this.masterDataService?.listTestTypes().pipe(
      map(res => res.map(itm => new PDropDownModel(itm.name, itm.id)))
    ).subscribe(res => this.testTypeData$.next(res));

    this.masterDataService?.listSlurryTypes().pipe(
      map(res => res.map(itm => new PDropDownModel(itm.name, itm.id)))
    ).subscribe(res => this.slurryTypeData$.next(res));
  }

  public slurryTypeChanges(fluid: FluidModel): void {

    this._fluidSlurryTypeChangesSrc.next(fluid);
  }

  blankFieldSpecial(fluidMaterials) {

    return fluidMaterials.map(material =>
    ({
      ...material,
      actualQty: null,
      overrideVolume: null
    })
    );
  }

  private calculationPlannedVolumeStage(stageModel: PumpScheduleStageModel) {

    const defaultValue = 0;
    const order = stageModel.order;
    const events = stageModel.events;

    if (order === 0) {
      return defaultValue;
    } else {
      let totalVolumeStage = 0;
      let count = 0;
      events.forEach(element => {
        if (element.volume) {
          count++;
          totalVolumeStage += +element.volume;
        }
      });
      if (isNaN(totalVolumeStage)) {
        return null;
      } else {
        return count === 0 ? '' : +totalVolumeStage;
      }
    }
  }

  private initCopiedJobObjectives(x: ObjectiveModel): void {
    x.jobId = null;
    x.objectiveList.forEach(o => {
      o.id = null;
      o.optionObjectiveMet = null;
      o.resultComment = null;
      o.actual = null;
      o.state = ObjectState.Created;
    });
    x.designCriteriaList.forEach(d => {
      d.id = null;
      d.pumpStageId = null;
      d.state = ObjectState.Created;
    });
    x.contingencyList.forEach(c => {
      c.id = null;
      c.state = ObjectState.Created;
    });
  }

  private initCopiedPumpSchedule(x: PumpSchedule) {
    x.jobId = null;
    x.pumpScheduleId = null;
    x.stages = x.stages.map((stage: any) => {
      stage.id = null;
      if (stage.loadoutVolume == null) {
        stage.loadoutVolume = this.calculationPlannedVolumeStage(stage);
      }
      stage.actualVolumePumped = stage.actualVolumePumped === null || stage.actualVolumePumped === undefined ? stage.plannedVolume : stage.actualVolumePumped;
      stage.isChangeActualVolumePumped = true; // If Duplicate job not popuplate data for field Actual Qty in Fluid Material Details
      stage.isBulkCement = false; // If set Load out volume by default Plan volume => bulk cemment is cauculator
      stage.fluidMaterials = this.blankFieldSpecial(stage.fluidMaterials);
      stage.syncObjectiveId = stage.order;
      return { ...stage };
    });
  }

  private getSlurryListFromPumpSchedules(pumpSchedules: PumpSchedule[],isClonedJob: boolean, jobActionMode: JobActionMode): FluidModel[] {

    const listAllFluid = []
    if(isClonedJob && jobActionMode === JobActionMode.CreateManual){
      pumpSchedules.forEach(pumpSchedule => {
        pumpSchedule.stages.map(item => {
          if(item && item.slurry){
            const isDuplicated = listAllFluid.find(i => i.id === item.slurry.id)
            // eslint-disable-next-line
            !!!isDuplicated && listAllFluid.push(item.slurry);
          }
        })
      })

      return listAllFluid
    }

    const fluids = [];
    pumpSchedules.forEach(pumpSchedule => {
      let slurryList = pumpSchedule.stages
        .filter(x => x.slurry && (x.slurry.primaryStatus === 'Planned' || x.slurry.slurryIdHDF != null))
        .map(x => x.slurry);
      slurryList = uniqWith(slurryList, (l, r) => l.id === r.id);

      const slurryDetail = slurryList.map(slurryDetail => ({
        ...slurryDetail,
        id: null,
        fluidMaterial: slurryDetail.fluidMaterial.map(material => ({ ...material, id: null, slurryId: null }))
      }));
      slurryDetail.map(item => {
        const isDuplicated = fluids.find(i => i.slurryIdHDF === item.slurryIdHDF)
        !isDuplicated && fluids.push(item);
      })
    });

    return fluids;
  }

  private getSlurryListNonPumpSchedule(pumpSlurryList: FluidModel[], importedSlurryList: FluidModel[]): FluidModel[] {
    const listAllFluid = pumpSlurryList;
    importedSlurryList.forEach(item => {
        if(item && item.slurryId){
          const isExistBySlurryId = listAllFluid.findIndex(i => i.slurryId === item.slurryId) !== -1;

          if (!isExistBySlurryId) {
            const isExistByRequestId = listAllFluid.findIndex(i => i.requestId === item.requestId && i.slurryNo === item.slurryNo) !== -1;
            !isExistByRequestId && listAllFluid.push(item);
          }
        }
      });
    return listAllFluid;
  }

  private _findFluid(fluidList: FluidModel[], fluid: FluidModel): FluidModel {

    if (!fluid.id && fluid.slurrySource === SlurrySource.HDFFluid) {

      if (fluid.requestIdHDF) {

        return fluidList.find(f => f.requestIdHDF === fluid.requestIdHDF) || null;
      }

      if (fluid.requestIdHDF) {

        return fluidList.find(f => f.slurryIdHDF === fluid.slurryIdHDF) || null;
      }

      if (fluid.tempId) {

        return fluidList.find(f => f.tempId === fluid.tempId) || null;
      }
    }

    if (fluid.id) {

      return fluidList.find(f => f.id === fluid.id) || null;

    } else {

      if (fluid.tempId) {

        return fluidList.find(f => f.tempId === fluid.tempId) || null;
      }
    }

    if (fluid.requestId && fluid.slurryNo) {

      return fluidList.find(f => f.requestId === fluid.requestId && f.slurryNo === fluid.slurryNo) || null;
    }

    if (fluid.displayName) {

      return fluidList.find(f => f.displayName === fluid.displayName) || null;
    }

    return null;
  }

  getMaterialJobMapping: fnJobMapping = (): JobMappingModel[] => {
    if (this.fluidFormArray == null){
      throw 'findJobMapping without initialization';
    }
    const result: JobMappingModel[] = [];
    this.fluidFormArray.controls.forEach(fluidForm => {
      const jobModels: JobMappingModel[] = [];
      const fluid = fluidForm as UntypedFormGroup;
      jobModels.push(...this.getCtrlMaterials(fluid.controls.fluidBlendMaterial));
      jobModels.push(...this.getCtrlMaterials(fluid.controls.fluidAdditiveMaterial));
      jobModels.push(...this.getCtrlMaterials(fluid.controls.supplementalMaterial));
      if (jobModels.length > 0){
        const details = this.getMappingDetails(fluid);
        jobModels.forEach(x => x.details = details);
      }
      result.push(...jobModels);
    });
    return result;
  }

  private getCtrlMaterials(fgMaterials: any): JobMappingModel[] {
    const result: JobMappingModel[] = [];
    fgMaterials.controls.forEach(fgMaterial => {
      result.push({
        materialId: fgMaterial.controls.materialId.value,
        iFactMaterialName: fgMaterial.controls.materialName.value,
        sapMaterialNumber: fgMaterial.controls.sapMaterialNumber.value,
        isMaterialMappingTable: false,
        details: undefined
      });
    });
    return result;
  }

  private getMappingDetails(fluid: UntypedFormGroup) {
    const slurryId = fluid.controls.id.value ? fluid.controls.id.value : fluid.controls.slurryIdHDF.value;
    let slurry = '';
    if (fluid.controls.requestId.value) {
      slurry = `${fluid.controls.requestId.value}/${fluid.controls.slurryNo.value}`;
    } else if (fluid.controls.requestIdHDF.value) {
      slurry = fluid.controls.requestIdHDF.value;
    }
    return { slurryId, slurry };
  }
}
