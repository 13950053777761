import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Job, ChildParentJobModel } from 'libs/models';
import { ControlPointState } from '../../constant';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalRemoveLinkOriginJobComponent } from '../modal-remove-link-origin-job/modal-remove-link-origin-job.component';
import { Subscription } from 'rxjs';
import { JobsService } from 'libs/shared/services';
import { MessageService } from 'primeng/api';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { environment } from 'libs/environment';

@Component({
  selector: 'dash-job-originating',
  templateUrl: './job-originating.component.html',
  styleUrls: ['./job-originating.component.scss']
})
export class JobOriginatingComponent {

  @Input() job: Job;
  // eslint-disable-next-line
  @Output() onConfirm: EventEmitter<string> = new EventEmitter();

  isShowModal = false;
  isShowModalRemove=false;
  loadingSubscription: Subscription;

  get canEdit(): boolean {
    return this.checkEditLinkJobIsValid() && this.job.canEdit;
  }

  constructor(private modalService: BsModalService,
    private jobService: JobsService,
    private messageService: MessageService) { }

  openModalLinkJob() {
    if (this.canEdit) {
      this.isShowModal = true;
    }
  }

  checkEditLinkJobIsValid() {
    const statesAllow = [
      ControlPointState.NonSubmitted,
      ControlPointState.Unlock,
      ControlPointState.Rejected
    ];

    const cp1 = this.job.controlPoints?.find(x => x.type === 1);

    if (!cp1) {
      return false;
    }

    return statesAllow.indexOf(cp1.eControlPointState) >= 0;
  }

  showConfirmationOnRemoveLink() {

    if (!this.canEdit) {
      return;
    }

    const initialState = {confirm: false, callback: this.onConfirmRemoveLinkOrigin.bind(this)};
    this.modalService.show(ModalRemoveLinkOriginJobComponent, {
      initialState,
      class: 'mt-10p',
      ignoreBackdropClick: true,
      keyboard: false,
      id: MODAL_ID.REMOVE_LINK_ORIGIN_JOB
    });
  }

  onConfirmRemoveLinkOrigin(){
    this.removeLinkJob();
  }



  removeLinkJob() {
    const model = new ChildParentJobModel(this.job.id, this.job.originalJobId, '')
    this.loadingSubscription=this.jobService.removeJobLink(model).subscribe(
      (res) => {
        this.onConfirm.emit(null);
        if(res===true){
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'Job has been successfully disconnected from originating job' });
        }
      },
      (err) => console.log(err)
    )
  }
  confirmFromModal(jobOrigin) {
    this.onConfirm.emit(jobOrigin);
  }
}
