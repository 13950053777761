import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import {JobsService, UserSettingService} from 'libs/shared/services';
import {IUserSettingModel} from '../../../application/models';
import {UntypedFormControl} from '@angular/forms';
import { Time } from '@angular/common';
import { ProjectedDateDefaultTime } from './job-projected-date.constant';

@Component({
  selector: 'job-projected-date',
  templateUrl: './job-projected-date.component.html',
  styleUrls: ['./job-projected-date.component.scss']
})
export class JobProjectedDateComponent implements OnInit, OnChanges {

  @Input() actualDate: UntypedFormControl;
  @Input() isManualActualDate: UntypedFormControl;
  @Input() projectedDate: UntypedFormControl;
  @Input() jobStatusDescription: string;
  @Input() cp4State: string;
  @Input() canRemove = false;
  @Input() showTime = false;
  @Input() isDisabled: boolean = false;
  // eslint-disable-next-line
  @Output() onProjectedDateChange = new EventEmitter<any>();
  // eslint-disable-next-line
  @Output() onProjectedDateSelect = new EventEmitter<any>();
  // eslint-disable-next-line
  @Output() onDeleteProjectedDate = new EventEmitter<any>();
  // eslint-disable-next-line
  @Output() onBlur = new EventEmitter<any>();

  dateprojected: string;
  userSettings: IUserSettingModel;
  projectedDateTooltipText: string;
  maxDate: Date = null;
  projectedDateDefaultTime: Time = ProjectedDateDefaultTime;

  projectedDateChange = () => {
  };

  constructor(private jobService: JobsService,
              private userSettingService: UserSettingService,
              private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.userSettings = this.userSettingService.userSettings;

    if (this.actualDate.value) {
      this.maxDate = new Date();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    const {actualDate, projectedDate} = changes;

    if (actualDate || projectedDate) {
      this.actualDate.valueChanges.subscribe(() => {
        this._updateInternalProjectDate();
        this.actualDate.markAsDirty();
      });
      this.projectedDate.valueChanges.subscribe(() => {
        this.projectedDate.markAsDirty();
        this._updateInternalProjectDate();
      });
      this._updateInternalProjectDate();
    }

    if (this.jobStatusDescription === 'Completed' || this.jobStatusDescription === 'Cancelled') {
      document.querySelectorAll('p-calendar input').forEach((el) => el.setAttribute('tabindex', '-1'));
    }
  }

  _updateInternalProjectDate() {
    if (this.actualDate && this.actualDate.value) {
      this.dateprojected = this.actualDate.value ? this.actualDate.value.replace('Z', '') : this.actualDate.value; // ignore timezone
    } else {
      this.dateprojected = this.projectedDate.value ? this.projectedDate.value.replace('Z', '') : this.projectedDate.value; // ignore timezone
    }
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  _updateExternalProjectDate() {
    if (this.actualDate.value) {
      this.actualDate.setValue(this.dateprojected);
      if (this.isManualActualDate)
        this.isManualActualDate.setValue(true);
    } else {
      this.projectedDate.setValue(this.dateprojected);
    }
    this.onProjectedDateChange.emit(this.dateprojected);
  }

  onRemove() {
    if (this.canRemove) {
      this.onDeleteProjectedDate.emit();
    }
  }

  public onChange() {
    this._updateExternalProjectDate();
  }

  clearProjectedDate() {
    // set projected date = null when clear calendar
    this.dateprojected = '';
    this.cd.markForCheck();
    this.cd.detectChanges();
    this._updateExternalProjectDate();
  }

  setClasses() {
    return {
      'exception-disable-item': this.cp4State !== 'Completed',
      'disable-inner-elements': this.isDisabled
    };
  }

  _onBlur(date: Date) {
    if (new Date(date).getTime() > new Date().getTime()) {
      return;
    }
    if (this.actualDate.value) {
      this.actualDate.setValue(date);
      if (this.isManualActualDate)
        this.isManualActualDate.setValue(true);
    } else {
      this.projectedDate.setValue(date);
    }
  }

  _onSelect($event: Date) {
    this.onProjectedDateSelect.emit($event);
  }
}
