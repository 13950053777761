import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeZoneConverterHelper } from '../../shared/helpers/index';

@Pipe({name: 'timeFormatWithLocalTimeZone'})
export class TimeFormatWithLocalTimeZone extends DatePipe implements PipeTransform {
  // eslint-disable-next-line
  // @ts-ignore
  transform(value: Date, timeFormat: string): string {
    const localTime = TimeZoneConverterHelper(value.toString());

    const format = timeFormat.split(':');
    const date = new Date(localTime);
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const formattedTimeObject = {
      'hh': this.convert24Hours(hour),
      'mm': this.convert24Hours(minute),
      'ss': this.convert24Hours(second),
    };

    const dateAfterMatch = format.map(str => {
      return formattedTimeObject[str];
    });

    return dateAfterMatch.join(':');
  }

  convert24Hours(obj: number) {
    return (obj < 10) ? `0${obj}` : obj;
  }
}
