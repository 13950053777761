import {
  Directive,
  Injector,
  Input,
  ChangeDetectorRef,
  HostListener,
  AfterViewChecked,
  ElementRef,
  OnDestroy,
  Self, Optional,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormValueAccessor } from '../form-value-accessor';
import { formatNumber } from '../unit-conversions/unit-helper';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[numericPrecision]'
})
export class NumericPrecisionDirective extends FormValueAccessor implements AfterViewChecked, OnDestroy {
    _sourceValue: any = undefined;
    _formattedValue: any;

    protected cdRef: ChangeDetectorRef;
    private el: HTMLInputElement;
    private ctrlValueChangeSubscription: Subscription;

    @Input() decimalPlaces: number = 2;

    constructor(protected inj: Injector,
                elementRef: ElementRef,
                @Optional() @Self() private ngControl: NgControl
    ) {
        super(inj);
        this.cdRef = this.inj.get(ChangeDetectorRef);
        this.el = elementRef.nativeElement;
    }


    ngAfterViewChecked(){
        if (this._sourceValue == undefined) {
            this.el.value = this.ngControl.value;
            this.updateSrc(this.el.value);
            this.el.value = this._formattedValue;
            this.ngControl.valueAccessor.writeValue(this.el.value)
            if (this.control && !this.ctrlValueChangeSubscription) {
                this.ctrlValueChangeSubscription = this.control.valueChanges.subscribe((value) => {
                    this.updateSrc(value);
                    this.el.value = this._formattedValue;
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.ctrlValueChangeSubscription)
            this.ctrlValueChangeSubscription.unsubscribe();
    }

    @HostListener('focus', ['$event'])
    _onFocus() {
        this.el.value = this._sourceValue;
    }

    @HostListener('blur', ['$event'])
    _onBlur() {
       this.el.value = this._formattedValue;
    }

    private updateSrc(value: string) {
        this._sourceValue = value;
        this._formattedValue = formatNumber(value, this.decimalPlaces);
    }
}
