import { Injectable } from '@angular/core';

import {switchMap, map} from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'libs/environment';
import { WellModel } from 'libs/models';
import { HttpCommanderService } from './http-commander.service';
import { HttpService } from './http.service';
import { IAdditionalParams, IPagingResultModel } from 'apps/vida/src/modules/shared/models';
import { RoleLevels } from 'apps/vida/src/modules/application/models';

@Injectable()
export class WellService {
  well: WellModel;

  constructor(private http: HttpService, private hcService: HttpCommanderService) { }

  getGroups(minRoleLevel: number = RoleLevels.Reviewer) {
    return this.http.Get(`${environment.baseUrl}/api/admin/region-groups?minRoleLevel=${minRoleLevel}`);
  }

  getWellsByGroupId(groupId: string, additionalParams: IAdditionalParams): Observable<IPagingResultModel<WellModel>> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.http.Get(`${environment.baseUrl}/api/wells/groups/${groupId}/wells?${params}`);
  }

  saveWell(groupId: string, model: WellModel): Observable<boolean> {
    const url = `${environment.baseUrl}/api/wells/groups/${groupId}/wells`;
    const stringifyModel = JSON.stringify(model);

    if (!model.id) {
      return this.http.Post(url, stringifyModel).pipe(
        switchMap((wellId: string) => this.hcService.createJobOrWellFolderPath(wellId, null, true).pipe(map((x: number) => x === 200 || x === 202)))
      );
    }
    return this.http.Put(url, stringifyModel);
  }

  createNewWell(groupId: string, model: WellModel): Observable<any> {
    const url = `${environment.baseUrl}/api/wells/groups/${groupId}/wells`;
    const stringifyModel = JSON.stringify(model);

    return this.http.Post(url, stringifyModel);
  }

  createJobOrWellFolderPath(wellId: string): Observable<any> {
    return this.hcService.createJobOrWellFolderPath(wellId, null, true);
  }

  getWellDetails(wellId: string): Observable<WellModel> {
    return this.http.Get(`${environment.baseUrl}/api/wells/${wellId}`);
  }

  deleteWell(wellId: string): Observable<WellModel> {
    return this.http.Delete(`${environment.baseUrl}/api/wells/${wellId}`);
  }

  isExisted(model: WellModel):Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/wells/isexisted`, JSON.stringify(model));
  }

  isWellInJob(model: WellModel) {
    return this.http.Post(`${environment.baseUrl}/api/wells/is-well-in-job`, JSON.stringify(model));
  }

  getWellLib(): Observable<WellModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/well-library`);
  }

  saveToWellLibrary(wellId: string): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/well-library/${wellId}`, JSON.stringify(wellId));
  }

  removeToWellLibrary(wellId: string): Observable<any> {
    return this.http.Delete(`${environment.baseUrl}/api/well-library/${wellId}`);
  }

  saveJobByWellToLibrary(wellId: string, jobId: string): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/well-library/save-job-library/${wellId}/${jobId}`, JSON.stringify(wellId));
  }

  getWellByGroup(groupId: string, wellId: string = ''): Observable<Array<any>> {
    return this.http.Get(`${environment.baseUrl}/api/wells/groups/${groupId}?selectedWellId=${wellId}`);
  }

  switchWellActiveStatus(wellId: string, status: boolean): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/wells/${wellId}/status`, JSON.stringify(status));
  }

  checkWellState(wellName: string, groupId: string): Observable<any> {
    const well =  {wellName: wellName, groupId : groupId};
    return this.http.Post(`${environment.baseUrl}/api/wells/check-well-state`, JSON.stringify(well));
  }

  reActiveWellById(wellId: string): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/wells/${wellId}/status`, JSON.stringify(true));
  }

  reActiveWellByName(wellName: string, groupId: string): Observable<any> {
    const well =  {wellName: wellName, isActive: true};
    return this.http.Put(`${environment.baseUrl}/api/wells/groups/${groupId}/active-state`, JSON.stringify(well));
  }
}
