
export const DEFAULT_DECIMAL_PLACES = 3;
export const DEFAULT_PRECISION_SCALE = '25,12';

export function preciseRound(value, precision) {
  if(("" + value).indexOf("e") === -1) {
    return +(Math.round(Number(value + "e+" + precision))  + "e-" + precision);
  } else {
    const arr = ("" + value).split("e");
    let sig = ""
    if(+arr[1] + precision > 0) {
      sig = "+";
    }
    return +(Math.round(Number(+arr[0] + "e" + sig + (+arr[1] + precision))) + "e-" + precision);
  }
}

