import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of, BehaviorSubject, Subscription, Subject, EMPTY } from 'rxjs';
import { distinctUntilChanged, tap, filter, takeUntil, switchMap } from 'rxjs/operators';
import { ISAPMaterialModel } from 'libs/models';
import { MaterialService, MasterDataService, IfactService, ApplicationStateService, JobsService, JobCasingEquipmentService } from 'libs/shared/services';
import { ColumnDefinition } from 'libs/ui/models/column-definition';
import { TypeaheadDatatableComponent } from 'libs/ui/components/typeahead-datatable/typeahead-datatable.component';
import { SlurrySource } from 'libs/constants';
import { CEMaterialProvider, ChemicalMaterialProvider, iFactsMaterialProvider, ISAPMaterialProvider } from './sap-material-providers';
import { ChemicalSearchService } from 'libs/shared/services/chemical-search.service';

@Component({
  selector: 'sap-material',
  template: `
    <typeahead-datatable [formControl]="_sapMaterialDisplayName" [ngClass]="styleClass" [disabled]="disabled"
      styleClass="typeahead-material" [useTextArea]="useTextArea" [columns]="_columns" [options]="_sapMaterials"
      (onLazyLoad)="_onLazyLoadSAP($event)" (onKeyup)="_onKeyupSAP()" (onChange)="_onChangeSAP($event)"
      (onFocus)="_onFocusSAP($event)" (onFocusOut)="_onFocusOutSAP()" (onClear)="_onClearSAP($event)">
    </typeahead-datatable>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['sap-material.component.scss'],
})
export class SapMaterialComponent implements OnChanges, OnDestroy, OnInit {

  @Input() isIfactFirst: boolean;
  @Input() isCEMaterial: boolean = false;
  @Input() isCHMaterial: boolean = false;
  @Input() styleClass: string;
  @Input() sapMaterialNumber: UntypedFormControl;
  @Input() sapMaterialName: UntypedFormControl;
  @Input() generalMaterialId: UntypedFormControl;
  @Input() notExistedSAPMaterial: UntypedFormControl;
  @Input() slurrySource: SlurrySource;
  @Input() useTextArea: boolean;
  @Input() disabled: boolean;
  @Input() sapNumberDisplayFormat: string = null;
  @ViewChild(TypeaheadDatatableComponent, { static: true }) typeahead: TypeaheadDatatableComponent;
  // eslint-disable-next-line
  @Output() onChangeSAP = new EventEmitter<ISAPMaterialModel>();
  // eslint-disable-next-line
  @Output() onClearSAP = new EventEmitter<ISAPMaterialModel>();
  // eslint-disable-next-line
  @Output() onKeyupSAP = new EventEmitter();
  // eslint-disable-next-line
  @Output() onUpdateDisplayName = new EventEmitter<string>();

  _columns: ColumnDefinition[] = [
    new ColumnDefinition('id', '', false),
    new ColumnDefinition('materialName', 'SAP Material Name'),
    new ColumnDefinition('materialNumber', 'SAP Material Number'),
  ];
  _sapMaterials: ISAPMaterialModel[];
  _sapMaterialsIfact: ISAPMaterialModel[];
  _sapMaterialDisplayName: UntypedFormControl = new UntypedFormControl();
  _isSearching: boolean;
  _focus$ = new BehaviorSubject<any>(null);
  private _unsubscribe$ = new Subject<void>();
  _mappingSubscription: Subscription;
  private _materialProvider: ISAPMaterialProvider;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _materialService: MaterialService,
    private _masterDataService: MasterDataService,
    private _ifactService: IfactService,
    private _appState: ApplicationStateService,
    private _jobsService: JobsService,
    private _ceService: JobCasingEquipmentService,
    private _chService: ChemicalSearchService

  ) {
    this._sapMaterialDisplayName.statusChanges.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      if (this._sapMaterialDisplayName.errors) {
        this.sapMaterialNumber.setErrors(this._sapMaterialDisplayName.errors)
      }
      if (this._sapMaterialDisplayName.touched && !this.sapMaterialNumber.touched)
        this.sapMaterialNumber.markAsTouched();
      if (this._sapMaterialDisplayName.dirty && !this.sapMaterialNumber.dirty)
        this.sapMaterialNumber.markAsDirty();
    });

    this._ifactService.forceUpdateMapping$.subscribe(() => {
      if (this.generalMaterialId != null) {
        this._mappingSubscription =
          this._findMappingForSAPMaterial$(this.generalMaterialId.value)
          .subscribe(sapMaterials => this._sapMaterialsIfact = sapMaterials);
      }
    });

    this._focus$.pipe(filter(e => !!e)).subscribe(() => {
      if (!this.generalMaterialId) {
        return;
      }

      this.typeahead.showLoading();
      this.typeahead.showOverlayTable();
      this._mappingSubscription =
        this._findMappingForSAPMaterial$(this.generalMaterialId.value).pipe(takeUntil(this._unsubscribe$))
        .subscribe(sapMaterials => {
          this._sapMaterials = [...sapMaterials];
          this._cdRef.markForCheck();
        });
    });
  }

  ngOnInit(): void {
    this._materialProvider = this.isCEMaterial ? new CEMaterialProvider(this._ceService) :
      this.isCHMaterial ? new ChemicalMaterialProvider(this._chService) :
          new iFactsMaterialProvider(this._materialService, this._ifactService, this._masterDataService);

    if (this.notExistedSAPMaterial) {
      this.sapMaterialNumber.statusChanges.pipe(
        takeUntil(this._unsubscribe$),
        distinctUntilChanged()
      ).pipe(
        switchMap(() => {
          if (this.notExistedSAPMaterial.value) {
            return this._materialProvider.getSAPMaterialByNumber(this.sapMaterialNumber.value);
          }
          return EMPTY;
        })
      ).subscribe(sap => {
        if (!sap) {
          this.sapMaterialNumber.setErrors({ 'notExisted': true });
          this.sapMaterialNumber.markAsDirty();
          this._sapMaterialDisplayName.setErrors({ 'notExisted': true });
          this._sapMaterialDisplayName.markAsDirty();
          this._cdRef.markForCheck();
        }
      });
    }

    this._updateSAPMaterialDisplayName();
    this._lookupSAPMaterialNameByNumber();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { sapMaterialNumber, sapMaterialName } = changes;

    if (sapMaterialNumber) {
      this.sapMaterialNumber.valueChanges.pipe(takeUntil(this._unsubscribe$))
          .subscribe(() => this._lookupSAPMaterialNameByNumber());
      if (this.sapMaterialNumber.disabled)
        this._sapMaterialDisplayName.disable();
      else
        this._sapMaterialDisplayName.enable();
      if (!sapMaterialName.firstChange){
        this._updateSAPMaterialDisplayName();
        this._lookupSAPMaterialNameByNumber();
      }
    }

    if (sapMaterialName) {
      this.sapMaterialName.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this._updateSAPMaterialDisplayName();
      });
      this.sapMaterialName.statusChanges.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
        this._sapMaterialDisplayName.setErrors(this.sapMaterialName.errors);
        this._sapMaterialDisplayName.markAsTouched();
      });
      if (!sapMaterialName.firstChange){
        this._updateSAPMaterialDisplayName();
      }
    }


  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  _lookupSAPMaterialNameByNumber() {
    if (!this.sapMaterialNumber.value){
      this.sapMaterialName.setValue('');
      this._updateSAPMaterialDisplayName();
    }
    else {
      this._materialProvider.getSAPMaterialByNumber(this.sapMaterialNumber.value).pipe(takeUntil(this._unsubscribe$)).subscribe(sap => {
        if (sap && this.sapMaterialNumber.value == sap.materialNumber) {
          this.sapMaterialName.setValue(sap.materialName ? sap.materialName : null, { emitEvent: false });
          this._updateSAPMaterialDisplayName();
        }
      });
    }
  }

  _updateSAPMaterialDisplayName() {
    this._sapMaterialDisplayName.setValue(this._getDisplayName(this.sapMaterialNumber.value, this.sapMaterialName.value));
    this.onUpdateDisplayName.emit(this.sapMaterialName.value);
  }

  _getDisplayName(sapMaterialNumber, sapMaterialName: string) {
    if (!sapMaterialNumber || !sapMaterialName) {
      return '';
    }
    return this._formatDisplayName(sapMaterialNumber, sapMaterialName);
  }

  _formatDisplayName(materialNumber: string, materialName: string): string {
    let result = this.sapNumberDisplayFormat;
    if (result) {
      const shortSAPNumber: number = parseFloat(materialNumber);
      result = result.replace('[number]', materialNumber);
      result = result.replace('[shortnumber]', shortSAPNumber.toString());
      result = result.replace('[name]', materialName);
    } else {
      result = `${ materialName } (${ materialNumber })`;
    }
    return result;
  }

  _onLazyLoadSAP(keyword): void {
    if (this._isSearching && keyword) {
      this._materialProvider.searchMaterials(keyword).pipe(takeUntil(this._unsubscribe$))
        .subscribe(res => {
          this._sapMaterials = [...res];
          this._cdRef.markForCheck();
        });
    }
  }

  _onKeyupSAP() {
    this._isSearching = true;
    this.sapMaterialNumber.setValue(null, { emitEvent: false });
    this.sapMaterialName.setValue(null, { emitEvent: false });
    this.onKeyupSAP.emit();
  }

  _onChangeSAP(sapMaterial: ISAPMaterialModel) {
    if (sapMaterial) {
      this.sapMaterialNumber.setValue(sapMaterial.materialNumber, { emitEvent: false });
      this.sapMaterialName.setValue(sapMaterial.materialName, { emitEvent: false });
      if (sapMaterial.materialName && sapMaterial.materialNumber) {
        this._sapMaterialDisplayName.setValue(this._getDisplayName(sapMaterial.materialNumber, sapMaterial.materialName), { emitEvent: false });
      }
    }
    this.sapMaterialNumber.markAsDirty();
    this.onChangeSAP.emit(sapMaterial);
    this._appState.sapChanged$.next(sapMaterial);
  }

  _onFocusSAP(event) {
    // // When clicking on the input it will fire click event, for some reason, the click event is fired on overlaypant later than focus event here.
    // // The code below is a HACK to avoid the overlay disappear due to misleading click event.
    // // https://github.com/primefaces/primeng/blob/6.1.7/src/app/components/overlaypanel.ts#L82
    this._focus$.next(event);
  }

  _onFocusOutSAP() {
    if (this._mappingSubscription)
      this._mappingSubscription.unsubscribe();
  }

  _onClearSAP(event) {
    this._displayAvaiableOptions();
    this.onClearSAP.emit(event);
  }

  _displayAvaiableOptions() {
    if (this._sapMaterialsIfact && this._sapMaterialsIfact.length && !this._sapMaterialDisplayName.value) {
      this._sapMaterials = [...this._sapMaterialsIfact];
      this._cdRef.markForCheck();
    }
  }

  _findMappingForSAPMaterial$ = (generalMaterialId: number): Observable<ISAPMaterialModel[]> => {
    if (!generalMaterialId) return of([]);

    let groupId = this._jobsService.groupId$.value;
    if (this.isIfactFirst) {
      groupId = null;
    }

    if (groupId && this._sapMaterialsIfact !== undefined)
      return of(this._sapMaterialsIfact);

    return this._materialProvider.getSapMaterialDetails(generalMaterialId, groupId).pipe(
      tap(mappings => this._sapMaterialsIfact = mappings)
    );
  }
}

