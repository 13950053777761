import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { TestTableName, UCAField } from '../stage-test-results/stage-test-results.constant';
import { IFactsAvailableSlurry } from 'libs/models/ifact/ifacts-available-slurry';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';

@Component({
  selector: 'uca-test-result',
  templateUrl: './uca-test-result.component.html',
  styleUrls: ['./uca-test-result.component.scss']
})
export class UcaTestResultComponent extends PumpScheduleTestResultBase {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
  }
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
  }
  @Input() canEdit: boolean;
  // eslint-disable-next-line
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();

  UCAField = UCAField;

  get test(): PumpScheduleStageTest | null {
    if (!this.tests || this.tests.length === 0)
      return null;

    if (this.tests.length === 1)
      return this.tests[0];

    const finishedValidatedTests = this.tests.filter(x => this.isTestFinishedOrValidated(x));

    if (finishedValidatedTests.length) {
      const highestUCATemp = this.getHighestTemperatureTest(finishedValidatedTests);

      const sameTempResults = finishedValidatedTests.every(x => Number(this.getField(x, UCAField.EndTemperature)) === Number(this.getField(highestUCATemp, UCAField.EndTemperature)));

      // if selected tests values have the same temperature it must return nothing, otherwise return the highest temperature
      return finishedValidatedTests.length > 1 && sameTempResults ? null : highestUCATemp;
    }

    return this.getHighestTemperatureTest(this.tests);
  }

  constructor() {
    super(TestTableName.UCACompStrength);
  }

  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }

  private getHighestTemperatureTest = (tests: PumpScheduleStageTest[]): PumpScheduleStageTest => tests.reduce((prev, current) => Number(this.getField(prev, UCAField.EndTemperature)) > Number(this.getField(current, UCAField.EndTemperature)) ? prev : current);
}
