import { Component,ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CpStatuses } from '../../../control-point/models';
import { CommonMessageText, ControlPointType, JobComplienceStatus } from '../../constant';
import { Job } from 'libs/models';
import { getCPComplienceStatus } from 'libs/helpers/job-helper';
import { JobForm } from '../../models/job-form';

@Component({
  selector: 'control-point-statuses',
  templateUrl: './control-point-statuses.component.html',
  styleUrls: ['./control-point-statuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

// eslint-disable-next-line
export class ControlPointStatuses implements OnInit {
    @Input() jobForm: JobForm;
    public toolTipDueSoon = CommonMessageText.TOOLTIPS.JOB_DUE_SOON;
    public toolTipCompliance = CommonMessageText.TOOLTIPS.JOB_COMPLIANCE;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
      if (this.jobForm) {
        this.jobForm.onChanges.subscribe(() => {
          this.cd.markForCheck();
        });
      }
    }

    public get job(): Job {
      return this.jobForm.job;
    }
    public get cpStatuses(): CpStatuses {
      return this.job?.cpStatuses;
    }
    public get isDirectSales(): boolean {
      return this.job?.ceDirectSales ?? false;
    }
    public isDueSoon(cpType: ControlPointType): boolean {
      if (this.job == null) return false;
      return getCPComplienceStatus(this.job, cpType) === JobComplienceStatus.DueSoon;
    }
    public isOutOfCompliance(cpType: ControlPointType): boolean {
      if (this.job == null) return false;
      return getCPComplienceStatus(this.job, cpType) === JobComplienceStatus.OutOfComplience;
    }
}
