import { Directive, ElementRef, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { UnitType } from 'libs/constants';
import { Subscription } from 'rxjs';
import { UnitConversionService } from './unit-conversion.service';
import { formatNumber, getDisplayValue } from './unit-helper';

@Directive({
  selector: '[unitType]'
})
export class UnitTypeDirective implements OnInit, OnChanges, OnDestroy {
  @Input() unitType: UnitType;
  @Input() useAlias?: boolean;
  @Input() apiValue?: number;
  @Input() formatNum?: number = 2;
  @Input() sackWeight: number;
  @Input() shouldShowUoM?: boolean = true;
  @Input() shouldShowFormatNum?: boolean = false;
  subscription: Subscription;

  constructor(
    private unitConversionService: UnitConversionService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.updateUnit();
    this.subscription = this.unitConversionService.onSaveUnit$.subscribe(() => {
      this.updateUnit();
    });
  }

  ngOnChanges() {
    this.updateUnit();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateUnit(): void {
    const ele = this.el.nativeElement as HTMLElement;
    ele.innerText = this.apiValue == null ? this.getUnitName() : this.getUserUnitValue();
  }

  private getUnitName() {
    if (!this.shouldShowUoM || this.unitType == null) return '';
    return this.unitConversionService.getUnitName(this.unitType, this.useAlias);
  }

  private getUserUnitValue() {
    let value = formatNumber(getDisplayValue(this.apiValue, this.unitConversionService.getApiUnitMeasure(this.unitType), this.unitConversionService.getCurrentUnitMeasure(this.unitType), this.sackWeight), this.formatNum);
    if (this.shouldShowFormatNum && value != null) value = Number(value).toFixed(this.formatNum);
    return value != null ? value + ' ' + this.getUnitName() : this.getUnitName();
  }
}
