import { DesignCriteriaOption, ObjectState } from '../../../apps/vida/src/modules/shared/constant';
import { DesignCriteriaUOM } from './design-criteria-uom';
import { UnitType } from 'libs/constants';

export class DesignCriteria {
  id: string;
  option: DesignCriteriaOption;
  targetMinimumPressure: number;
  resultPressure: number;
  minVolume: number;
  maxVolume: number;
  resultVolume: number;
  targetMaximumPercentage: number;
  resultPercentage: number;
  minTime: string;
  maxTime: string;
  resultTime: string;
  rpm: number;
  temp: number;
  minTemp: number;
  maxTemp: number;
  resultTemp: number;
  minDensity: number;
  maxDensity: number;
  resultDensity: number;
  minGelStrength: string;
  maxGelStrength: string;
  resultGelStrength: string;
  minOther: number;
  maxOther: number;
  resultOther: number;
  unitType: UnitType;
  reasonOfOther: string;
  fromCustomer: boolean;
  targetJustification: string;
  resultJustification: string;
  order: number;
  pumpStageId: string;
  pumpStageOrder: number;
  state: ObjectState;
  origin: DesignCriteriaUOM;
  constructor() {
    this.id = '';
    this.option = null;
    this.targetMinimumPressure = null;
    this.resultPressure = null;
    this.minVolume = null;
    this.maxVolume = null;
    this.resultVolume = null;
    this.targetMaximumPercentage = null;
    this.resultPercentage = null;
    this.minTime = null;
    this.maxTime = null;
    this.resultTime = null;
    this.rpm = null;
    this.temp = null;
    this.minTemp = null;
    this.maxTemp = null;
    this.resultTemp = null;
    this.minDensity = null;
    this.maxDensity = null;
    this.resultDensity = null;
    this.resultGelStrength = null;
    this.minOther = null;
    this.maxOther = null;
    this.resultOther = null;
    this.unitType = null;
    this.reasonOfOther = '';
    this.fromCustomer = false;
    this.targetJustification = '';
    this.resultJustification = '';
    this.order = null;
    this.pumpStageId = null;
    this.pumpStageOrder = null;
    this.state = ObjectState.Created;
    this.origin = new DesignCriteriaUOM();
  }
}
