import { Component, Input } from '@angular/core';
import { JobForm } from '../../models/job-form';

@Component({
  selector: 'job-list-status-icons',
  templateUrl: './job-list-status-icons.component.html',
  styleUrls: ['./job-list-status-icons.component.scss']
})
export class JobListStatusIconsComponent {

  @Input() jobForm: JobForm;

  constructor() { }


}
