import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Optional, Renderer2, Self } from '@angular/core';
import { ApplicationStateService } from 'libs/shared/services';
import { LockableElementStatus } from 'apps/vida/src/modules/shared/constant/lockable-status';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[lockableElement]'
})
export class LockableElementDirective implements OnInit {
  @Input() lockableElement: string;
  @Input() lockableElementDisable = false;
  private disableClassName = 'disable-inner-elements';
  private exceptionClassName = 'exception-disable-item';
  private exceptionEnableClassName = 'exception-enable-item';

  private nativeElements = ['input', 'select', 'textarea'];
  private primeNgElements = ['p-dropdown', 'p-inputMask', 'p-checkbox', 'p-radioButton'];
  private customElements = ['typeahead-datatable', 'input-unit'];
  private lockableElements = this.nativeElements.concat(this.primeNgElements, this.customElements);
  private lockableElementsString = this.lockableElements.join(',');

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public cdr: ChangeDetectorRef,
    private applicationStateService: ApplicationStateService,
    @Optional() @Self() public ngControl: NgControl
  ) { }

  ngOnInit(): void {
    this.handleInputBehaviorByStatusType(this.applicationStateService.lockableElement$.value);
    this.applicationStateService.lockableElement$.subscribe(status => {
      this.handleInputBehaviorByStatusType(status);
    });
  }

  ngOnChanges() {
    this.handleInputBehaviorByStatusType(this.applicationStateService.lockableElement$.value);
  }

  private handleInputBehaviorByStatusType(statusType) {
    if (this.lockableElementDisable || (statusType && LockableElementStatus[statusType] && LockableElementStatus[statusType].indexOf(this.lockableElement) > -1)) {
      this.disableElements();
    } else {
      this.enableElements();
    }
  }

  private disableElements() {
    if (this.ngControl && this.el.nativeElement.classList.contains(this.exceptionClassName)) {
      return;
    }

    if (this.ngControl) this.ngControl.control.disable({onlySelf: true, emitEvent: false});
    this.disableElement(this.el.nativeElement);
    this.el.nativeElement
      .querySelectorAll(this.lockableElementsString)
      .forEach((item) => {
        this.disableElement(item);
      });
    this.cdr.markForCheck();
  }

  private enableElements() {
    if (this.ngControl) this.ngControl.control.enable({onlySelf: true, emitEvent: false});
    this.enableElement(this.el.nativeElement);
    this.el.nativeElement
      .querySelectorAll(this.lockableElementsString)
      .forEach((item) => {
        this.enableElement(item);
      });
    this.cdr.markForCheck();
  }

  disableElement(element: any) {
    if (element.classList.contains(this.exceptionClassName)) {
      return;
    }
    element.classList.add(this.disableClassName);
    this.renderer.setProperty(element, 'disabled', 'true');
  }

  enableElement(element: any) {
    if (element.classList.contains(this.exceptionEnableClassName)) {
      return;
    }
    element.classList.remove(this.disableClassName);
    this.renderer.setProperty(element, 'disabled', '');
  }
}
