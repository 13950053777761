import { Component, ViewChild } from '@angular/core';
import { ConfirmationDialogModel } from 'libs/ui';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'confirm-dialog-container',
  templateUrl: './confirm-dialog.container.html',
  styleUrls: ['./confirm-dialog.container.scss']
})
// eslint-disable-next-line
export class ConfirmDialogContainer {
  @ViewChild(ConfirmDialog, { static: true }) confirmDialog: ConfirmDialog;
  isVisible: boolean = false;

  constructor(
    private confirmationService: ConfirmationService
  ) { }

  show(confirmation: ConfirmationDialogModel) {
    this.isVisible = true;

    const originAccept = confirmation.accept;
    confirmation.accept = () => { this.hide(); originAccept(); };

    const originReject = confirmation.reject;
    confirmation.reject = () => { this.hide(); originReject(); };

    this.confirmationService.confirm(confirmation);
  }

  hide() {
    this.isVisible = false;
  }

}
