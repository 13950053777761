import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, timer, Subject, from, of, forkJoin } from 'rxjs';
import { flatMap, mergeAll, distinct, filter, toArray, takeUntil, map } from 'rxjs/operators';

import { intersection } from 'libs/helpers/lodash-helper';
import { environment } from 'libs/environment';
import { ConfirmDialogService } from 'libs/ui';
import { DATE_TIME_CALL_STANDARD } from 'libs/constants';

import { JobsService, UserSettingService, ApplicationStateService, EventHubService, CollectionService, IfactService, SettingService, RoleService } from 'libs/shared/services';
import { IUserSettingModel } from '../../../application/models';
import { IAdditionalParams } from '../../../shared/models';
import { CommonMessageText } from '../../../shared/constant';
import { CollectionModel } from '../../../collection/components/models';
import { validationProjectedDate } from 'libs/helpers/job-helper';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { MessageService } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import { ChangeJobOwnerDialogComponent } from '../../../shared/components/change-job-owner-dialog/change-job-owner-dialog.component';
import { WellImportedModel } from '../../../well/models';
import { LazyLoadingService } from 'libs/shared/services/lazy-loading.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { DWXService } from '../../../well/services';
import {
  WellImportDialogComponent
} from 'apps/vida/src/modules/well/components/well-import-dialog/well-import-dialog.component';
import { JobForm } from '../../../shared/models/job-form';

@AutoUnsubscribe()
@Component({
  selector: 'dash-active-jobs',
  templateUrl: './active-jobs.component.html',
  styleUrls: ['./active-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActiveJobsComponent implements OnInit, OnDestroy {
  @ViewChild(ChangeJobOwnerDialogComponent) changeJobOwnerDialogComponent: ChangeJobOwnerDialogComponent;
  @ViewChild('box', { static: true }) boxSearch: any;
  readonly DATE_TIME_CALL_STANDARD: string = DATE_TIME_CALL_STANDARD;
  jobs: JobForm[];
  userSettings: IUserSettingModel;
  additionalParams: IAdditionalParams;
  firstPage: number = 0;
  rowsPerPage: number = 200;
  sortField: string = 'modifiedDate';
  sortOrder: number = -1;
  totalRecords: number = null;
  opsMessageText: string;
  wells: WellImportedModel[];

  collectionNameActiveJobs: any;
  collectionNameActiveJobsAdded: any;

  checked: boolean;
  filterByLocation: any;

  private opsMessageTimer$ = timer(0, environment.opsMessageReloadIntervalInMilisecond);
  private destroy$ = new Subject();
  private subscription = new Subscription();
  loadingSubscription: Subscription;

  event: any;
  wellSize: number;
  cols = [
    { field: 'jobCode', header: 'Job Number', width: '6.76%' },
    { field: 'riskLevel', header: 'Risk', width: '3%' },
    { field: 'customerName', header: 'Customer', width: '10.89%' },
    { field: 'wellName', header: 'Well Name', width: '10.16%' },
    { field: 'wellNumber', header: 'Well Number', width: '5%' },
    { field: 'rigName', header: 'Rig', width: '8.11%' },
    { field: 'jobName', header: 'Job Name', width: '15%' },
    { field: 'jobTypeName', header: 'Job Type', width: '8.11%' },
    { field: '', header: 'Control Point', width: '8%' },
    { field: 'jobStatusDescription', header: 'Job State', width: '5.76%' },
    { field: 'actualDate|projectedDate', header: 'Job Date', width: '10%' },
    { field: 'appianPushState', header: 'Sent to Bulk Plant', width: '6%' },
    { field: 'appianBBLoadStatus', header: 'Load Status', width: '6%' },
    { field: 'modifiedDate', header: 'Last Modified', width: '7.77%' }
  ];

  constructor(
    private applicationStateService: ApplicationStateService,
    private userSettingService: UserSettingService,
    private jobService: JobsService,
    private ifactService: IfactService,
    private eventHubService: EventHubService,
    private messageService: MessageService,
    private collectionService: CollectionService,
    private confirmDialogService: ConfirmDialogService,
    private settingService: SettingService,
    private ref: ChangeDetectorRef,
    private dwxService: DWXService,
    private lazyLoadingService: LazyLoadingService,
    private bsModalService: BsModalService,
    private roleService: RoleService
  ) {
    this.userSettings = this.userSettingService.userSettings;
  }

  ngOnInit() {
    this.applicationStateService.jobCreated$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.getActiveJobByCurrentLocation(this.additionalParams);
    });

    this.eventHubService.onGroupUpdated$.pipe(
      filter((action: string) => action === 'selectGroup'),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      // Reset pagination to first page
      if (this.checked) {
        this.boxSearch.nativeElement.value = '';
        this.firstPage = 0;
        this.additionalParams.pageNum = 0;
        this.onCheckLocationSetting();
      }

      this.getWellsImported();
    });

    this.opsMessageText = CommonMessageText.TOOLTIPS.OPS_MESSAGE;

    this.event = {
      first: this.firstPage
    };
    this.onCheckLocationSetting();

    this.getWellsImported();
  }

  ngOnDestroy(): void {
    if (this.jobs) {
      this.jobs.forEach(jf => jf.onDestroy());
    }
  }

  handleChange(event) {
    this.boxSearch.nativeElement.value = '';

    const additionalParams: IAdditionalParams = {
      sortField: this.sortField,
      sortOrder: this.sortOrder === 1 ? 'asc' : 'desc',
      pageSize: this.rowsPerPage,
      pageNum: 0,
    };

    if (event.checked) {
      this.getActiveJobByCurrentLocation(additionalParams);
    } else {
      this.getActiveJobByMemberOfGroups(additionalParams);
    }

    if (this.filterByLocation) {
      this.filterByLocation.isActiveJobByCurrentLocation = event.checked;
      this.settingService.saveFilterByLocationSettings(this.filterByLocation).subscribe(() => {
        this.firstPage = 0;
      });
    } else {
      this.firstPage = 0;
    }
  }

  getActiveJobByCurrentLocation(additionalParams: IAdditionalParams, keyword = '') {
    const { groupId } = this.userSettingService.selectedLocation;
    this.loadingSubscription =
      this.jobService
        .getActiveJobsByCurrentLocation(groupId, additionalParams, keyword)
        .pipe(
          map(response => validationProjectedDate(response))
        )
        .subscribe((response: any) => {
          this.ref.markForCheck();
          this.jobs = response.data.map(item => new JobForm({ ...item, hasNewMessage: false }, this.roleService));

          this.totalRecords = response.totalRecord;
          this.subscription.add(this.opsMessageTimer$.subscribe(() => this.onLoadMessage(this.jobs)));
        });

    this.ref.markForCheck();
  }

  getActiveJobByMemberOfGroups(additionalParams: IAdditionalParams, keyword = '') {
    this.loadingSubscription =
      this.jobService.GetActiveJobByMemberOfGroups(additionalParams, keyword)
        .pipe(
          map(response => validationProjectedDate(response))
        )
        .subscribe(response => {
          // mark for checking a new value assigned to.
          this.ref.markForCheck();
          this.jobs = response.data.map(item => new JobForm({ ...item, hasNewMessage: false }, this.roleService));

          this.totalRecords = response.totalRecord;

          this.subscription.add(this.opsMessageTimer$.subscribe(() => this.onLoadMessage(this.jobs)));
        });
    // mark for checking a loadingIndicator.
    this.ref.markForCheck();
  }

  onLoadMessage(jobs: JobForm[]) {
    from(jobs).pipe(
      flatMap((jobForm: JobForm) => of(jobForm.job.listRequestByJob)),
      mergeAll(),
      distinct(),
      filter(requestId => !!requestId),
      toArray(),
      flatMap((requestIds: number[]) => this.ifactService.checkOpsMessage(requestIds, true)),
      takeUntil(this.destroy$)
    ).subscribe((requestsHaveMessage: number[]) => {
      this.ref.markForCheck();
      this.jobs.forEach(e => {
        e.job.hasNewMessage = intersection(e.job.listRequestByJob, requestsHaveMessage).length > 0;
      });
    });
  }

  // Event for Lazy load of PrimeNG Datatable

  loadMoreJobs(event: LazyLoadEvent) {
    const { sortField, sortOrder, first } = event;
    this.additionalParams = {
      sortField,
      sortOrder: sortOrder === 1 ? 'asc' : 'desc',
      pageSize: this.rowsPerPage,
      pageNum: first / this.rowsPerPage,
    };

    this.onCheckLocationSetting();
  }

  getPage(event) {
    const { first, sortField, sortOrder } = event;
    this.additionalParams = {
      sortField: sortField,
      sortOrder: sortOrder === 1 ? 'asc' : 'desc',
      pageSize: this.rowsPerPage,
      pageNum: first / this.rowsPerPage,
    };
    this.firstPage = first;
    this.event = { first };
    this.sortField = sortField;
    this.sortOrder = sortOrder;
    this.onCheckLocationSetting();
  }

  onCheckLocationSetting() {
    this.loadingSubscription = this.settingService.getFilterLocationSettings().subscribe((setting: any) => {
      this.filterByLocation = setting;
      this.checked = setting.isActiveJobByCurrentLocation;
      this.processActiveJobs();
    });
  }

  processActiveJobs() {
    const keyword = this.boxSearch.nativeElement.value != null ? this.boxSearch.nativeElement.value : '';
    if (this.filterByLocation && this.filterByLocation.isActiveJobByCurrentLocation) {
      this.getActiveJobByCurrentLocation(this.additionalParams, keyword);
    } else {
      this.getActiveJobByMemberOfGroups(this.additionalParams, keyword);
    }
  }

  saveToLibrary(id: string) {
    this.messageService.clear();
    const jobForm = this.jobs.find(x => x.job.id === id);
    this.jobService.saveToLibrary(id).subscribe(
      () => {
        jobForm.job.inLibrary = true;
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `<strong>${jobForm.job.jobName}</strong> ${CommonMessageText.LIBRARY_SAVED.SUCCESS}` });
      },
      (error) => {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `<strong>${jobForm.job.jobName}</strong> ${CommonMessageText.LIBRARY_SAVED.FAILED}<br/>Error: ${error}` });
      }
    );
  }

  removeJobFromSavedJobs(id: string) {
    this.messageService.clear();
    const jobForm = this.jobs.find(x => x.job.id === id);

    this.subscription.add(
      this.jobService.removeJobFromSavedJobs(id).subscribe(
        () => {
          jobForm.job.inLibrary = false;
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `<strong>${jobForm.job.jobName}</strong> ${CommonMessageText.LIBRARY_REMOVED.SUCCESS}` });
        },
        (error) => {
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `<strong>${jobForm.job.jobName}</strong> ${CommonMessageText.LIBRARY_REMOVED.FAILED}<br/>Error: ${error}` });
        }
      ));
  }

  showAllCollectionNameActiveJobs(jobId) {
    this.subscription.add(
      forkJoin(
        this.collectionService.getAllCollectionName(jobId),
        this.collectionService.getAllCollectionNameRemove(jobId)
      ).subscribe(([collectionAvailable, collectionAdded]) => {
        this.ref.markForCheck();
        this.collectionNameActiveJobs = collectionAvailable;
        this.collectionNameActiveJobsAdded = collectionAdded;
      }));
  }

  addToCollectionActiveJobs(collectionId, jobId, collectionName, jobName) {
    const collectionModel: CollectionModel = {
      id: collectionId,
      jobId: jobId
    };
    this.subscription.add(
      this.collectionService.addToCollectionName(collectionModel).subscribe(() => {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `<strong>${jobName}</strong> has been added to <strong>${collectionName}</strong> collection successfully.` });
      })
    );
  }

  onSearch(keyword: any) {
    if (this.checked) {
      this.getActiveJobByCurrentLocation(this.additionalParams, keyword);
    } else {
      this.getActiveJobByMemberOfGroups(this.additionalParams, keyword);
    }
  }

  removeFromCollectionActiveJobs(collectionId, jobId, collectionName, jobName) {
    const collectionModel: CollectionModel = {
      id: collectionId,
      jobId: jobId
    };

    this.confirmDialogService.show({
      message: `Are you sure to remove collection: <strong>${collectionName}</strong>?`,
      header: 'Message',
      accept: () => {
        this.subscription.add(
          this.collectionService.removeFromCollectionName(collectionModel).subscribe(() => {
            this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `<strong>${jobName}</strong> has been removed from <strong>${collectionName}</strong> collection successfully.` });
          }));
      },
      reject: () => {

      }
    });
  }

  changeJobOwner(jobId: string, jobOwner: string) {
    this.changeJobOwnerDialogComponent.open(jobId, jobOwner);
    this.changeJobOwnerDialogComponent.jobOwnerUpdate.subscribe(() => {
      this.onCheckLocationSetting();
    });
  }

  getWellsImported() {
    const { groupName } = this.userSettingService.selectedLocation;

    this.dwxService.getWellsImported(groupName).subscribe(data => {
      this.wells = data;
      this.wellSize = this.wells.length;
      this.ref.markForCheck();
    });

    this.applicationStateService.dwxWellList$.subscribe(data => {
      if(data) {
        this.wells = data;
        this.wellSize = this.wells.length;
        this.ref.markForCheck();
      }
    });
  }

  showDialogWellImported() {
    this.wellSize = this.wells.length;
    this.ref.markForCheck();
    const initState = {
      wells: this.wells,
      dialogType: 'activejob' as any
    }

    this.lazyLoadingService.loadWellImportDialog().then(m => {
      const modalRef = this.bsModalService.show<WellImportDialogComponent>(m.components.WellImportDialogComponent, {
        class: 'custom-model-screen',
        ignoreBackdropClick: true,
        initialState: initState,
        keyboard: false,
        id: MODAL_ID.WELL_NOT_IMPORT
      });
      modalRef.content.onClose.subscribe((wellSize) => {
        this.wellSize = wellSize;
        this.ref.markForCheck();
      });
    });
  }

  removedWellsImported(wellId: string, wellName: string) {
    this.confirmDialogService.show({
      message: `Are you sure to remove collection: <strong>${wellName}</strong>?`,
      header: 'Message',
      accept: () => {
        this.subscription.add(
          this.dwxService.deleteWell(wellId).subscribe(() => {
            this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `<strong>${wellName}</strong> has been removed from <strong>${wellName}</strong> collection successfully.` });
          }));
      },
      reject: () => {

      }
    });
  }

  openEditWell(event, wellId:string) {
    const editWellParameters = {
      wellId: wellId
    };
    this.applicationStateService.openWellEdit$.next(editWellParameters);
  }
}
