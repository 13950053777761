import { Component, OnInit, Input, Injector, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Job } from 'libs/models/entities/job';
import { environment } from 'libs/environment';
import { FormControlContainer } from 'libs/ui/form-control-container';
import { UnitType } from 'libs/constants/unit-of-measure.constants';
import { VersaFlexOptions } from 'libs/constants';
import { PumpScheduleService } from '../../services';

@Component({
  selector: 'pump-schedule-liner-job',
  templateUrl: './pump-schedule-liner-job.component.html',
  styleUrls: ['./pump-schedule-liner-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PumpScheduleLinerJobComponent extends FormControlContainer implements OnInit {

  public readonly UnitType = UnitType;
  public LinerCirculationMethods;
  public readonly VersaFlexOptions = VersaFlexOptions;

  @Input()
  public pumpScheduleForm: UntypedFormGroup;

  @Input()
  public cpState: number = 0;

  @Input()
  public job: Job;

  constructor(
    protected inj: Injector,
    private pumpSchedule: PumpScheduleService,
    private cd: ChangeDetectorRef
  ) {
    super(inj);
  }

  get linerJobGuidelineLink(): string {
    return environment.linerJobGuidelineLink;
  }

  get linerJobVersaFlexGuidelineLink(): string {
    return environment.linerJobVersaFlexGuidelineLink;
  }

  ngOnInit() {
    this.pumpSchedule.getLinerCirculation().subscribe(res => {
      this.LinerCirculationMethods = res.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })
      this.cd.detectChanges();
    })
  }

}
