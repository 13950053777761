import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApplicationStateService} from '../services';
import {MessageService} from 'primeng/api';
import {EnvanaDownRedMessage} from '../../../apps/vida/src/modules/shared/constant/envana';

@Injectable()
export class EnvanaDownInterceptor implements HttpInterceptor {

  private static redMessageUrls = ['/carbonFootPrint/compute/', '/pumpSchedule/carbonfooprint-per-barrel/'];

  constructor(private appStateService: ApplicationStateService, private messageService: MessageService) {
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (EnvanaDownInterceptor.redMessageUrls.some(url => request.url.includes(url))
    ) {

      request = request.clone();

      return next.handle(request).pipe(
        tap(
          () => {
            this.appStateService.isEnvanaDown$.next(false);
          },
          (error: HttpErrorResponse) => {
            if (error.status === 503) {
              this.messageService.add(new EnvanaDownRedMessage());
              this.appStateService.isEnvanaDown$.next(true);
            }
          }
        )
      );
    }

    return next.handle(request);
  }
}
