import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'libs/environment';
import { ApplicationStateService, CacheService } from 'libs/shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class IFactInterceptor implements HttpInterceptor {

  readonly EXPIRY = 10000;

  constructor(
    public cacheService: CacheService,
    public appStateService: ApplicationStateService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.iFactsUrl)) {

      req = req.clone({
        withCredentials: true
      });

      return next.handle(req).pipe(
        tap(
          () => {
            this.appStateService.notifyIFactDown$.next(false);
          },
          () => {
            this.appStateService.notifyIFactDown$.next(true);
          }
        )
      );
    }

    return next.handle(req);
  }

}
