import { Component, forwardRef, Injector, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnitBaseComponent } from './unit-base.component';
import { getDisplayValue, formatNumber } from './unit-helper';
import { AutoComplete } from 'primeng/autocomplete';
import { getPattern } from '../validations/pkeyfilter-reg-exp';

@Component({
  selector: 'autocomplete-unit',
  template: `
    <p-autoComplete [suggestions]="_suggestions" [formControl]="_formControl" [appendTo]="_appendTo" [baseZIndex]="_baseZIndex"
      (onBlur)="_onBlur($event)" (onClear)="onClear.emit($event)"
      (completeMethod)="_completeMethod($event)" (onKeyUp)="_onKeyUp($event.target.value)" #autocomplete
      (onFocus)="!autocomplete.value && autocomplete.handleDropdownClick()"
      (onSelect)="_onSelect($event)" [pKeyFilter]="pKeyFilter" [pattern]="pattern">
    </p-autoComplete>
  `,
  styleUrls: [`autocomplete-unit.component.scss`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteUnitComponent),
      multi: true
    }
  ]
})
export class AutoCompleteUnitComponent extends UnitBaseComponent implements OnChanges, AfterViewInit {

  _suggestions: any[];
  _appendTo= "";
  _baseZIndex= 0;

  @Input() appendTo: string;
  @Input() baseZIndex: number;
  @Input() suggestions: any[];
  @Input() decimalPlaces: number;
  @Input() pKeyFilter: string = 'num';
  @Input() pattern: RegExp = getPattern(this.pKeyFilter);
  // eslint-disable-next-line
  @Output() onClear = new EventEmitter<any>();
  @Output() completeMethod = new EventEmitter<any>();
  @ViewChild(AutoComplete, { static: true }) autoComplete: AutoComplete;

  constructor(protected inj: Injector) {
    super(inj);

    this.unitConversionService.onSaveUnit$.subscribe(() => {
      this._updateSuggestions();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { suggestions, appendTo, baseZIndex } = changes;

    if (suggestions) {
      this._updateSuggestions();
    }

    if (appendTo && appendTo.currentValue) {
      this._appendTo = appendTo.currentValue;
    }

    if (baseZIndex && baseZIndex.currentValue) {
      this._baseZIndex = baseZIndex.currentValue;
    }
  }

  ngAfterViewInit() {
    this.autoComplete.inputEL.nativeElement.addEventListener('paste', (e) => {
      const pastedValue = e.clipboardData && e.clipboardData.getData('text/plain') || (<any>window).clipboardData.getData('text');
      if (isNaN(parseFloat(pastedValue)) || !isFinite(pastedValue)) {
        e.preventDefault();
      }
    });
    this.autoComplete.inputEL.nativeElement.addEventListener('input', (e) => {
      let enteredValue = e.target.value;
      if (enteredValue.length > 25) {
        enteredValue = enteredValue.slice(0, 25);
        e.target.value = enteredValue;
      }
      else if (enteredValue.length > 13) {
        if(!enteredValue.toString().includes('.')){
          const integerPart = enteredValue.slice(0, 13);
          const decimalPart = enteredValue.slice(13);
          enteredValue = integerPart + '.' + decimalPart;
          e.target.value = enteredValue;
        }else{
          const [integerPart, decimalPart] = enteredValue.split('.');
          if (integerPart.length >= 13) {
            const newIntegerPart = integerPart.slice(0, 13);
            enteredValue = newIntegerPart + '.' + decimalPart;
            e.target.value = enteredValue;
        }
        }
      }
    this._onKeyUp(e.target.value)});
  }

  handleDropdownClick(e) {
    if (this.autoComplete)
      this.autoComplete.handleDropdownClick(e);
  }

  _onBlur(e) {
    this.onBlur.emit(e);
  }

  _completeMethod(e) {
    this._suggestions = this.suggestions.filter(res => {
      if (res) {
        return res.toString().toLowerCase().indexOf(e.query.toLowerCase()) !== -1;
      }
    });
    this.completeMethod.emit(e);
  }

  _updateSuggestions() {
    this._suggestions = this.suggestions.map(
      value => formatNumber(
        getDisplayValue(value, this.unitConversionService.getApiUnitMeasure(this.unit), this.unitConversionService.getCurrentUnitMeasure(this.unit), this.sackWeight),
        this.decimalPlaces
      )
    );
  }

  _onSelect(value) {
    super._onKeyUp(value);
  }
}
