import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { VidaFileType, CommonMessageText } from '../../../shared/constant';
import { DynamicSidebarRef } from 'libs/ui';
import { MessageService } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpCommanderService } from 'libs/shared/services';
import { PostJobReport } from '../../../control-point/models';
import * as moment from 'moment-mini';
import { JobUploaderComponent } from '../../../shared/components';
import { environment } from 'libs/environment';

@Component({
  selector: 'post-job-report-sidebar',
  templateUrl: './post-job-report-sidebar.component.html',
  styleUrls: ['./post-job-report-sidebar.component.scss']
})

export class PostJobReportSideBarComponent implements OnInit {
  data: any;
  vidaFileType: VidaFileType = VidaFileType.PostJobReport;
  errorMessage: string;
  disableSaveButton = true;
  destinationFolderPath: string;
  loadingSubscription: Subscription;
  jobId: string;
  postJobReportFb: UntypedFormGroup;
  statusNoPostJobReport: boolean = true;
  postJobReportFiles = [];

  postJobReport: PostJobReport = null;
  @ViewChild('uploader', { static: true }) jobUploader: JobUploaderComponent;

  constructor(
    private dynamicSidebarRef: DynamicSidebarRef<PostJobReportSideBarComponent>,
    private HCService: HttpCommanderService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.destinationFolderPath = this.data.path;
    this.statusNoPostJobReport = true;
    this.jobId = this.data.jobId;
    this.disableSaveButton = !this.data.canEdit;
    this.getFiles();
  }

  uploadInprogress(event) {
    if (event) {
      this.disableSaveButton = true;
    }
  }

  uploadCancel() {
    this.dynamicSidebarRef.close(null);
  }

  uploadSuccess(event) {
    if (event.isSuccess) {
      this.getFiles();
      this.disableSaveButton = false;
    }
  }

  getFiles() {
    this.loadingSubscription = this.HCService.getFilesByVirtualRelativeFolderPath(this.destinationFolderPath, VidaFileType.PostJobReport)
      .subscribe((data: any) => {
        this.handleDataFromVIDA(data);
      });
  }

  handleDataFromVIDA(data) {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result) {
        this.postJobReportFiles = result.map(postJobReport => {
          const temp = Object.assign(new PostJobReport, postJobReport);
          if (temp.approvalDate) {
            temp.approvalDate = moment.utc(temp.approvalDate).local();
          }
          return temp;
        });
        if (!this.postJobReportFiles) {
          this.disableSaveButton = true;
        }
        this.postJobReport = this.postJobReportFiles.find((postJobReport: any) => postJobReport.isLastUsed);
        this.postJobReport ? this.statusNoPostJobReport = false : this.statusNoPostJobReport = true;
        this.parseObjectToForm(this.postJobReportFiles);
      }
    }
  }

  parseObjectToForm(postJobReport: PostJobReport[]) {
    const postJobReportFg = postJobReport.map(jobReport => {
      return this.createFormGroup(jobReport);
    });

    this.initForm(postJobReportFg);
  }

  initForm(postJobReportFgArr) {
    this.postJobReportFb = this.fb.group({
      postJobReport: postJobReportFgArr && postJobReportFgArr.length ? this.fb.array(postJobReportFgArr) : this.fb.array([])
    });
  }

  createFormGroup(postJobReport: PostJobReport): UntypedFormGroup {
    return this.fb.group({
      virtualRelativeFilePath: [
        {
          value: postJobReport.virtualRelativeFilePath,
          disabled: false
        }
      ],
      fileName: [
        {
          value: postJobReport.getFileName(),
          disabled: false
        }
      ],
      createDateFormat: [
        {
          value: postJobReport.getCreatedDate(),
          disabled: false
        }
      ],
      filePathDownload: [
        {
          value: postJobReport.filePathDownload,
          disabled: false
        }
      ],
      isLastUsed: [
        {
          value: postJobReport.isLastUsed,
          disabled: false
        }
      ],
      uploader: [
        {
          value: postJobReport.uploader,
          disabled: false
        }
      ],
      createdDate: [
        {
          value: postJobReport.createdDate ? new Date(postJobReport.createdDate) : null,
          disabled: false
        }
      ],
      description: [
        {
          value: postJobReport.description,
          disabled: false
        }
      ],
      sentToCustomerDate: [
        {
          value: postJobReport.sentToCustomerDate,
          disabled: false
        }
      ],
      comment: [
        {
          value: postJobReport.comment,
          disabled: false
        }
      ],

    });
  }


  savePostJobReport() {
    this.postJobReportFb.markAsTouched();
    const listPostJobReport = this.postJobReportFb.value.postJobReport.map(jobReport => {
      const jobTemp = Object.assign(new PostJobReport, jobReport);
      return jobTemp;
    });
    // save Post-Job Report
    this.loadingSubscription = this.HCService.savePostJobReport(listPostJobReport, this.jobId).subscribe((data: any) => {
      this.handleDateFromHC(data);
      if (data.statusCode === 200) {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.POST_JOB_REPORT.SUCCESS });
        this.dynamicSidebarRef.close(this.postJobReportFiles);
      } else {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: CommonMessageText.POST_JOB_REPORT.FAILED });
        this.hideFileSidebar();
      }
    });
  }

  disableOtherRadioButton(event) {
    if (!event) {
      this.statusNoPostJobReport = false;
    } else {
      this.statusNoPostJobReport = true;
    }
    const listPostJobReport = this.postJobReportFb.value.postJobReport.map(jobReport => {
      const postJobReportTemp = Object.assign(new PostJobReport, jobReport);
      if (postJobReportTemp.virtualRelativeFilePath !== (event ? event.controls.virtualRelativeFilePath.value : null)) {
        postJobReportTemp.isLastUsed = false;
      } else {
        postJobReportTemp.isLastUsed = true;
      }
      return postJobReportTemp;
    });

    const postJobReport = listPostJobReport.find((callsh: any) => callsh.isLastUsed);
    postJobReport ? this.statusNoPostJobReport = false : this.statusNoPostJobReport = true;
    this.parseObjectToForm(listPostJobReport);
    this.disableSaveButton = false;
  }

  handleDateFromHC(data) {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result.find(x => x.isLastUsed)) {
        this.statusNoPostJobReport = false;
      }
      if (result) {
        this.postJobReportFiles = result.map(postJobReport => {
          const temp = Object.assign(new PostJobReport, postJobReport);
          return temp;
        });
        this.postJobReport = this.postJobReportFiles.find((job: any) => job.isLastUsed);
        this.parseObjectToForm(this.postJobReportFiles);
      }
    }
  }

  cancelPostJobReport() {
    this.jobUploader.cancelUpload();
    this.hideFileSidebar();
  }

  hideFileSidebar() {
    this.dynamicSidebarRef.close(null);
  }

}


