<div class="sidebar-block">
  <div class="sidebar-header">
    <h1 class="title">Post-Job Report</h1>
  </div>
  <div class="sidebar-content" [loadingIndicator]="loadingSubscription">

    <div class="file-upload">
      <job-uploader #uploader [listFiles]="postJobReportFiles" [jobId]="jobId"
        (uploadCancelEmitter)="uploadCancel()" (uploadSuccessEmitter)="uploadSuccess($event)"
        (uploadInprogressEmitter)="uploadInprogress($event)" [destinationFolderPath]="destinationFolderPath"
        [header]="'File and Description'" [title]="'A Post-Job Report document must be uploaded.'"
        [drapDropDescription]="'or Drag and Drop Post-Job Report File Here'" [isEnableDescriptionInput]="true"
        [vidaFileType]="vidaFileType" [badgeNumber]=true [displayMode]="'Vertical'">
      </job-uploader>
    </div>
    <div class="select-source">
      <label><span class="badge-number">2</span> Select Source</label>
      <div class="uploader-subtitle">Select a Post-Job Report document as source.</div>
      <br>
      <div class="table-select-source">
        <table>
          <thead>
            <tr>
              <th style="width:10%">Select</th>
              <th class="p-l-10" style="width:35%">File Name</th>
              <th style="width:55%"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="form-group radio-button" style="text-align: center;">
                  <p-radioButton [value]="true" [(ngModel)]="statusNoPostJobReport" name="noPostJobReport"
                    (click)="disableOtherRadioButton(null)">
                  </p-radioButton>
                </div>
              </td>
              <td class="p-l-10">No Post-Job Report</td>
              <!-- <td></td>
              <td></td> -->
            </tr>
            <tr *ngFor="let report of (postJobReportFb ? postJobReportFb['controls'].postJobReport['controls'] : [])">
              <td>
                <form [formGroup]="report">
                  <div class="form-group radio-button" style="text-align: center;">
                    <p-radioButton formControlName="isLastUsed" [value]="true" (click)="disableOtherRadioButton(report)">
                    </p-radioButton>
                  </div>
                </form>
              </td>
              <td class="p-l-10">{{report.controls.fileName.value}}</td>
              <td>
                <form [formGroup]="report">
                  <div class="col-sm-5 p-l-0 p-r-0">
                    Date/Time<br>
                    <datetime-picker format="dd-MMM-yyyy" formControlName="sentToCustomerDate" styleClass="float-left-calendar">
                    </datetime-picker>
                  </div>
                  <div class="col-sm-7 p-r-0">
                    Comment<br>
                    <textarea class="post-job-textarea" name="1" id="1" formControlName="comment"></textarea>
                  </div>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <div class="sidebar-footer">
    <a class="btn lnk-cancel" (click)="cancelPostJobReport()">Cancel</a>
    <button type="button" class="btn primary-btn" (click)="savePostJobReport()"
      [disabled]="disableSaveButton">SAVE</button>
  </div>
</div>
