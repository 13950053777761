import { Component, Input, Injector, AfterContentInit, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorMessageModel } from './error-message.model';
import { FormValueAccessor } from '../form-value-accessor';

@Component({
  selector: 'error-display',
  template: '<div class="text-error-message">{{_error}}</div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ErrorDisplayComponent),
    }
  ]
})
export class ErrorDisplayComponent extends FormValueAccessor implements AfterContentInit {
  _error: string = '';
  @Input() errorMsg: ErrorMessageModel[] = [];

  constructor(
    protected inj: Injector,
    private cd: ChangeDetectorRef
  ) {
    super(inj);
  }

  ngAfterContentInit() {
    this.injectNgControl();
    this._updateTextError();
    this.control.statusChanges.subscribe(() => {
      this._updateTextError();
    });
  }

  writeValue(obj: any): void {
    super.writeValue(obj);
    // this._updateTextError();
  }

  _updateTextError() {
    const error = this._getTextError(this.control, this.errorMsg);
    if (this._error !== error) {
      this._error = error;
      this.cd.markForCheck();
    }
  }

  private _getTextError(control: AbstractControl, errorMsg: ErrorMessageModel[]): string {
    let error = this._getTextErrorFromList(control, errorMsg);
    if (!error) {
      error = this._getTextErrorFromControl(control);
    }
    if (typeof error === 'boolean') error = '';
    return error;
  }

  private _getTextErrorFromList(control: AbstractControl, errorMsg: ErrorMessageModel[]): string {
    const error = control && control.errors && errorMsg ? errorMsg.find(e => control.errors[e.errorType]) : null;
    return error ? error.errorMessage : '';
  }

  private _getTextErrorFromControl(control: AbstractControl) {
    if (!control || !control.errors)
      return null;

    const errorTypes = Object.keys(control.errors);
    return errorTypes && errorTypes.length ? control.errors[errorTypes[0]] : null;
  }
}
