import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { VersaFlexOptions } from 'libs/constants';
import { UnitType } from 'libs/constants/unit-of-measure.constants';
import { PumpScheduleService } from '../../../pump-schedule/services';
import { PumpScheduleStateManager } from '../../state/schedule-state-manager';
import { DocumentUrlKeyword } from '../../../shared/constant/document-url-keyword';
import { ControlPointService2 } from 'libs/shared';

@Component({
    selector: 'liner',
    templateUrl: './liner.component.html',
    styleUrls: ['./liner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinerComponent implements OnInit {

    public readonly UnitType = UnitType;

    public LinerCirculationMethods;

    public readonly VersaFlexOptions = VersaFlexOptions;

    @Input()
    public scheduleState: PumpScheduleStateManager;

    @Input()
    public isLinerParamsRequired: boolean;

    public get pumpScheduleForm(): UntypedFormGroup {

        return this.scheduleState.form;
    }

    documentKeyword = DocumentUrlKeyword;

    constructor(
        private pumpSchedule: PumpScheduleService,
        private controlPointService: ControlPointService2,
        private cd: ChangeDetectorRef
    ){}

    ngOnInit(){
        this.pumpSchedule.getLinerCirculation().subscribe(res => {
            this.LinerCirculationMethods = res.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            this.cd.detectChanges();
        });
    }

    openLinerDocment(event :Event, flag:number){
        event.preventDefault();
        this.controlPointService.getDesignDocumetUrl(flag).subscribe(result =>{
            window.open(result.url, ' _blank');
        });
    }
}
