import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';

const CH_NUMBER_TOOLTIP_TO_SHOW: number = 0

@Directive({
  selector: '[customTooltip]'
})
export class CustomTooltipDirective implements OnChanges {
 @Input() valueToParse;
  constructor( private el: ElementRef, private renderer: Renderer2 ) {}
  parent = this.el.nativeElement.parentElement;
  ngOnChanges(){
    this.manageToolTip()
  }
  manageToolTip(){
    if (this.renderer && this.parent && this.el) {
      if (this.valueToParse && this.valueToParse.length !== CH_NUMBER_TOOLTIP_TO_SHOW) {
        this.renderer.setAttribute(this.parent, 'title', this.valueToParse);
      }
      else {
        this.renderer.removeAttribute(this.parent, 'title');
      }
    }
  }
}

