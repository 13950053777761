
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeadVolumeFluidType, FLUID_TYPE_SCHEDULE, SlurrySource, UnitType } from 'libs/constants';
import { FluidModel, IPumpScheduleFluidType, ISlurryType, MudParameterModel, PumpSchedule, PumpScheduleEventModel, PumpScheduleStageModel } from 'libs/models';
import { PumpScheduleStageCOGSModel, PumpScheduleStageMaterialCOGSModel } from 'libs/models/entities/pump-schedule-stage-cogs.model';
import { ApplicationStateService, FluidService } from 'libs/shared/services';
import { convertWithUnitMeasure, formatNumber, UnitConversionService } from 'libs/ui/unit-conversions';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, concat, from, merge, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeAll, shareReplay, switchMap, switchMapTo, tap, takeUntil, skip, startWith, pairwise, debounceTime } from 'rxjs/operators';
import { MissingDataMaterial } from '../../shared/models/no-cogs-material.model';
import { PumpScheduleFormManager } from '../form-manager';
import { ViewState } from '../view-state';
import { StageFluidCalculator as StageCalculator } from './calculators/stage-calculator';
import { EventStateManager } from './event-state-manager';
import { FluidStateManager } from './fluid-state-manager';
import { FluidMaterialStateManager } from './material-state-manager';
import { PumpScheduleStateManager } from './schedule-state-manager';
import { PumpScheduleStateFactory } from './state-factory';
import { UpdateStageTests } from 'apps/vida/src/modules/pump/components/stage-test-results/test-results-mapper';
import { round } from 'lodash';
import { PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { PushedBulkPlantInfo } from '../models/pushed-bulk-plant-info.model';

export interface IFieldValue {
  [key: string]: number;
  default?: number;
}

interface IMudParameterUpdate {
  mudParameterData: MudParameterModel;
  order: number;
  isPumpDefault: boolean;
}

export class StageStateManager {

  public static readonly DrillingMudTypeName: string = FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD;

  public static readonly DrillingSpacerTypeName: string = FLUID_TYPE_SCHEDULE.DRILLING_FLUID_SPACER;

  public static readonly TopPlugTypeName: string = FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT;

  public static readonly CementTypeName: string = FLUID_TYPE_SCHEDULE.CEMENT;

  private static readonly _stageColors: Map<number, string> = new Map<number, string>([
    [0, '#d1d1d1'],
    [1, '#57c5e8'],
    [2, '#5d3116'],
    [3, '#efc027'],
    [4, '#ec2d24'],
    [5, '#80a095'],
    [6, '#ea6925'],
    [7, '#06ad4d'],
    [8, '#b01f23'],
    [9, '#412f8e'],
    [10, '#7bc045'],
    [11, '#047b80'],
    [12, '#71421b'],
    [13, '#de5e47'],
    [14, '#a8a138'],
    [15, '#4a190d']
  ]);

  private static readonly _timeStringFields = [
    'thickeningTime',
    'placementTime',
    'specificShutdownTime',
    'minThickeningTime',
    'actualSafety'
  ];

  private static readonly _fluidFormFields = [
    'deadVolume',
    'loadoutVolume',
    'bulkCement',
    'waterRequirements',
    'stageWaterTotal'
  ];

  private _eventsStates: EventStateManager[] = [];

  private _fluidState: FluidStateManager = null;

  private readonly _eventsStatesSrc = new BehaviorSubject<EventStateManager[]>([]);

  public readonly _materialsStatesSrc = new BehaviorSubject<FluidMaterialStateManager[]>([]);

  private readonly _errorThickeningTimeSrc = new BehaviorSubject<boolean>(false);

  private readonly _warningThickeningTimeSrc = new BehaviorSubject<boolean>(false);

  private readonly _materialsStates$ = this._materialsStatesSrc.asObservable().pipe(shareReplay());

  private readonly _subscriptions = new Subscription();

  private _destroySubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _eventSubscriptions = new Subscription();

  private readonly _calc: StageCalculator;

  public readonly form: UntypedFormGroup;

  public readonly placementTimeForm: UntypedFormGroup;

  public isPrevStageTypeMud: boolean;

  public readonly eventsStates$ = this._eventsStatesSrc.asObservable().pipe(shareReplay());

  public readonly availableFluids$: Observable<FluidModel[]> = this._scheduleState.fluids$;

  public readonly selectedFluid$: Observable<FluidModel> = this.availableFluids$
    .pipe(
      switchMap(availableFluids =>
        this.isPlug$
          .pipe(
            filter(itis => !itis),
            switchMapTo(
              merge(
                concat(
                  of(this._model.slurry),
                  this.form.controls.slurry.valueChanges
                ),
                this.form.controls.selectedFluidId.valueChanges
                  .pipe(
                    map(fluidId => {
                      return availableFluids.find(f => f.id === fluidId);
                    })
                  )
              )
            ),
            map(fluid => {
              const f = this._updateFluidModel(availableFluids, fluid);
              return f;
            })
          )
      ),
      shareReplay()
    );

  public readonly events$: Observable<PumpScheduleEventModel[]> = this.eventsStates$
    .pipe(
      map(eventsStates => {

        return eventsStates.map(s => s.model);
      }),
      shareReplay()
    );

  public readonly type$: Observable<IPumpScheduleFluidType> = this._availableStageTypes$
    .pipe(
      switchMap(types => {

        return concat(
          of(this._model.pumpScheduleFluidTypeId),
          this.form.controls.pumpScheduleFluidTypeId.valueChanges
        )
          .pipe(
            map(typeId => {
              return types.find(at => at.id === (typeId || null)) || null;
            }),
            tap(type => {

              this._setType(type);
            })
          );
      }),
      shareReplay()
    );

  public readonly isMud$: Observable<boolean> = this.type$.pipe(map(() => this._isMud), shareReplay());

  public readonly isDrillingMud$: Observable<boolean> = this.type$.pipe(map(() => this.isDrillingMud), shareReplay());

  public readonly isDrillingSpacer$: Observable<boolean> = this.type$.pipe(map(() => this.isDrillingSpacer), shareReplay());

  public readonly isPlug$: Observable<boolean> = this.type$.pipe(map(() => this.isPlug), shareReplay());

  public readonly isFluid$: Observable<boolean> = this.type$.pipe(map(() => this._isFluid), shareReplay());

  public readonly isIFactsFluid$: Observable<boolean> = this.selectedFluid$
    .pipe(
      map(fluid => this.isIFactsFluid(fluid)),
      shareReplay()
    );

  private _testTables$ = new Subject<PumpScheduleStageTestTable[]>();
  public readonly testTables$: Observable<PumpScheduleStageTestTable[]> = merge(
    this.selectedFluid$
      .pipe(
        startWith(new FluidModel()),
        pairwise(),
        map(([prevFluid, newFluid]) => {
          if (prevFluid.slurryId && !newFluid.slurryId) {
            this._model.pumpScheduleStageTestTables = [];
          } else if (prevFluid.slurryId && newFluid.slurryId && prevFluid.slurryId !== newFluid.slurryId) {
            this._model.pumpScheduleStageTestTables = [];
          }

          UpdateStageTests(this._model, newFluid);

          return this._model.pumpScheduleStageTestTables;
        })
      ),
    this._testTables$.asObservable(),
  );

  public updateTestTables(testTables: PumpScheduleStageTestTable[]) {
    this._testTables$.next(testTables);
    this._scheduleState.isTestTableChanged$.next(true);
  }

  private readonly _isFluidWithDryMaterials$: Observable<boolean> = this._materialsStates$
    .pipe(
      map(materialsStates => {
        return !this._isMud && materialsStates.some(m => m.isDry);
      })
    );

  public readonly number$: Observable<number> = this._scheduleState.stageNumbers$
    .pipe(
      map(numbers => {

        const n = numbers[this.order];
        this._model.number = n || null;
        return this._model.number;
      }),
      shareReplay()
    );

  public readonly color$: Observable<string> = this.number$
    .pipe(
      map(n => {
        return StageStateManager._stageColors.get(n % StageStateManager._stageColors.size);
      }),
      shareReplay()
    );

  public readonly mudParameterDisplay$: Observable<string> =
    merge(
      this.isMud$.pipe(
        tap(() => {

          const cpMud = this._applicationStateService.syncMudListCP?.slice().reverse()
            .find(item => item.pumpIndex === this._stateFactory.currentIndex
              && item.order === this._model.order);
          if (!cpMud) return;

          const jobEditMud = this._applicationStateService.syncMudListJobEdit.slice().reverse()
            .find(item => item.pumpIndex === this._stateFactory.currentIndex
              && item.order === this._model.order);

          if (this._applicationStateService.jobEditOpened$.value) {
            if (jobEditMud || cpMud) {
              this._updateMudParameter((jobEditMud || cpMud).mudParameterData);
            }
          } else {
            if (cpMud) {
              this._updateMudParameter(cpMud.mudParameterData);
            }
          }
        })
      ),
      this._applicationStateService.updateMudData$
        .pipe(
          filter((value: IMudParameterUpdate) => value.order === this._model.order),
          tap((value: IMudParameterUpdate) => {

            if (this._applicationStateService.jobEditOpened$.value) {
              if ((value.isPumpDefault && this._stateFactory.currentIndex === 0)
                || (!value.isPumpDefault && this._stateFactory.currentIndex !== 0)) {
                this._applicationStateService.syncMudListJobEdit.push({ ...value, pumpIndex: this._stateFactory.currentIndex });
              }
            } else {
              if ((value.isPumpDefault && this._stateFactory.currentIndex === 0)
                || (!value.isPumpDefault && this._stateFactory.currentIndex !== 0)) {
                this._applicationStateService.syncMudListCP.push({ ...value, pumpIndex: this._stateFactory.currentIndex });
              }
              this._applicationStateService.syncMudListJobEdit = [];
            }

            this._updateMudParameter(value.mudParameterData);
          })
        ),
      this._applicationStateService.updateMudDensity$
        .pipe(
          filter(() => {
            // this._model.pumpScheduleFluidTypeName will be always default - Drilling Fluid (Mud)
            // and is not updated to Drilling Fluid (Spacer) after reloading
            // this.isDrillingMud getter doesn't work so I use temporarily factory props

            const isFirstDrillingMud = this.isDrillingMudInStage(0);

            return isFirstDrillingMud
              && this._model.order === 0
              && this._stateFactory.currentIndex == 0;
          }),
          tap(density => {

            this._updateMudDensity(density);
          })
        )
    )
      .pipe(
        takeUntil(this._destroySubject),
        map(() => {

          return this._mudParameterToString(this._model.mudParameter);
        }),
        shareReplay()
      );

  public readonly thickeningTimeError$: Observable<boolean> = this._errorThickeningTimeSrc
    .asObservable().pipe(shareReplay());

  public readonly thickeningTimeWarning$: Observable<boolean>;

  public get isThickeningTimeInvalid$(): Observable<boolean> {
    return this.placementTimeForm.statusChanges
      .pipe(
        startWith('VALID'),
        map(() => this.placementTimeForm.get('thickeningTime').invalid
          && (this.placementTimeForm.get('thickeningTime').touched
            || this.placementTimeForm.get('thickeningTime').dirty)
        ),
        startWith('INVALID'),
        map(()=> this.placementTimeForm.get('thickeningTime').value == null)
      )
  }

  public readonly slurryType$: Observable<string> = combineLatest([
    this.selectedFluid$,
    this._availableSlurryTypes$
  ])
    .pipe(
      map(([fluid, availableSlurryTypes]) => {
        const foundSlurryType = availableSlurryTypes.find(st => st.id === fluid.slurryTypeId);
        // eslint-disable-next-line
        if (!!foundSlurryType) {
          fluid.slurryType = foundSlurryType.name;
        }
        return fluid ? fluid.slurryType : null;
      }),
      shareReplay()
    );

  public readonly plannedDensity$: Observable<number> = this.selectedFluid$
    .pipe(
      map(fluid => {

        return fluid ? fluid.density : null;
      }),
      shareReplay()
    );

  public readonly dropdownFluidItems$: Observable<SelectItem[]> = this._scheduleState.dropdownFluidItems$;

  public readonly cogs$: Observable<PumpScheduleStageCOGSModel>;

  public readonly noCogsMaterials$: Observable<MissingDataMaterial[]>;

  public readonly noBulkDensityMaterials$: Observable<MissingDataMaterial[]>;

  public readonly actualDensity$: Observable<number>;

  public readonly actualVolume$: Observable<number>;

  public readonly dryWeight$: Observable<number>;

  public readonly dryVolume$: Observable<number>;

  public readonly fluidName$: Subject<object> = new Subject<object>()

  private readonly _fluidForm: UntypedFormGroup;

  public readonly dropdownPlacementMethodItems$: Observable<SelectItem<string>[]>

  public constructor(

    public readonly _model: PumpScheduleStageModel,

    private readonly _availableStageTypes$: Observable<IPumpScheduleFluidType[]>,

    private readonly _availableSlurryTypes$: Observable<ISlurryType[]>,

    public readonly dropdownStageTypeItems$: Observable<SelectItem<string>[]>,

    private readonly _takeThickeningTimeFromSlurry: boolean,

    private readonly _unitConversionService: UnitConversionService,

    private readonly _applicationStateService: ApplicationStateService,

    private readonly _formManager: PumpScheduleFormManager,

    private readonly _scheduleState: PumpScheduleStateManager,

    private readonly _stateFactory: PumpScheduleStateFactory,

    private readonly _fluidService: FluidService,
  ) {

    this.form = this._formManager.createStageForm(
      this._model.order,
      this._stateFactory.viewState.isJobEditable
    );

    this._formManager.patchSilent(this.form, this._model);

    this._formManager.patchSilent(
      this.form.controls.mudParameter as UntypedFormGroup,
      this._model.mudParameter
    );

    this.placementTimeForm = this._formManager.createStagePlacementTimeForm();
    this._formManager.patchSilent(this.placementTimeForm, this._model);

    // udapte TT with with a fluid data from iFacts if it wasn't set manually
    this._updateTTcontrol();

    this._fluidForm = this._formManager.createFluidForm(this._model.order);

    // Need to create fluid form before creation of stage calculator
    // because data models for stage and fluid and forms for them are not aligned.
    // Some calculated values for stage model are actually set in fluid form,
    // not in stage form (Vietnam way of thinking). Probably need to refactor too.
    this._calc = this._createStageCalculator();

    // This calculator should be created before fluid state
    // because fluid materials (which are created by fluid state)
    // use calculated values from this calculator.
    this._fluidState = this._stateFactory.createFluidState(
      this._fluidForm,
      this.selectedFluid$,
      this._model,
      this._materialsStatesSrc,
      this._calc,
      this._scheduleState.spacerMixMethod$
    );

    this.cogs$ = this._getCogs();
    this.noCogsMaterials$ = this._getNoCogs();
    this.noBulkDensityMaterials$ = this._getNoBulkDensity();
    this.thickeningTimeWarning$ = this._setThickeningTimeValidation();

    this.actualDensity$ = this._getActualDensity();
    this.actualVolume$ = this._getActualVolume();
    this.dryWeight$ = this._getDryWeight();
    this.dryVolume$ = this._getDryVolume();

    this._subscribeToChanges();

    this.dropdownPlacementMethodItems$ = combineLatest([
      this.isDrillingMud$,
      this.isDrillingSpacer$,
    ]).pipe(
      switchMap(([isDrillingMud, isDrillingSpacer]) => {
        if (isDrillingMud || isDrillingSpacer) {
          return this._stateFactory._firstStagePlacementMethodDropdownItems$
        }

        return this._stateFactory._placementMethodDropdownItems$
      })
    )
  }

  public get viewState(): ViewState {

    return this._stateFactory.viewState;
  }

  public get order(): number {

    return this._model.order;
  }

  public setOrder(index) {
    this._model.order = index;
  }

  public get noType(): boolean {

    return !this._model.pumpScheduleFluidTypeId;
  }

  public get isDrillingMud(): boolean {

    return StageStateManager._isDrillingMudTypeName(this._model.pumpScheduleFluidTypeName)
      && this._model.order === 0;
  }

  public isDrillingMudInStage(stageNumber): boolean {

    const stages = this._stateFactory.listPumpScheduleStageStateManager[this._stateFactory.currentIndex].model.stages;
    if (stages[0] && stages[0].pumpScheduleFluidTypeName) {
      return StageStateManager._isDrillingMudTypeName(stages[stageNumber].pumpScheduleFluidTypeName);
    }
    return true;
  }

  public get isDrillingSpacer(): boolean {

    return StageStateManager._isDrillingSpacerTypeName(this._model.pumpScheduleFluidTypeName)
      && this._model.order === 0;
  }

  public get isCement(): boolean {

    return StageStateManager._isCementTypeName(this._model.pumpScheduleFluidTypeName);
  }

  public get isPlug(): boolean {

    return StageStateManager._isPlugTypeName(this._model.pumpScheduleFluidTypeName);
  }

  public get isTopPlug(): boolean {

    return StageStateManager._isTopPlugTypeName(this._model.pumpScheduleFluidTypeName);
  }

  public get fluid(): FluidModel {

    return this._model.slurry;
  }

  public get isShutdownStage(): boolean {

    return this._eventsStates.every(s => s.isShutdown);
  }

  public get number(): number {
    return this._model.number;
  }

  public readonly placementMethod$: Observable<number> = this.eventsStates$
    .pipe(
      switchMap(eventsStates =>
        from(eventsStates.map(s => s.placementMethod$))
      ),
      mergeAll(),
      map(() => {
        return this.order;
      }),
      shareReplay()
    );

  public get plannedVolume$(): Observable<number> {

    return this._calc.plannedVolume$;
  }

  public get avgRate$(): Observable<number> {

    return this._calc.avgRate$;
  }

  public get topOfFluid$(): Observable<number> {

    return this._calc.topOfFluid$;
  }

  public get plannedScope3Co2e$(): Observable<number> {

    return this._calc.plannedScope3Co2e$;
  }

  public get actualScope3Co2e$(): Observable<number> {

    return this._calc.actualScope3Co2e$;
  }

  public get totalBlendCO2e$(): Observable<number> {

    return of(this._model.totalBlendCO2e);
  }

  public get totalBlendActualCO2e$(): Observable<number> {

    return of(this._model.totalBlendActualCO2e);
  }

  public get loadoutVolume$(): Observable<number> {

    return this._calc.loadoutVolume$;
  }

  public get anyMaterialMissingBulkDensity$(): Observable<boolean> {

    return this._isFluidWithDryMaterials$
      .pipe(
        switchMap(withMaterials => {

          if (!withMaterials) {

            return of(false);
          }

          return this._materialsStates$
            .pipe(
              switchMap(materialStates => {

                return combineLatest(materialStates
                  .filter(ms => ms.isDry)
                  .map(ms => ms.bulkDensityAvailable$)
                );
              }),
              map(availableMap => {

                return availableMap.some(available => !available);
              })
            );
        }),
        shareReplay()
      );
  }

  public get placementTime$(): Observable<string> {

    return this._calc.placementTime$
      .pipe(
        map(time => {

          return this.viewState.formatTime(time);
        }),
        shareReplay()
      );
  }

  public get thickeningTime$(): Observable<string> {

    return this._calc.thickeningTime$
      .pipe(
        map(time => {

          return this.viewState.formatTime(time);
        }),
        shareReplay()
      );
  }

  public get specificShutdownTime$(): Observable<string> {

    return this._calc.specificShutdownTime$
      .pipe(
        map(time => {

          return this.viewState.formatTime(time);
        }),
        shareReplay()
      );
  }

  public get minThickeningTime$(): Observable<string> {

    return this._calc.minThickeningTime$
      .pipe(
        map(time => {

          return this.viewState.formatTime(time);
        }),
        shareReplay()
      );
  }

  public get actualSafety$(): Observable<string> {

    return this._calc.actualSafety$
      .pipe(
        map(time => {

          return this.viewState.formatTime(time);
        }),
        shareReplay()
      );
  }

  public get deadVolumeFluidTypeName$(): Observable<string> {

    return this._calc.deadVolumeType$
      .pipe(
        map(type => {

          if (!type) {

            type = DeadVolumeFluidType.CementSlurry;
          }

          return DeadVolumeFluidType[type].split(/(?=[A-Z])/).join(' ');
        }),
        shareReplay()
      );
  }

  public get fluidState(): FluidStateManager {

    return this._fluidState;
  }

  public get fluids$(): Observable<FluidModel[]> {
    return this._scheduleState.fluids$;
  }

  public static isPlugType(type: IPumpScheduleFluidType): boolean {

    return type && StageStateManager._isPlugTypeName(type.name);
  }

  private static _isMudTypeName(typeName: string): boolean {

    return StageStateManager._isDrillingMudTypeName(typeName)
      || typeName === FLUID_TYPE_SCHEDULE.MUD;
  }

  private static _isDrillingMudTypeName(typeName: string): boolean {

    return typeName === StageStateManager.DrillingMudTypeName;
  }

  private static _isDrillingSpacerTypeName(typeName: string): boolean {

    return typeName === StageStateManager.DrillingSpacerTypeName;
  }

  private static _isCementTypeName(typeName: string): boolean {

    return typeName === StageStateManager.CementTypeName;
  }

  private static _isTopPlugTypeName(typeName: string): boolean {

    return typeName === StageStateManager.TopPlugTypeName;
  }

  private static _isBottomPlugTypeName(typeName: string): boolean {

    return typeName === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG;
  }

  private static _isPlugDartPlugTypeName(typeName: string): boolean {

    return typeName === FLUID_TYPE_SCHEDULE.PLUG_DART;
  }

  private static _isPlugTypeName(typeName: string): boolean {

    return StageStateManager._isTopPlugTypeName(typeName)
      || StageStateManager._isBottomPlugTypeName(typeName)
      || StageStateManager._isPlugDartPlugTypeName(typeName);
  }

  private static _isFluidTypeName(typeName: string): boolean {

    return typeName
      && !StageStateManager._isMudTypeName(typeName)
      && !StageStateManager._isPlugTypeName(typeName);
  }

  public destroy(): void {

    this._subscriptions.unsubscribe();
    this._eventSubscriptions.unsubscribe();
    this._eventsStates.forEach(s => {

      s.destroy();
    });

    this._fluidState.destroy();

    this._eventsStatesSrc.complete();
    this._materialsStatesSrc.complete();
    this._errorThickeningTimeSrc.complete();
    this._warningThickeningTimeSrc.complete();

    this._destroySubject.next(null);
    this._destroySubject.complete();
  }

  public setLinkedFluidActual(fluid: FluidModel): void {
    const change = {
      actualSlurry: fluid
    };
    this.form.controls.actualSlurry.markAsDirty();
    this._formManager.patchAloud(this.form, change);

    const  pumpschedules = this._stateFactory.getListModel()
    const  pumpschedule = pumpschedules.find(x => x.order == this._model.pumpOrder)
    pumpschedule.stages.find(x => x.id == this.form.value.id).actualSlurry = fluid;
  }

  public setLinkedFluid(fluid: FluidModel): void {

    const change = {

      slurry: fluid
    };
       // eslint-disable-next-line
    if (!!fluid) {
      if (fluid.requestInfo && fluid.requestInfo.trademarkName) {
        change['fluidName'] = fluid.requestInfo.trademarkName;
      }
      // eslint-disable-next-line
      if (!!fluid.id) {
        change['selectedFluidId'] = fluid.id;
      }
    }

    this.form.controls.selectedFluidId.markAsDirty();
    this._formManager.patchAloud(this.form, change);
  }

  public isShutdownEvent(event: PumpScheduleEventModel): boolean {

    return this._eventsStates[event.order].isShutdown;
  }

  public getEventForm(event: PumpScheduleEventModel): UntypedFormGroup {
    return this._eventsStates[event.order].form;
  }

  public addEvent(): void {
    this.insertEvent(this._model.events.length);
  }

  public insertEvent(index: number, placementMethodName: string = null): void {
    const newEvent = this._insertEventModel(index, placementMethodName);
    this._insertEventState(newEvent, index);

    this._formManager.eventListChanged(this._model.order);
    this._eventsStatesSrc.next(this._eventsStates);

    if (this._model.pumpOrder !== 0) {
      this._model.events = this._eventsStates.map(item => item.form.value);
    }

    this._reOrderEventModel();
    const currentPumpScheduleModel = this._stateFactory.listPumpScheduleStageStateManager[this._stateFactory.currentIndex].model
    currentPumpScheduleModel.stages[this._model.order].events = this._model.events

    this._stateFactory.setListPumpScheduleStageStateManagerItemByPumpId(currentPumpScheduleModel)
  }

  public insertShutdownEvent(index: number): void {
    this.insertEvent(index, 'Shutdown');
  }

  public deleteEvent(event: PumpScheduleEventModel): void {
    this._deleteEventModel(event);
    this._deleteEventState(event);
    this._formManager.removeEventForm(this._model.order, event.order);

    this._eventsStatesSrc.next(this._eventsStates);
    this._reOrderEventModel();

    const currentPumpScheduleModel = this._stateFactory.listPumpScheduleStageStateManager[this._stateFactory.currentIndex].model
    currentPumpScheduleModel.stages[this._model.order].events = this._model.events

    this._stateFactory.setListPumpScheduleStageStateManagerItemByPumpId(currentPumpScheduleModel)
  }

  public placementMethodChanged(): void {

    this._eventsStates.forEach(s => {

      s.placementMethodChanged();
    });
  }

  public insertStageBefore(): void {

    this._scheduleState.insertStage(this.order);
  }

  public insertStageAfter(): void {

    this._scheduleState.insertStage(this.order + 1);
  }

  public deleteStage(): void {

    this._scheduleState.deleteStage(this.order);
  }

  public showCogsHelp(): void {

    this._scheduleState.showCogsHelp(this.order);
  }

  public getPushedBulkPlantsData(): PushedBulkPlantInfo[] {
    return this._scheduleState.getPushedBulkPlantsData();
  }

  public showMissingBulkDensityHelp(): void {

    this._scheduleState.showMissingBulkDensityHelp(this.order);
  }

  public linkFluids(): Observable<Observable<FluidModel>> {

    let testTypeId = null;
    let slurryTypeId = null;
    let requestIdHDF = null;

    if (this.fluid) {

      testTypeId = this.fluid.testTypeId;
      slurryTypeId = this.fluid.slurryTypeId;
      requestIdHDF = this.fluid.requestIdHDF;
    }

    return this._scheduleState.linkFluids(testTypeId, slurryTypeId, requestIdHDF);
  }

  public linkFluidsActual(): Observable<Observable<FluidModel>> {

    let testTypeId = null;
    let slurryTypeId = null;
    let requestIdHDF = null;

    if (this.fluid) {

      testTypeId = this.fluid.testTypeId;
      slurryTypeId = this.fluid.slurryTypeId;
      requestIdHDF = this.fluid.requestIdHDF;
    }

    return this._scheduleState.linkFluidsActual(testTypeId, slurryTypeId, requestIdHDF, this.number);
  }

  public patchEvents(events: any){
    this._eventsStates.forEach(s => s.canShowPlacementMethodWarning = false);
    this.form.controls.events.patchValue(events, {onlySelf: true});
    this._eventsStates.forEach(s => s.canShowPlacementMethodWarning = true);
  }

  private _setType(type: IPumpScheduleFluidType): void {
    this.isPrevStageTypeMud = this._model.pumpScheduleFluidTypeName ? StageStateManager._isMudTypeName(this._model.pumpScheduleFluidTypeName) : true;

    if (type) {

      this._model.pumpScheduleFluidTypeId = type.id;
      this._model.pumpScheduleFluidTypeName = type.name;

    } else {

      this._model.pumpScheduleFluidTypeId = null;
      this._model.pumpScheduleFluidTypeName = null;
    }

    this._changeModelForStageType();
    this._resetEventsStates();
  }

  private _updateFluidModel(availableFluids: FluidModel[], fluid: FluidModel): FluidModel {
    if (!fluid) {

      fluid = new FluidModel();
    }

    let found = this._findFluid(availableFluids, fluid);

    if (!found) {

      found = fluid;
    }

    if (found !== this._model.slurry) {

      if (found.requestInfo && !found.requestInfo.trademarkName && !found.iCemName && !found.name) {
        found.name = this._model.slurry.name;
      }

      if (!this._isMud || this._isMud && this.isPrevStageTypeMud)
        this._model.slurry = found;

      // for autocomplete on Schedule Edit

      this._formManager.patchSilent(this.form, { slurry: this._model.slurry });
      this._formManager.patchSilent(this.form, { fluidName: this.getFluidName(this._model) });
    } else {
      // for display fluidNmae on control point 1, 2, 4

      const newFluidName = this.getFluidName(this._model);
      const prevFluidName = this.form.get('fluidName').value;

      if (!newFluidName && prevFluidName) {
        this._model.slurry.name = prevFluidName;
      }

      this._formManager.patchSilent(this.form, { slurry: this._model.slurry });
      this._formManager.patchSilent(this.form, { fluidName: this.getFluidName(this._model) });
    }

    if (this.form.controls.selectedFluidId.value !== (this._model.slurry && this._model.slurry.id)) {

      // for dropdown on Control Points 1 and 2
      this._formManager.patchSilent(this.form, { selectedFluidId: this._model.slurry.id });
    }
    // 444369: TT wasn't updated for CP2 submit
    if (found && found.thickeningTime && !this._model.isManualyThickeningTime) {
      this._model.thickeningTime = found.thickeningTime;
    }
    return found;
  }
  private getFluidName(model) {
    if (!model.slurry) {
      return ''
    }
    if (model.slurry.requestInfo) {
      if (model.slurry.iCemName !== null) {
        return model.slurry.requestInfo.trademarkName === null || model.slurry.requestInfo.trademarkName === '' ?
          model.slurry.iCemName :
          model.slurry.requestInfo.trademarkName;
      }
      if (model.slurry.requestInfo.trademarkName === null || model.slurry.requestInfo.trademarkName === '') {
        return model.slurry.name;
      }
      return model.slurry.name === null ? model.slurry.requestInfo.trademarkName : model.slurry.name;
    } else {
      if (model.slurry.name === '' && !!model.slurry.sapMaterialName) {
        return model.slurry.sapMaterialName;
      }
      return model.slurry.name;
    }


  }

  private _findFluid(availableFluids: FluidModel[], fluid: FluidModel): FluidModel {

    let found = null;
    // eslint-disable-next-line
    if (!!fluid.id) {

      found = availableFluids.find(f => f.id === fluid.id) || null;

    } else if (!!fluid.requestId && !!fluid.slurryNo) {

      found = availableFluids.find(f => f.requestId === fluid.requestId && f.slurryNo === fluid.slurryNo) || null;

    } else {

      const isSavedFluid = !!this._model.slurry && !!this._model.slurry.id;

      if (!isSavedFluid && availableFluids.some(f => f.slurrySource === SlurrySource.HDFFluid)) {

        found = availableFluids.find(f => f.hdfSlurryIds.some(id => id === this._model.slurryIdHDF)) || null;
      }

      if (!found && availableFluids.some(f => f.tempId !== null) && fluid.tempId) {

        found = availableFluids.find(f => f.tempId === fluid.tempId);
      }
    }

    return found;
  }

  private _updateMudParameter(mudParameter: MudParameterModel): void {

    if (this._stateFactory.currentIndex === this._model.pumpOrder) {
      this._model.mudParameter = mudParameter;

      this._formManager.patchSilent(
        this.form.controls.mudParameter as UntypedFormGroup,
        this._model.mudParameter);

      const model = this._stateFactory.listPumpScheduleStageStateManager[this._stateFactory.currentIndex].model;
      if (!model.stages) return;
      const newStage = { ...model.stages[this._model.order], ...this._model };
      const stages = model.stages
      stages[this._model.order] = newStage

      this._stateFactory.setListPumpScheduleStageStateManagerItemByPumpId({ ...model, stages });
    }
  }

  private _updateMudDensity(density: number): void {

    const mudParameter = this._model.mudParameter;
    mudParameter.mudDensity = density;

    this._updateMudParameter(mudParameter);
  }

  private _createStageCalculator(): StageCalculator {

    const events$ = this.eventsStates$
      .pipe(
        map(eventsStates => {

          return eventsStates.map(es => es.calc);
        }),
        shareReplay()
      );

    const materials$ = this._materialsStates$
      .pipe(
        map(materialsStates => {

          return materialsStates.map(s => s.calc);
        }),
        shareReplay()
      );

    const loadoutVolume$ = this.isFluid$
      .pipe(
        filter(itis => itis),
        switchMapTo(
          concat(
            of(this._model.loadoutVolume),
            this._fluidForm.controls.loadoutVolume.valueChanges
          )
        ),
        shareReplay()
      );

    const bulkCement$ = this.isFluid$
      .pipe(
        filter(itis => itis),
        switchMapTo(
          concat(
            of(this._model.bulkCement),
            this._fluidForm.controls.bulkCement.valueChanges
          )
        ),
        distinctUntilChanged(),
        shareReplay()
      );

    const deadVolume$ = this.isFluid$
      .pipe(
        filter(itis => itis),
        switchMapTo(
          concat(
            of(this._model.deadVolume || 0),
            this._fluidForm.controls.deadVolume.valueChanges
          )
        ),
        distinctUntilChanged(),
        shareReplay()
      );

    const deadVolumeType$ = this.isFluid$
      .pipe(
        filter(itis => itis),
        switchMapTo(
          this.selectedFluid$
            .pipe(
              switchMap(() => {

                // If user changed stage fluid, reset dead volume fluid type to default
                if (this.form.controls.slurry.dirty) {

                  this._model.deadVolumeFluidType = DeadVolumeFluidType.CementSlurry;
                }

                return concat(
                  of(this._model.deadVolumeFluidType),
                  this._fluidForm.controls.deadVolumeFluidType.valueChanges
                );
              }),
            )
        ),
        distinctUntilChanged(),
        shareReplay()
      );

    const actualVolume$ = this.type$
      .pipe(
        switchMapTo(
          concat(
            of(this._model.actualVolumePumped),
            this.form.controls.actualVolumePumped.valueChanges
          )
        ),
        shareReplay()
      );

    const yield$ = this.selectedFluid$
      .pipe(
        map(fluid => {
        // eslint-disable-next-line
          return !!fluid ? fluid.yield : null;
        }),
        distinctUntilChanged(),
        shareReplay()
      );

    const mixWater$ = this.selectedFluid$
      .pipe(
        map(fluid => {
          // eslint-disable-next-line
          return !!fluid ? fluid.mixWater : null;
        }),
        distinctUntilChanged(),
        shareReplay()
      );

    const waterDensity$ = this.selectedFluid$
      .pipe(
        map(fluid => {
        // eslint-disable-next-line
          return !!fluid ? fluid.waterDensity : null
        }),
        distinctUntilChanged(),
        shareReplay()
      );

    const remaningStagesDurations$: Observable<number[]> = this._scheduleState.stagesStates$
      .pipe(
        switchMap(allStages => {

          const thisStageIndex = allStages.findIndex(s => s === this);

          const remainingStages = allStages
            .slice(thisStageIndex)
            .filter(s => !s.isPlug);

          return combineLatest(remainingStages.map(rs => rs._calc.duration$));
        }),
        shareReplay()
      );

    const thickeningTime$: Observable<number> = this.isFluid$
      .pipe(
        filter(itis => itis && this.isCement),
        switchMapTo(
          this.selectedFluid$
            .pipe(
              switchMap(fluid =>
                concat(
                  of(this._getThickeningTimeFromModel(fluid))
                    .pipe(
                      tap(v => {

                        // Thickening time can be also provided
                        // in Job Criteria section of Job Edit view.
                        // Current implementation reads the value stored
                        // in session and sync with it. See Job Edit container.
                        this._saveThickeningTimeInSession(v, true);
                      })
                    ),
                  this.placementTimeForm.controls.thickeningTime.valueChanges
                    .pipe(
                      tap(v => {

                        // Thickening time can be also provided
                        // in Job Criteria section of Job Edit view.
                        // Current implementation reads the value stored
                        // in session and sync with it. See Job Edit container.
                        this._saveThickeningTimeInSession(v);
                      }),
                      filter((v: string) => !v?.includes('_')),
                    )
                )
                  .pipe(
                    map(v => {

                      const t = this.viewState.parseTimeStringToMinutes(v);
                      return t;
                    })
                  )
              )
            )
        ),
        shareReplay()
      );

    const specificShutdownTime$ = this.isFluid$
      .pipe(
        filter(itis => itis && this.isCement),
        switchMapTo(
          concat(
            of(this.viewState.isJobTypeLiner),
            this._applicationStateService.jobTypeChanged
              .pipe(
                map(jobType => {

                  return jobType.context.isLiner
                    && (this.viewState.isScheduleEditView || this.viewState.isCP1View || this.viewState.isCP2View);
                })
              )
          ).pipe(
            switchMap(isLinerJobType =>
              concat(
                of(this._model.specificShutdownTime),
                this.placementTimeForm.controls.specificShutdownTime.valueChanges
                  .pipe(
                    filter((v: string) => !v.includes('_'))
                  )
              )
                .pipe(
                  map((value: string) => {

                    return isLinerJobType
                      ? this.viewState.parseTimeStringToMinutes(value)
                      : null;
                  })
                )
            )
          )
        ),
        shareReplay()
      );

    const loadoutByDeadVolumeTrigger$ = this._fluidForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged(),
        map(() => {
          const isDirty = this._fluidForm.controls.deadVolume.dirty
            || this._fluidForm.controls.deadVolumeFluidType.dirty;

          if (isDirty) {
            this._fluidForm.controls.deadVolume.markAsPristine();
            this._fluidForm.controls.deadVolumeFluidType.markAsPristine();
          }

          return isDirty;
        }),
        filter(dirty => !!dirty)
      );

    const loadouByBulkCementTrigger$ = this._fluidForm.valueChanges
      .pipe(
        map(() => {
          const isDirty = this._fluidForm.controls.bulkCement.dirty;

          if (isDirty) {
            this._fluidForm.controls.bulkCement.markAsPristine();
          }

          return isDirty;
        }),
        filter(dirty => !!dirty)
      );

    const originYield$ = this.selectedFluid$
      .pipe(
        map(fluid => {
          const originFluid = this._fluidService.originFluids?.find(f => f.id !== null && f.id === fluid.id);
          // eslint-disable-next-line
          return !!originFluid ? originFluid.yield : fluid?.yield;
        }),
        distinctUntilChanged(),
        shareReplay()
      );

    const calc = new StageCalculator(
      events$,
      materials$,
      loadoutVolume$,
      loadouByBulkCementTrigger$,
      loadoutByDeadVolumeTrigger$,
      bulkCement$,
      deadVolume$,
      deadVolumeType$,
      actualVolume$,
      yield$,
      originYield$,
      mixWater$,
      remaningStagesDurations$,
      thickeningTime$,
      specificShutdownTime$,
      this._scheduleState.scheduledShutdown$,
      this._scheduleState.targetSafetyFactor$,
      waterDensity$
    );

    this._subscriptions.add(
      combineLatest([yield$, originYield$])
        .pipe(
          filter(([yieldCurrent, originYield]) => {
            return round(yieldCurrent, 12) !== round(originYield, 12)
          }),
          switchMap(() =>{
            return combineLatest([this.selectedFluid$, this.number$, this._scheduleState.model$])
          })
        )
        .subscribe(([fluid, stageNumber, psModel]) => {
          const originSlurry = psModel.stages.find(s => s.id !== null && s.id === this._model.id)?.slurry;

          if (originSlurry && originSlurry.requestId === fluid.requestId && originSlurry.slurryNo === fluid.slurryNo) {
            this._fluidService.pushYieldChangedWarningMessage(fluid.requestId, fluid.slurryNo, psModel.name, stageNumber);
          }

        })
    )

    return calc;
  }

  private _updateFluidStageValue(formDataChanged): void {
    if (this._stateFactory.currentIndex === this._model.pumpOrder) {

      const model = this._stateFactory.listPumpScheduleStageStateManager[this._stateFactory.currentIndex].model;
      if (!model.stages) return;
      const newStage = { ...formDataChanged, ...model.stages[this._model.order], ...this._model };
      const stages = model.stages
      stages[this._model.order] = newStage

      this._stateFactory.setListPumpScheduleStageStateManagerItemByPumpId({ ...model, stages });
    }
  }

  private fluidStatePreviousStateMap = new Map();

  private _subscribeToChanges(): void {

    this._subscriptions.add(
      this.fluidState.form.valueChanges.subscribe((data) => {
        const current: FluidModel = this.fluidState.form.value;

        if (this.fluidStatePreviousStateMap.has(this.fluidState.id)) {
          const previous: FluidModel = this.fluidStatePreviousStateMap.get(this.fluidState.id);
          if (!FluidModel.equals(previous, current)) {
            this._updateFluidStageValue(data);
          }
        }
        else {
          this._updateFluidStageValue(data);
        }

        this.fluidStatePreviousStateMap.set(this.fluidState.id, current);
      })
    )

    // loadoutVolume can be set manually by the user (Load Out Qty field)
    // or it can be recalculated based on Events' Volume / Dead Qty / Bulk Cement changes
    // value will be the last updated
    this._subscriptions.add(
      this.plannedVolume$.pipe(skip(1)).subscribe(() => {
        this._fluidForm.controls.loadoutVolume.updateValueAndValidity();
      })
    );

    this._subscriptions.add(
      from([
        this._calc.plannedVolume$
          .pipe(
            map(plannedVolume => {
              return {
                plannedVolume: plannedVolume
              };
            })
          ),
        this._calc.avgRate$
          .pipe(
            map(avgRate => {
              return {
                avgRate: avgRate
              };
            })
          ),
        this._calc.topOfFluid$
          .pipe(
            map(topOfFluid => {
              return {
                topOfFluid: topOfFluid,
                default: 0
              };
            })
          ),
        this._calc.loadoutVolume$
          .pipe(
            map(loadoutVolume => {
              return {
                loadoutVolume: loadoutVolume
              };
            })
          ),
        this._calc.dryWeight$
          .pipe(
            map(dryWeight => {
              return {
                dryWeight: dryWeight,
                default: 0
              };
            })
          ),
        this._calc.dryVolume$
          .pipe(
            map(dryVolume => {
              return {
                dryVolume: dryVolume,
                default: 0
              };
            })
          ),
        this._calc.deadVolume$
          .pipe(
            map(deadVolume => {
              return {
                deadVolume: deadVolume,
                default: 0
              };
            })
          ),
        this._calc.deadVolumeType$
          .pipe(
            map(deadVolumeType => {
              return {
                deadVolumeFluidType: deadVolumeType,
                default: DeadVolumeFluidType.CementSlurry
              };
            })
          ),
        this._calc.stageWater$
          .pipe(
            map(stageWater => {
              return {
                waterRequirements: stageWater
              };
            })
          ),
        this._calc.bulkCement$
          .pipe(
            map(bulkCement => {
              return {
                bulkCement: bulkCement
              };
            })
          ),
        this._calc.placementTime$
          .pipe(
            map(placementTime => {
              return {
                placementTime: placementTime
              };
            })
          ),
        this._calc.thickeningTime$
          .pipe(
            map(thickeningTime => {
              return {
                thickeningTime: thickeningTime
              };
            })
          ),
          this._calc.specificShutdownTime$
            .pipe(
              map(specificShutdownTime => {
                return {
                  specificShutdownTime: specificShutdownTime
                };
              })
            ),
        this._calc.minThickeningTime$
          .pipe(
            map(minThickeningTime => {
              return {
                minThickeningTime: minThickeningTime
              };
            })
          ),
        this._calc.actualSafety$
          .pipe(
            map(actualSafety => {
              return {
                actualSafety: actualSafety
              };
            })
          ),
        this.actualDensity$
          .pipe(
            map(actualDensity => {
              return {
                actualDensity: actualDensity
              };
            })
          ),
        this._calc.actualVolume$
          .pipe(
            map(actualVolume => {
              return {
                actualVolumePumped: actualVolume
              };
            })
          )
      ])
        .pipe(
          mergeAll()
        )
        .subscribe((change: IFieldValue) => {

          const field = Object.keys(change)[0];
          const value = change[field];
          const defVal = change.default;

          this._setFieldValue(field, value, defVal);
        })
    );

    this._subscriptions.add(

      this.cogs$.subscribe(cost => {

        this._model.totalCOGS = cost.totalCOGS;
      })
    );

    this._subscriptions.add(
      this.form.get('pumpScheduleFluidTypeId').valueChanges.subscribe(() => {
        this.form.get('slurry').setValue(null);
      })
    )

    this._subscriptions.add(

      this.form.controls.comment.valueChanges.subscribe(value => {

        if (this.form.controls.comment.dirty) {

          this._model.comment = value;
        }
      })
    );

    this._subscriptions.add(

      this._fluidForm.controls.loadoutVolume.valueChanges.subscribe(() => {

        if (this._fluidForm.controls.loadoutVolume.dirty) {

          this._model.isChangeLoadOutVolume = true;
          this._model.isManuallyDeadVolume = true;   // do we need this?
        }
      })
    );

    this._subscriptions.add(
      this.fluidName$.subscribe(data => {
        this._scheduleState.updateFluidNames$.next(data)
      })
    )

    this._subscriptions.add(

      this._fluidForm.controls.bulkCement.valueChanges.subscribe(() => {

        if (this._fluidForm.controls.bulkCement.dirty) {

          this._model.isBulkCement = true;           // do we need this?
          this._model.isChangeLoadOutVolume = true;
          this._model.isManuallyDeadVolume = true;   // do we need this?
        }
      })
    );

    this._subscriptions.add(this.placementTimeForm.controls.is70BCOverrideThickeningTime.valueChanges.subscribe(rawStringValue => {
      if (rawStringValue)
        this._model.is70BCOverrideThickeningTime = rawStringValue;
    }));
    this._subscriptions.add(this.placementTimeForm.controls.thickeningTime70BC.valueChanges.subscribe(rawStringValue => {
      if (rawStringValue)
        this._model.thickeningTime70BC = rawStringValue;
    }));
    this._subscriptions.add(

      this.placementTimeForm.controls.thickeningTime.valueChanges.subscribe(rawStringValue => {
        if (this.placementTimeForm.controls.thickeningTime.dirty) {

          this._model.thickeningTime = this.viewState.timeMaskToTimeString(rawStringValue);
          this._model.isManualyThickeningTime = true;
          this._model.number = this._model.order
          this.form.markAsDirty();
          const currentModel = this._stateFactory.getListModel().filter(item => item.order === this._model.pumpOrder);
          this._stateFactory.setListPumpScheduleStageStateManagerItemByPumpId(currentModel.map((schedule: PumpSchedule) => {
            const stageChanged = schedule.stages.map(stage => {
              if (stage.order === this._model.order) {
                return { ...stage, thickeningTime: this._model.thickeningTime, isManualyThickeningTime: this._model.isManualyThickeningTime, is70BCOverrideThickeningTime: this._model.is70BCOverrideThickeningTime }
              } else {
                return stage
              }
            })
            return { ...schedule, stages: stageChanged }
          })[0])

        }

      })
    );

    this._subscriptions.add(

      this.number$.subscribe()
    );

    if (this._stateFactory.viewState.isCP4InCompletionMode) {
      this._setRequiredValidators();
    }

    this._subscriptions.add(

      this._stateFactory.viewState.cp4Completion$.subscribe(completion => {
        if (completion.complete) {
          this._setRequiredValidators(completion.pumpScheduleId, completion.isStageJob);
        }
      })
    );

    this._subscriptions.add(

      this.eventsStates$.subscribe(() => {

        this._scheduleState.updateStage(this.order);

        if (this._eventSubscriptions) {
          this._eventSubscriptions.unsubscribe();
        }

        this._eventSubscriptions = new Subscription();

        const eventArray = this.form.controls.events as UntypedFormArray;

        eventArray.controls.forEach(event => {

          this._eventSubscriptions.add(
            event.get('placementMethod').valueChanges.subscribe(() => {
              this._scheduleState.updateStage(this.order);
            })
          );
        });
      })
    );
    const formControlChange = [
      'pumpScheduleFluidTypeId',
      'fluidName',
      'slurry',
      'avgRate',
      'plannedVolume',
      'topOfFluid',
      'actualVolumePumped',
      'selectedFluidId',
      'actualDensity',
      'comment',
      'thickeningTime'
    ]
    formControlChange.map(item => {
      this._subscriptions.add(this.form.controls[item].valueChanges.subscribe(value => {
        this._model[item] = value;
        this._updateMudParameter(this._model.mudParameter)
      }))
    })

    this._subscriptions.add(
      combineLatest([
        this._availableStageTypes$,
        this.form.controls.pumpScheduleFluidTypeId.valueChanges,
      ])
        .pipe(
          map(([types, typeId]) => types.find(at => at.id === (typeId || null)) || null),
        )
        .subscribe(type => {
          const isMud = StageStateManager._isMudTypeName(type.name);

          if (isMud && this._stateFactory.currentIndex === 0 && this._model.order === 0) {
            setTimeout(() => { this._applicationStateService.initiatePassMudDensityFromCDI$.next(null); }, 0);
          }
        })
    )

  }

  private _changeModelForStageType(): void {

    if (this.isPlug) {

      this._model.events = [];

    } else if (!this._model.events.length) {

      this._insertEventModel(0);
    }

    if (this._isFluid) {

      if (!this._model.slurry) {

        this._model.slurry = new FluidModel();
      }
    }

    if (this._isMud && !this.isPrevStageTypeMud) {
      this._model.slurry = new FluidModel();
    }
  }

  private _resetEventsStates(): void {

    this._eventsStates.forEach(es => es.destroy());

    this._eventsStates = this._model.events.map(event => {

      return this._stateFactory.createEventState(event, this._model.order, this.isCement);
    });

    this._eventsStatesSrc.next(this._eventsStates);
  }

  private _toNumberOrDefault(value: number, defVal: number): number {

    if (value !== 0 && (!value || isNaN(Number(value)))) {

      return defVal;
    }

    return Number(value);
  }

  private _setFieldValue(field: string, value: number, defVal: number): void {

    if (defVal === undefined) {

      defVal = null;
    }

    let val: number | string = defVal;
    let newCtrlVal: number | string = val;

    const isTimeStringField = StageStateManager._timeStringFields.some(f => f === field);

    if (isTimeStringField) {

      newCtrlVal = this.viewState.formatTime(value);
      if (field === 'placementTime') {

        val = this._toNumberOrDefault(value, defVal);

      } else {

        val = newCtrlVal;
      }

    } else {

      val = this._toNumberOrDefault(value, defVal);
      newCtrlVal = value;
    }

    this._model[field] = val;

    const isFluidFormField = StageStateManager._fluidFormFields.some(f => f === field);

    let form = this.form;

    if (isFluidFormField) {

      form = this._fluidForm;

    } else if (isTimeStringField) {

      form = this.placementTimeForm;
    }

    const formPatch = {};
    const ctrl = form.controls[field];
    const currentCtrlVal = ctrl && ctrl.value;

    if (ctrl && currentCtrlVal !== newCtrlVal) {

      formPatch[field] = newCtrlVal;
    }

    if (field === 'waterRequirements') {

      formPatch['stageWaterTotal'] = newCtrlVal;
      this._model.stageWaterTotal = val as number;
    }

    if (Object.keys(formPatch).length > 0) {

      this._formManager.patchSilent(form, formPatch);
    }
  }

  private _getThickeningTimeFromModel(fluid: FluidModel): string {

    let thickeningTime: string = null;

    if (this._model.isManualyThickeningTime || this._model.is70BCOverrideThickeningTime) {

      thickeningTime = this._model.thickeningTime;

    } else if (this._takeThickeningTimeFromSlurry && !!fluid && fluid.status !== 'Draft' && this._model.thickeningTime==null) {

      thickeningTime = fluid.thickeningTime;
    }

    return thickeningTime;
  }

  private _saveThickeningTimeInSession(timeString: string, original: boolean = false): void {

    const storageEntry = {
      id: this._model.id,
      thickeningTime: timeString
    };

    if (original) {

      // Thickening time aready saved and present in model.
      storageEntry['thickeningTimeOld'] = timeString;
    }

    let timeStorage = {};

    const json = window.sessionStorage.getItem('shoe-track');
    if (json) {

      timeStorage = JSON.parse(json);
    }

    const id = storageEntry.id;

    if (!timeStorage[id]) {

      timeStorage[id] = {};
    }

    timeStorage[id] = { ...timeStorage[id], ...storageEntry };

    const modifiedJson = JSON.stringify(timeStorage);
    window.sessionStorage.setItem('shoe-track', modifiedJson);
  }

  private _insertEventModel(index: number, placementMethodName: string = null): PumpScheduleEventModel {
    const newEvent = new PumpScheduleEventModel();
    let dataPlacement;
    if (this.dropdownPlacementMethodItems$)
      this.dropdownPlacementMethodItems$.subscribe(data => {
        dataPlacement = data
      });

    newEvent.placementMethodName = placementMethodName;
    newEvent.order = index;
    newEvent.placementMethod = Array.isArray(dataPlacement) ?
      dataPlacement.filter(item => item.label === placementMethodName)[0]?.value :
      null
    const eventsBefore = this._model.events.slice(0, index);
    const eventsAfter = this._model.events.slice(index);

    eventsAfter.forEach(e => {
      e.order = e.order + 1;
    });

    this._model.events = [
      ...eventsBefore,
      newEvent,
      ...eventsAfter
    ];

    return newEvent;
  }

  private _deleteEventModel(event: PumpScheduleEventModel): void {

    const index = event.order;
    const eventsBefore = this._model.events.slice(0, index);
    let eventsAfter: PumpScheduleEventModel[] = [];

    if (index + 1 < this._model.events.length) {

      eventsAfter = this._model.events.slice(index + 1);

      eventsAfter.forEach(s => {
        s.order = s.order - 1;
      });
    }

    this._model.events = [
      ...eventsBefore,
      ...eventsAfter
    ];
  }

  private _insertEventState(event: PumpScheduleEventModel, index: number): void {

    const eventsStatesBefore = this._eventsStates.slice(0, index);
    const eventsStatesAfter = this._eventsStates.slice(index);

    this._eventsStates = [
      ...eventsStatesBefore,
      this._stateFactory.createEventState(event, this._model.order, this.isCement),
      ...eventsStatesAfter
    ];
  }

  private _deleteEventState(event: PumpScheduleEventModel): void {

    const index = event.order;

    const eventState = this._eventsStates[index];
    eventState.destroy();

    const statesBefore = this._eventsStates.slice(0, index);
    let statesAfter: EventStateManager[] = [];

    if (index + 1 < this._eventsStates.length) {

      statesAfter = this._eventsStates.slice(index + 1);
    }

    this._eventsStates = [
      ...statesBefore,
      ...statesAfter
    ];
  }

  private get _isMud(): boolean {

    return StageStateManager._isMudTypeName(this._model.pumpScheduleFluidTypeName);
  }

  private get _isFluid(): boolean {

    return StageStateManager._isFluidTypeName(this._model.pumpScheduleFluidTypeName);
  }

  public isIFactsFluid(fluid: FluidModel): boolean {

    return !!fluid && !!fluid.slurryId;
  }

  private _mudParameterToString(param: MudParameterModel): string {

    const unitName = this._unitConversionService.getUnitName(UnitType.Density, false);

    let mudDensityString = '';
    if (param.mudDensity != null) {
      const convertedDensity = convertWithUnitMeasure(
        param.mudDensity,
        this._unitConversionService.getApiUnitMeasure(UnitType.Density),
        this._unitConversionService.getCurrentUnitMeasure(UnitType.Density)
      );
      mudDensityString = `${formatNumber(convertedDensity, 2)} ${unitName}`;
    }

    const stringArray = [mudDensityString, param.typeName, param.mudName];

    return stringArray.filter(Boolean).join(', ');
  }

  private _getCogs(): Observable<PumpScheduleStageCOGSModel> {

    return this.isFluid$
      .pipe(
        switchMap(itis => {

          if (!itis) {

            return this._getZeroCost();
          }

          return this.selectedFluid$
            .pipe(
              switchMap(fluid => {

                if (!fluid || !fluid.fluidMaterial?.length) {

                  return this._getZeroCost();
                }

                return combineLatest([
                  this._fluidState.materialsCogs$,
                  this.viewState.isCogsCalculationDisabled$
                ])
                  .pipe(
                    map(([materialsCogs, calcDisabled]) => {

                      let stageCogs = this._model.totalCOGS;

                      if (!calcDisabled || this.form.controls.selectedFluidId.dirty) {

                        stageCogs = this._calc.calcStageFluidCost(
                          materialsCogs.map(c => c.totalCOGS)
                        );
                      }

                      return this._toStageCogsModel(materialsCogs, stageCogs);
                    })
                  );
              })
            );
        }),
        shareReplay()
      );
  }

  private _getNoCogs(): Observable<MissingDataMaterial[]> {

    return this.isFluid$
      .pipe(
        switchMap(itis => {

          if (!itis) {

            return of([]);
          }

          return this.selectedFluid$
            .pipe(
              switchMap(fluid => {

                if (!fluid || !fluid.fluidMaterial?.length) {

                  return of([]);
                }

                return this._fluidState.noCogsMaterials$;
              })
            );
        }),
        shareReplay()
      );
  }

  private _getNoBulkDensity(): Observable<MissingDataMaterial[]> {

    return this.isFluid$
      .pipe(
        switchMap(itis => {

          if (!itis) {

            return of([]);
          }

          return this.selectedFluid$
            .pipe(
              switchMap(fluid => {

                if (!fluid || !fluid.fluidMaterial?.length) {

                  return of([]);
                }

                return this._fluidState.noBulkDensityMaterials$;
              })
            );
        }),
        shareReplay()
      );
  }

  private _getZeroCost(): Observable<PumpScheduleStageCOGSModel> {

    const zeroCost = new PumpScheduleStageCOGSModel();
    zeroCost.id = this._model.id;
    zeroCost.fluidMaterials = [];
    zeroCost.totalCOGS = 0;

    return of(zeroCost);
  }

  private _setThickeningTimeValidation(): Observable<boolean> {

    this.placementTimeForm.controls.thickeningTime.setValidators([control => {

      const placementTime = this.viewState.parseTimeStringToMinutes(
        this.placementTimeForm.controls.placementTime.value
      );

      const thickeningTime = this.viewState.parseTimeStringToMinutes(control.value);

      if (thickeningTime !== 0 &&
        (!thickeningTime || isNaN(thickeningTime) || thickeningTime > placementTime)) {

        this._errorThickeningTimeSrc.next(false);

        return null;
      }

      this._errorThickeningTimeSrc.next(true);

      return { messageTime: true };
    }, Validators.required]);

    return combineLatest([
      this._calc.placementTime$,
      this._calc.minThickeningTime$,
      this._calc.thickeningTime$
    ])
      .pipe(
        map(([placementTime, minThickeningTime, thickeningTime]) => {

          return placementTime < thickeningTime && thickeningTime < minThickeningTime;

        }),
        shareReplay()
      );
  }

  private _toStageCogsModel(
    materialsCogs: PumpScheduleStageMaterialCOGSModel[],
    stageCogs: number
  ): PumpScheduleStageCOGSModel {

    const stageCost = new PumpScheduleStageCOGSModel();
    stageCost.id = this._model.id;
    stageCost.fluidMaterials = materialsCogs;
    stageCost.totalCOGS = stageCogs;

    return stageCost;
  }

  private _getActualDensity(): Observable<number> {

    return this.form.controls.actualDensity.valueChanges
      .pipe(
        map(v => {
          return v;
        }),
        shareReplay()
      );
  }

  private _getActualVolume(): Observable<number> {

    return this.form.controls.actualVolumePumped.valueChanges
      .pipe(
        map(v => {
          return Number(v);
        }),
        shareReplay()
      );
  }

  private _getDryWeight(): Observable<number> {

    return this.isMud$
      .pipe(
        switchMap(isMud => {

          if (isMud) {

            return of(null);
          }

          return this._calc.dryWeight$;
        }),
        shareReplay()
      );
  }

  private _getDryVolume(): Observable<number> {

    return this.isMud$
      .pipe(
        switchMap(isMud => {

          if (isMud) {

            return of(null);
          }

          return this._calc.dryVolume$;
        }),
        shareReplay()
      );
  }

  private _setRequiredValidators(pumpScheduleId: string = null, isStageJob: boolean = false): void {
    if (isStageJob || pumpScheduleId === this.form.controls.actualDensity.parent.parent.parent.value.pumpScheduleId) {
      if (!this.isPlug && !this.isDrillingMud) {
        const reassignValue = this.form.controls.actualDensity.value;
        this.form.controls.actualDensity.setValidators(Validators.required);
        this.form.controls.actualDensity.markAsTouched();
        this.form.controls.actualDensity.patchValue(reassignValue);
        this.form.controls.actualDensity.updateValueAndValidity();
      }

      const reassignValue = this.form.controls.actualVolumePumped.value;
      this.form.controls.actualVolumePumped.setValidators(Validators.required);
      this.form.controls.actualVolumePumped.markAsTouched();
      this.form.controls.actualVolumePumped.patchValue(reassignValue);
      this.form.controls.actualVolumePumped.updateValueAndValidity();
    }
  }

  private _updateTTcontrol() {

    const controlTT = this.placementTimeForm.controls.thickeningTime as UntypedFormControl;

    if (controlTT && !controlTT.value) {

      this.selectedFluid$.subscribe(fluid => {

        if (fluid && fluid.thickeningTime && !this._model.isManualyThickeningTime) {
          controlTT.setValue(fluid.thickeningTime);
        }
      });
    }
  }

  private _reOrderEventModel() {
    this._model.events = this._model.events.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })
    this._eventsStates.map((item, index) => {
      item.form.get('order').setValue(index, { onlySelf: true, emitEvent: false });
      item.model.order = index;
      return item
    })
  }
}
