import { ControlValueAccessor, NgControl, AbstractControl, UntypedFormControl } from '@angular/forms';
import { AfterViewInit, Injector, ChangeDetectorRef, Directive } from '@angular/core';

@Directive()
export class FormValueAccessor implements ControlValueAccessor, AfterViewInit {
  control: AbstractControl; // Reference to the formControl
  _cdRef: ChangeDetectorRef;
  _formControl: UntypedFormControl = new UntypedFormControl();
  // eslint-disable-next-line
  _propagateChange = (_: any) => { };
  _propagateTouched = () => { };

  constructor(protected inj: Injector) {
    this._cdRef = inj.get(ChangeDetectorRef);
  }

  ngAfterViewInit() {
    this.injectNgControl();
  }

  injectNgControl() {
    const ngControl = this.inj.get(NgControl);
    this.control = ngControl ? ngControl.control : null;
  }

  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._propagateTouched = fn;
  }

  writeValue(obj: any): void {
    this._formControl.setValue(obj);
    this._cdRef.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this._formControl.disable();
    else
      this._formControl.enable();
  }
}
