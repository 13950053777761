import { Subscription } from "rxjs";

export class SubscriptionArray {
    public get subscriptions(): Subscription[] {
        return [...this._subscriptions];
    }

    public get closed(): boolean {
        return this._subscriptions.every(s => s.closed)
    }

    private _subscriptions: Subscription[] = [];
    private _onComplete: any = () => {};

    public push(value: Subscription) {
        if (!value || !(value instanceof Subscription)) {
            return;
        }

        value.add(() => this._complete())

        this._subscriptions.push(value);
    }

    public onComplete(func: any) {
        this._onComplete = func;
    }

    public destroy() {
        this._subscriptions = [];
    }

    private _complete() {
        if (this.closed) {
            this._onComplete();
            this._subscriptions = [];
        }
    }
}
