import { UnitMeasureModel } from 'libs/models';
import { CustomUnit } from 'libs/constants';
import { preciseRound } from 'libs/helpers/math';

export const DEFAULT_DECIMAL_PLACES = 3;
export const DEFAULT_PRECISION_SCALE = '25,12';

export const TO_KM_MODIFICATOR = 0.62137119;
export const TO_MILE_MODIFICATOR = 1.609344;

export function formatNumber(value, decimalPlaces = DEFAULT_DECIMAL_PLACES, minDecimalPlaces = 0): string {

  if (decimalPlaces === null) {
    decimalPlaces = DEFAULT_DECIMAL_PLACES;
  }

  if (value === '' || value === null)
    return null;
  if (isNaN(value))
    return value;
  const result = +value;

  const roundedResult = preciseRound(result, decimalPlaces);

  if (minDecimalPlaces > 0) {
    return roundedResult.toFixed(minDecimalPlaces);
  }

  return roundedResult.toString();
}

export function getDisplayValue(sourceValue: any, sourceUoM: UnitMeasureModel, displayUoM: UnitMeasureModel, sackWeight: number = null, yieldValue: number = null) {
  if (sourceValue === null || sourceValue === undefined || sourceValue === '' || isNaN(sourceValue))
    return sourceValue;

  let displayValue = +convertWithUnitMeasure(sourceValue, sourceUoM, displayUoM, sackWeight, yieldValue);
  displayValue = displayValue != null ? preciseRound(displayValue, 8) : null;
  return displayValue;
}

export function getSourceValue(displayValue: any, displayUoM: UnitMeasureModel, sourceUoM: UnitMeasureModel, sackWeight: number = null, yieldValue: number = null) {
  if (displayValue === null || displayValue === undefined || displayValue === '')
    return displayValue;

  const sourceValue = +convertWithUnitMeasure(displayValue, displayUoM, sourceUoM, sackWeight, yieldValue);
  return sourceValue;
}

export function convertWithUnitMeasure(value: number, fromUoM: UnitMeasureModel, toUoM: UnitMeasureModel, sackWeight: number = null, yieldValue: number = null): number {

  if (!fromUoM || !toUoM || !fromUoM.scale || !toUoM.scale || fromUoM === toUoM || fromUoM.name === toUoM.name) {
    return value;
  }

  if (fromUoM.name === 'miles' || toUoM.name === 'miles') {
    return convertDistance(value, toUoM.name, fromUoM.name);
  }

  if (fromUoM.name === '°C' || toUoM.name === '°C') {
    return convertTemperature(value, toUoM.name, fromUoM.name);
  }

  const calValue = ((value / fromUoM.scale) - fromUoM.offset + toUoM.offset) * toUoM.scale;

  if (toUoM.name === 'L/100kg' || toUoM.name === 'm3/MT') {
    return sackWeight ? calValue / +sackWeight * 94 : calValue;
  }

  if (fromUoM.name === 'gal/sk' && (toUoM.name === CustomUnit.galbbl || toUoM.name === CustomUnit.lm3)) {
    return calValue / +(sackWeight || 1) / +(yieldValue || 1);
  }

  if (sackWeight && (toUoM.name === 'metric ton' || toUoM.name === 'lbs' || toUoM.name === 'kg')) {
    return sackWeight ? calValue * +sackWeight : calValue;
  }

  if (sackWeight && (fromUoM.name === 'metric ton' || fromUoM.name === 'lbs' || fromUoM.name === 'kg')) {
    return sackWeight ? calValue / +sackWeight : calValue;
  }

  return calValue;
}

function convertTemperature(value, destinationUnit, originUnit) {
  if (destinationUnit === '°C' && originUnit === '°F') {
    return (value - 32) * 5 / 9;
  }

  if (destinationUnit === '°F' && originUnit === '°C') {
    return (value * 9 / 5) + 32;
  }

  return value;
}

function convertDistance(value: number, originUnit: string, destinationUnit: string) {
  if (originUnit === 'miles' && destinationUnit === 'km') {
    return value / TO_MILE_MODIFICATOR;
  }

  if (originUnit === 'km' && destinationUnit === 'miles') {
    return value * TO_MILE_MODIFICATOR;
  }

  return value;
}
