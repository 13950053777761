import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'call-sheet-selection-details',
  templateUrl: './call-sheet-selection-detail.component.html',
  styleUrls: ['./call-sheet-selection-detail.component.scss']
})
// eslint-disable-next-line
export class CallSheetSelectionDetails {

  @Input() callSheet: UntypedFormGroup;
  @Input() jobId: string;
  @Input() wellId: string;
  @Output() valueChange = new EventEmitter<any>();
  constructor() { }

  disableOtherRadioButton() {
    this.valueChange.emit(this.callSheet.value);
  }
}
