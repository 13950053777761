import { Directive, forwardRef, Input, Injector, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessorDirective } from '../form-value-accessor.directive';
import { formatNumber } from './unit-helper';

@Directive({
  selector: '[dUnitFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitFormat2Directive),
      multi: true
    }
  ]
})
export class UnitFormat2Directive extends FormValueAccessorDirective implements AfterViewInit {

  private readonly DEFAULT_DECIMAL_PLACES = 2;
  _formattedValue: string;
  @Input() dUnitFormat: number;

  constructor(
    protected inj: Injector,
    protected renderer: Renderer2,
    protected elementRef: ElementRef) {
    super(inj, renderer, elementRef);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.renderViewValue(this.control.value);
  }

  renderViewValue(value: any) {
    this._value = value;
    this._formattedValue = formatNumber(this._value, this.dUnitFormat || this.DEFAULT_DECIMAL_PLACES);
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._formattedValue);
  }

  renderViewFocus(isFocus: boolean): void {
    if (isFocus) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._value);
    } else {
      this._formattedValue = formatNumber(this._value, this.dUnitFormat || this.DEFAULT_DECIMAL_PLACES);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._formattedValue);
    }
  }
}
