import { Component, EventEmitter, OnInit } from '@angular/core';
import { SlurrySource, UnitType } from 'libs/constants';
import { environment } from 'libs/environment';
import { toNumber } from 'libs/helpers/lodash-helper';
import { FluidModel, IFactsRequest, ILab, ISlurryType, Job, IFactsRequestInfo } from 'libs/models';
import { ApplicationStateService, FluidService, GroupService, IfactService, MasterDataService, MaterialService, SessionService } from 'libs/shared/services';
import { DynamicSidebarContent, DynamicSidebarRef } from 'libs/ui';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { MessageService } from 'primeng/api';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { PumpScheduleService } from '../../../pump-schedule/services';
import { ActionState } from '../../../shared/constant';
import { PDropdownDataSanitizerHelper } from '../../../shared/helpers';
import { IFactMapping, standardConcentrationUnit } from './ifact-create-new-request.mapping';

@Component({
  selector: 'ifact-copy-request-sidebar',
  templateUrl: './ifact-copy-request-sidebar.component.html',
  styleUrls: ['./ifact-copy-request-sidebar.component.scss']
})
export class IfactCopyRequestSidebarComponent implements DynamicSidebarContent, OnInit {

  data: any = {
    index: null,
    fluid: null,
    job: null,
    prefUserName: null,
    requestId: null,
    slurryNo: null,
    slurryId: null,
    testType: null,
    density: null,
    fluidId: null,
    jobTypeId: null,
    slurryTypeLabel: null,
    action: null
  };

  title: string;
  labs: any[];
  labId: number;
  customers: any[];
  customerId: number;
  usedFors = [{ label: 'Cement', value: 1 }, { label: 'Spacer', value: 2 }];
  usedFor = 1;
  requestTypes: any[];
  holeSizes: any[];
  casingSizes: any[];
  slurryTypes: any[];
  rigs: any[];
  wells: any[];
  blends: any[];
  selectedAreaId: number;
  errorMessage: any;
  currentRequest: IFactsRequest;
  requests: any[];
  loadingSubscription: Subscription;
  UnitType: any = UnitType;
  isTransferChecked: boolean;
  createSuccessEvent = new EventEmitter<any>();
  userAreaId: number;
  selectedLabName: string;
  ActionState: typeof ActionState = ActionState;
  vidaSlurryTypes: ISlurryType[];
  isValidUser: boolean;



  constructor(
    private masterDataService: MasterDataService,
    private applicationStateService: ApplicationStateService,
    private ifactService: IfactService,
    private sessionService: SessionService,
    private unitConversionService: UnitConversionService,
    private messageService: MessageService,
    private fluidService: FluidService,
    private materialService: MaterialService,
    private pumpScheduleService: PumpScheduleService,
    private dynamicSidebarRef: DynamicSidebarRef<IfactCopyRequestSidebarComponent>,
    private groupService: GroupService
  ) { }

  ngOnInit() {
    const halID = this.data.prefUserName;
    if (this.data && this.data.fluid) {
      this.loadingSubscription = forkJoin(
        this.masterDataService.listSlurryTypes(),
        this.ifactService.getLabs(),
        this.ifactService.getUsers(halID),
        this.ifactService.getRequestDetail(this.data.fluid.slurryId),
        this.groupService.getGroup(this.data.job.group)
      ).subscribe(([vidaSlurryTypes, labs, users, request, group]) => {
        this.vidaSlurryTypes = vidaSlurryTypes;
        this.getIFactsLabs(labs);


        if(group && group.iFactsLabId) {
          this.labId = group.iFactsLabId;
        }
        else
        {
          // set lab dropdownlist data
          let defaultLabId = this.getLocalStoragePreviousLabId();

          if (!defaultLabId && users.length > 0) {
            defaultLabId = users[0].LabId;
            this.saveDefaultLabToLocalStorage(defaultLabId);
          }

          this.labId = defaultLabId;
        }



        if (request) {
          this.currentRequest = request;
          const usedFor = this.usedFors.find(x => x.value === this.currentRequest.usedFor);
          this.title = `Copy Request: ${this.data.requestId}/${this.data.slurryId}, ${this.data.testType}, ${usedFor.label}`;
        }

        if (users && users.length) {
          this.isValidUser = users[0].Status === 'ACTIVE';
        }

        this.onChangeLab();
      });
    } else {
      this.loadingSubscription = forkJoin(
        this.ifactService.getLabs(),
        this.ifactService.getUsers(halID),
      ).subscribe(([labs, users]) => {
        this.getIFactsLabs(labs);

        // set lab dropdownlist data
        let defaultLabId = this.getLocalStoragePreviousLabId();

        if (!defaultLabId && users.length > 0) {
          defaultLabId = users[0].LabId;
          this.saveDefaultLabToLocalStorage(defaultLabId);
        }
        this.labId = defaultLabId;
        // this.title = 'Create iFacts Request Based on Selected Fluid';
        this.onChangeLab();
        if (users && users.length) {
          this.isValidUser = users[0].Status === 'ACTIVE';
        }
      });
    }

    if (this.data.action === ActionState.Created) {
      this.title = 'Create iFacts Request Based on Selected Fluid';
    }

    this.getRequestTypes();
    this.getHoleSizes();
    this.getCasingSizes();
    this.getIFactSlurryTypes();

    if (this.data.slurryTypeLabel === 'Spacer') {
      this.usedFor = 2;
    }
  }

  private getIFactsLabs(labs: ILab[]) {
    this.labs = labs.map(lab => (
      {
        label: lab.LabName,
        value: lab.LabId,
        areaId: lab.AreaId
      }
    ));
  }

  private getRequestTypes() {
    this.ifactService.getRequestTypes().subscribe(response => this.requestTypes = response);
  }

  private getHoleSizes() {
    this.ifactService.getHoleSizes().subscribe(response => this.holeSizes = response);
  }

  private getCasingSizes() {
    this.ifactService.getCasingSizes().subscribe(response => this.casingSizes = response);
  }

  private getIFactSlurryTypes() {
    this.ifactService.getSlurryTypes().subscribe(response => this.slurryTypes = response);
  }

  private getJobType(labId: number, jobTypeName: string) {
    this.ifactService.getJobTypeValue(labId, jobTypeName).subscribe(res => this.data.jobTypeId = res);
  }

  onChangeLab() {
    const selectedLabId = this.labId;
    if (!selectedLabId) {
      this.customers = [];
      return;
    }

    const lab = this.labs.find(x => x.value === selectedLabId);
    this.selectedAreaId = lab.areaId;
    this.selectedLabName = lab.label;

    this.getJobType(selectedLabId, this.data.job.jobTypeName);

    this.saveDefaultLabToLocalStorage(selectedLabId);

    this.loadCustomersByArea();
  }

  private loadCustomersByArea() {
    // load customers
    if (!this.selectedAreaId) {
      return;
    }

    this.customers = [];
    this.loadingSubscription = this.ifactService.getCustomers(this.selectedAreaId).subscribe((customerData) => {
      this.customers = PDropdownDataSanitizerHelper('customerName', 'customerId', customerData);
      const selectedCustomer = this.customers.find(x => x.label.toLowerCase() === this.data.job.customerName.toLowerCase());
      if (selectedCustomer) {
        this.customerId = selectedCustomer.value;
      }
    });
  }

  private saveDefaultLabToLocalStorage(customerId) {
    localStorage.setItem(`CopyIfactRequest.${this.sessionService.user.prefUserName}.PreviousLabId`, customerId);
  }

  close() {
    this.dynamicSidebarRef.close();
  }

  submitCopyRequest() {
    // authorize user
    if (!this.isValidUser) {
      this.showMessage(false, `User is an invalid iFacts user of the selected lab for the new request.`);
      return;
    }
    // get request detail
    if (!this.customerId || !this.usedFor) {
      this.handleInvalidRequestId();
      return;
    }
    this.loadingSubscription = forkJoin(
      this.ifactService.getRigs(this.selectedAreaId),
      this.ifactService.getWells(this.selectedAreaId),
      this.ifactService.getBlends(this.selectedAreaId),
      this.ifactService.getHoleSizeValue(this.data.job.holeSizeValue ?? this.currentRequest.holeSize),
      this.ifactService.getCasingSizesValue(this.data.job.casingSizeValue ?? this.currentRequest.casingSize),
      this.ifactService.getRigsValue(this.selectedAreaId, this.data.job.rigName.toLowerCase().trim())
    ).pipe(
      switchMap(([rigs, wells, blends, holeSizeVal, casingSizeVal, rigsValue]) => {
        this.rigs = rigs;
        this.wells = wells;
        this.blends = blends;
        const iFactData = {
          holeSize: holeSizeVal,
          casingSize: casingSizeVal,
          rigId: rigsValue
        };

        const preparedData = this.prepareCopyRequestData(this.currentRequest, this.data.fluid, iFactData);
        return forkJoin([
          of(preparedData),
          this.ifactService.createRequestWithGenMat(preparedData) as Observable<any>
        ]);
      }),
      switchMap(([preparedData, res]) => {
        if (res.status && res.requestid > 0 && res.slurryid > 0) {
          return forkJoin([
            of(preparedData),
            of(res),
            this.ifactService.getRequestInfo(res.requestid, true) as Observable<IFactsRequestInfo>,
            this.ifactService.getRequestDetail(res.slurryid)
          ]);
        }
        return forkJoin([of(null), of(res), of(null), of(null)]);
      })
    ).subscribe(([preparedData, res, newRequestInfo, newRequestDetail]) => {
      const newSlurry = newRequestInfo && newRequestInfo.availableSlurries ? newRequestInfo.availableSlurries.find(x => x.id === res.slurryid) : null;
      if (newSlurry) {
        this.messageService.clear();
        if (!preparedData.mixProcedureIsNull) {
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `Request ID: ${res.requestid}, Slurry Number: ${res.slurryid} has been copied successfully.` });
        } else {
          this.messageService.add({
            life: environment.messagePopupLifetimeMs,
            severity: 'info', detail: `Request ID: ${res.requestid}, Slurry Number: ${res.slurryid} has been copied successfully. <br/>
          Mixing procedure not available for any material in the slurry has been set to "PB" for this request. Please choose the appropriate value for them in iFacts.` });
        }
        const fluid = new FluidModel();
        fluid.requestId = res.requestid;
        fluid.name = res.trademarkName;
        fluid.slurryNo = newSlurry.number;
        fluid.slurryId = newSlurry.id.toString();
        fluid.slurrySource = SlurrySource.LinkedFluid;
        fluid.slurryTypeId = null;
        fluid.slurryType = null;
        fluid.testType = newRequestInfo.type;

        const iFactsSlurryType: ISlurryType =
          this.fluidService.getSlurryType(fluid, newRequestDetail, this.vidaSlurryTypes);

        if (iFactsSlurryType) {

          fluid.slurryTypeId = iFactsSlurryType.id;
          fluid.slurryType = iFactsSlurryType.name;
        }

        this.dynamicSidebarRef.close(fluid);
      } else {
        this.showErrorMessage(res);
      }
    });
  }

  private handleInvalidRequestId() {
    this.errorMessage = 'Invalid Request ID. Please try again.';
    this.requests = [];
  }

  prepareCopyRequestData(sourceRequest: IFactsRequest, fluid: FluidModel, iFactData) {
    const jobMapping = { ...this.data.job } as Job;
    const { holeSize, casingSize, rigId } = iFactData;
    // #region HEADER
    // const rig = jobMapping.rigName ? this.rigs.find(x => x.RigName === jobMapping.rigName) : null;
    const well = jobMapping.wellName ? this.wells.find(x => x.WellName.toLowerCase().trim() === jobMapping.wellName.toLowerCase()) : null;
    const sourceRequestType = sourceRequest.type ? this.requestTypes.find(x => x.label === sourceRequest.type) : null;
    const fluidRequestType = fluid.testType ? this.requestTypes.find(x => x.label === fluid.testType) : null;
    let requestType = null;

    if (fluidRequestType) {
      requestType = fluidRequestType;
    } else if (sourceRequestType) {
      requestType = sourceRequestType;
    }

    if (!this.isTransferChecked) {
      jobMapping.bhct = null;
      jobMapping.bhst = null;
    }

    const header: any = {
      EngineerUserId: this.sessionService.user.prefUserName,
      CasingSize: casingSize,
      HoleSize: holeSize,
      SalesOrders: this.data.job.salesOrderNumber,
      RequestStatus: '2',
      LabId: this.labId,
      UsedFor: this.usedFor,
      JobTypeId: sourceRequest.jobTypeId,
      CustomerId: this.customerId,
      RigId: rigId,
      WellId: well ? well.WellId : null,
      HeaderComments: sourceRequest.comments,
      RequestType: requestType ? requestType.value : null,
      CreatedByApp: 'VIDA',
      SideTrackId: sourceRequest.sideTrackId,
      PlantCode: '',
      LocationId: '',
      TrademarkId: sourceRequest.trademarkId
    };

    // #endregion

    // #region SLURRY

    const sourceSlurryType = this.slurryTypes.find(x => x.SlurryType === sourceRequest.selectedSlurry.type);
    const fluidSlurryType = this.slurryTypes
      .find(x => x.SlurryType.toLowerCase() === this.standardizeiFactSlurryName(fluid.slurryType.toLowerCase(), 'cement'))
    let slurryType = null;

    if (fluidSlurryType) {
      slurryType = fluidSlurryType;
    }else if(sourceSlurryType){
      slurryType = sourceSlurryType;
    }

    const preparedFluid = this.prepareFluids(sourceRequest, fluid);
    const slurryInfo = {
      BlendID: sourceRequest.selectedSlurry.blendId ? sourceRequest.selectedSlurry.blendId : null,
      Density: sourceRequest.selectedSlurry.sg,
      OldSlurryId: this.data.slurryId,
      RequestId: null,
      FoamBlendVol: sourceRequest.selectedSlurry.foamBlendVolume,
      FoamDensity: sourceRequest.selectedSlurry.foamDensity,
      IsFoam: sourceRequest.selectedSlurry.isFoam ? 'Y' : 'N',
      MixVolume: sourceRequest.selectedSlurry.mixVolume,
      SlurryComments: sourceRequest.selectedSlurry.comments,
      SlurryTypeId: slurryType ? slurryType.SlurryTypeId : null,
      UsedOnJob: 'N',
      Additives: preparedFluid.additives,
      CementBlend: preparedFluid.cementBlend,
      Tests: [],
    };
    // #endregion

    // #region TEST CONDITION
    const testCondition = {
      BHCT: jobMapping?.bhct || sourceRequest.testCondition?.bhct,
      BHST: jobMapping?.bhst || sourceRequest.testCondition?.bhst,
      MD: jobMapping?.originalJobMd || sourceRequest.testCondition?.md,
      TVD: jobMapping?.originalJobTvd || sourceRequest.testCondition?.tvd,
      Pressure: jobMapping?.bhp || sourceRequest.testCondition?.pressure,
      BatchMixing: sourceRequest.testCondition?.batchMixing,
      HeatingTime: sourceRequest.testCondition?.heatingTime,
      MudDensity: this.unitConversionService.convertFromSiToApi(sourceRequest.testCondition?.mudDensity, UnitType.Density),
      MudSupplierId: sourceRequest.testCondition?.mudSupplierId,
      MudSupplierName: sourceRequest.testCondition?.mudSupplierName,
      MudTradeId: sourceRequest.testCondition?.mudTradeId,
      MudTradeName: sourceRequest.testCondition?.mudTradeName,
    };
    // #endregion

    const submitData: any = {
      Header: header,
      Slurry: slurryInfo,
      TestCondition: testCondition,
      isChangeMixProcedure: preparedFluid.mixProcedureIsNull
    };

    return submitData;
  }

  private getLocalStoragePreviousLabId() {
    const prevCustomerText = localStorage.getItem(`CopyIfactRequest.${this.sessionService.user.prefUserName}.PreviousLabId`);
    const prevCustomerId = prevCustomerText ? toNumber(prevCustomerText) : null;
    return prevCustomerId;
  }

  private prepareFluids(sourceRequest: IFactsRequest, fluid: FluidModel) {
    const mapMaterialToIfact = (fluid, index) => {
      const isSupplemental = fluid.materialType === 'Supplemental';
      fluid.concentrationUnit = isSupplemental ? standardConcentrationUnit(fluid.concentrationUnit || fluid.fluidUnitMeasureName) : fluid.concentrationUnit;
      return {
        BulkDensity: fluid.bulkDensity || '',
        Concentration: fluid.concentration,
        ConcentrationUnit: fluid.concentrationUnit,
        MaterialId: isSupplemental ? fluid.materialId : fluid.generalMaterialId,
        MaterialTypeId: isSupplemental ? 6 : fluid.typeId,
        MixingProcedure: fluid.mixingProcedure || 'PH',
        Order: fluid.order || index + 1,
        SG: fluid.sg || null,
        SampleId: null,
        TestAmount: fluid.testAmount || null,
        WeightMaterial: fluid.weightMaterial === 'Y' ? true : false,
      };
    };
    const additives = sourceRequest.selectedSlurry.materials
      .filter(fluid => !this.materialService.isCementIFacts(fluid) && !this.materialService.isSupplemental(fluid) || +fluid.typeId === 8); // or Order != 0 ++ 8 => spacer

    const additivesAndSupplemental = additives.map((x, index) => mapMaterialToIfact(x, index));
    if (fluid.supplementalMaterial) {
      fluid.supplementalMaterial.map((x, index) => mapMaterialToIfact(x, index)).forEach((sm) => {
        additivesAndSupplemental.push(sm);
      });
    }

    const ifactMapping = new IFactMapping(this.materialService);

    const cementBlend = {
      ...ifactMapping.buildCementBlend(fluid),
      ...ifactMapping.buildBlendComponents(fluid.fluidMaterial, fluid.sackWeight)
    };
    const mixProcedureIsNull = sourceRequest.selectedSlurry.materials
      .filter(material => !this.materialService.isCement(material) && !material.mixingProcedure).length;
    return {
      additives: additivesAndSupplemental,
      cementBlend: cementBlend,
      mixProcedureIsNull
    };
  }

  prepareCreateIfactData = (job: Job, metaData, fluid) => {
    const ifactMapping = new IFactMapping(this.materialService);
    return this.ifactService.getIFactDataBaseOnVIDA(job, metaData, fluid.id).pipe(
      map(jobMapped => {
        return ifactMapping.mappingJobToIFact(jobMapped, fluid, metaData);
      }),
      mergeMap(mappedData => {
        return this.ifactService.createRequestWithGenMat(mappedData);
      })
    );
  }

  submitCreateRequest = () => {
    // authorize user
    if (!this.isValidUser) {
      this.showMessage(false, `User is an invalid iFacts user of the selected lab for the new request.`);
      return;
    }

    if (this.data.job) {
      const jobMapping = { ...this.data.job };
      if (!this.isTransferChecked) {
        jobMapping.bhct = null;
        jobMapping.bhst = null;
      }
      const sideBarData = {
        areaId: this.selectedAreaId,
        usedFor: this.usedFor,
        labId: this.labId,
        engineerUserId: this.sessionService.user.prefUserName,
        requestType: '6', // Investigation
        customerId: this.customerId,
        density: this.data.density,
        slurryType: this.slurryTypes
          .find(x => x.SlurryType.toLowerCase() === this.standardizeiFactSlurryName(this.data.slurryTypeLabel.toLowerCase(), 'cement'))
      };

      this.prepareCreateIfactData(jobMapping, sideBarData, this.data.fluid)
        .subscribe((result: any) => {
          if (result && result.status) {
            const fluid = new FluidModel();
            fluid.id = this.data.fluid.id;
            fluid.requestId = result.requestid;
            fluid.slurryNo = 1;
            fluid.slurryId = result.slurryid;
            fluid.slurrySource = SlurrySource.LinkedFluid;

            // mapping sap;
            this.fluidService.moveSupplementalsToAdditives(fluid)
              .pipe(
                switchMap(() => {
                  return this.pumpScheduleService.clearActualQty(fluid.id);
                })
              ).subscribe(() => {
                this.applicationStateService.createIFactsRequestCompleted$.next({ result, index: this.data.index });
              });

            window.open(`${environment.iFactsUrl}/Request/GetRequest?requestId=${result.requestid}`, '_blank');
            this.showMessage(true, '');
            this.dynamicSidebarRef.close(fluid);
          } else {
            this.showErrorMessage(result);
            this.applicationStateService.createIFactsRequestCompleted$.next(null);
          }
        }, err => {
          console.error(err);
          this.applicationStateService.createIFactsRequestCompleted$.next(null);
        }
        );
    }
  }

  private showMessage(value: boolean, labLabel: string): void {
    if (value) {
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `Create iFacts Request successfully.` });
    } else {
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: labLabel });
    }
  }

  private standardizeiFactSlurryName(slurryName: string, removeStr: string): string {
    if (slurryName === null || slurryName.length <= 0) return '';

    return slurryName.replace(removeStr, '').trim();
  }

  private showErrorMessage(result) {
    const message = result.errormessage ? result.errormessage.replace('Error: ', '') : `Sorry! An error has occurred, please try again.`;
    this.showMessage(false, message);
  }
}
