import { Component } from '@angular/core';

@Component({
  selector: 'more-info-tooltip',
  templateUrl: './more-info-tooltip.component.html',
  styleUrls: ['./more-info-tooltip.component.scss']
})
export class MoreInfoTooltipComponent {

  constructor() { }

  moreInfoOnClick(componentRef: any, event: any) {
    componentRef.toggle(event);
    event.stopPropagation();
  }
}
