import { Time } from '@angular/common';
import { Component, Input, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimeZoneConverterHelper } from '../../helpers';

@Component({
  selector: 'datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimePickerComponent),
    multi: true,
  }]
})
export class DatetimePickerComponent implements ControlValueAccessor, OnChanges {
  @Input() format: any;
  @Input() value: Date;
  @Input() applyTimeZone: boolean = false;
  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() canRemove: boolean;
  @Input() showAlert: boolean = false;
  @Input() showTime: boolean = false;
  @Input() hourFormat: number = 24;
  @Input() maxDate: Date;
  @Input() isMinimal: false;
  @Input() defaultTime: Time = { hours: 0, minutes: 0 }
  @Input() disabled: boolean = false;
  // eslint-disable-next-line
  @Output() change = new EventEmitter<Date>();
  // eslint-disable-next-line
  @Output() select = new EventEmitter<Date>();
  // eslint-disable-next-line
  @Output() onRemove = new EventEmitter();
  // eslint-disable-next-line
  @Output() onClear = new EventEmitter();
  // eslint-disable-next-line
  @Output() onBlur = new EventEmitter();

  datetimeFormat: string = 'M-dd-yy';

  /**
   * The method set in registerOnChange, it is just a placeholder for a method that takes one parameter,
   * We use it to emit changes back to the form
   */
  private propagateChange: any = () => { };

  // this is the initial value set to the component
  writeValue(date: string) {
    if (this.format) {
      this.datetimeFormat = this.convertFormat(this.format);
    }
    date ? this.value = this.getDate(date) : this.value = null;
  }

  private convertFormat(format: string): string {
    // ref: https://www.primefaces.org/primeng-6.1.6/#/calendar. http://www.csharp-examples.net/string-format-datetime/
    return format
      .replace('MMM', 'M')
      .replace('yyyy', 'LONG_YEAR')
      .replace('yy', 'y')
      .replace('LONG_YEAR', 'yy');
  }

  getDate(date: string) {
    return this.applyTimeZone ? TimeZoneConverterHelper(date) : new Date(date);
  }

  /**
   * Registers 'fn' that will be fired when changes are made
   * This is how we emit the changes back to the form
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // not used, used for touch input
  registerOnTouched() { }

  /**
   * This function is called when the control status changes to or from "DISABLED".
   * Depending on the value, it will enable or disable the appropriate DOM element.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _onRemove() {
    if (this.canRemove) {
      this.onRemove.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled } = changes;
    if (disabled){
      this.setDisabledState(disabled.currentValue);
    }
  }
  // change events from the PrimeNG p-calendar
  onChange(event) {
    // let date = event;

    // // remove timezone
    // if (event instanceof Date && !this.applyTimeZone) {
    //   // date = new Date((new Date(event.toString() + 'Z')).toUTCString());
    //   // date = new Date(event.toGMTString()).toUTCString();
    //   const time = (new Date(event.getFullYear(), event.getMonth(), event.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())).getTime() + event.getTimezoneOffset() * 60 * 1000;
    //   date.setTime(time);
    // }

    // this.propagateChange(date);
    // this.change.emit(date);

    let date = null;
    if (event instanceof Date) {
      date = event;
    }

    if (date === null && event.currentTarget && Date.parse(event.currentTarget.value)) {
      date = new Date(event.currentTarget.value);
    }

    if (!this.showTime && date) {
      date?.setHours(this.defaultTime.hours, this.defaultTime.minutes, 0, 0);
    }

    const dateString = date ? date.toString().substring(0, date.toString().indexOf('GMT') - 1) : '';

    this.propagateChange(dateString);
    this.change.emit(dateString);

  }

  onClearClick() {
    this.onClear.emit();
  }

  _onBlur() {
    if (!this.value) {
      return;
    }
    const date = this.value.toString().substring(0, this.value.toString().indexOf('GMT') - 1);
    this.propagateChange(date);
    this.onBlur.emit(date);
  }

  onSelect(event: any) {
    this.onChange(event);
    let date = event.toString().substring(0, event.toString().indexOf('GMT') - 1);
    if (date === '' && typeof(event) === 'object' && event.currentTarget && Date.parse(event.currentTarget.value)){
      event = new Date(event.currentTarget.value);
      date = event.toString().substring(0, event.toString().indexOf('GMT') - 1);
    }
    this.propagateChange(date);
    this.select.emit(date);
  }
}
