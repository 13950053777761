<div class="sidebar-content-wrapper" [loadingIndicator]="loadingSubscription">
    <div class="sidebar-header">
        <h1 class="title">Job Log File</h1>
    </div>
    <div class="sidebar-content">

        <div class="file-upload">
            <job-uploader #uploader [listFiles]="fileList" [jobId]="jobId"
                          (uploadCancelEmitter)="uploadCancel()" (uploadSuccessEmitter)="uploadSuccess($event)"
                          (uploadInprogressEmitter)="uploadInprogress($event)" [destinationFolderPath]="destinationFolderPath"
                          [header]="'File and Description'" [title]="'Select a Job Log file to import.'"
                          [drapDropDescription]="'or Drag and Drop Job Log File Here'" [isEnableDescriptionInput]="true"
                          [vidaFileType]="vidaFileType" [badgeNumber]=true [displayMode]="'Vertical'">
            </job-uploader>
        </div>
        <div class="files-list m-top-2">
            <span _ngcontent-c10="" class="badge-number">2</span> <label>Select Source</label>
            <div class="files-list-subtitle">Select a Job Log file as source.</div>
            <br>

            <div class="files-list-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="source-file-cell">Source File</th>
                            <th class="file-name-cell">File Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p-radioButton name="file" [value]="null" [(ngModel)]="lastUsedFile"></p-radioButton>
                            </td>
                            <td>No Job Log File</td>
                        </tr>
                        <tr *ngFor="let file of fileList">
                            <td>
                                <p-radioButton name="file" [value]="file" [(ngModel)]="lastUsedFile"></p-radioButton>
                            </td>
                            <td style="position: relative;">
                                <span [pTooltip]="file.description" tooltipZIndex="99990000" appendTo="body" tooltipPosition="top"
                                      tooltipStyleClass="file-description-tooltip">{{file.getFileName()}}</span>
                                <p class="full-width-input">{{ file.description }}</p>
                            </td>
                        </tr>
                        <tr *ngIf="errorMessage">
                            <td colspan="2"><span style="margin-left: 15px;">{{errorMessage}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="sidebar-footer">
        <a class="btn lnk-cancel" (click)="cancel()">Cancel</a>
        <button type="button" class="btn primary-btn" (click)="save()" [disabled]="disableSaveButton">SAVE</button>
    </div>
</div>
