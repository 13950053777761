import { Time } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DATE_TIME_CALL_STANDARD, UnitType } from 'libs/constants';
import { environment } from 'libs/environment';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { FillFullSOwithZeros, ISaleOrderNumberModel, ISalesOrderLineItemBomType, JobHDFInfo, SaleOrderNumberModel, WellModel } from 'libs/models';
import { ApplicationStateService, CustomerService, EventHubService, HdfService, HttpCommanderService, JobsService, SaleOrderNumberService, UserSettingService } from 'libs/shared/services';
import { ToolTipService } from 'libs/shared/services/tool-tip.service';
import { ConfirmDialogService, JobModalManagementService } from 'libs/ui';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { MessageService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import {concat, forkJoin, merge, Observable, of, Subject, Subscription, timer} from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { IUserSettingModel } from '../../../application/models';
import { BhctRiskLevelService } from '../../../control-point/services/bhctRiskLevelService.service';
import { JobClassificationState } from '../../../edit-job/components';
import { Controlpoint4SectionValidateMessage, ControlPointState, ControlPointType } from '../../constant';
import { PDropdownDataPumpScheduleHelper } from '../../helpers';
import { ICustomerModel } from '../../models';
import { JobProjectedDateComponent } from '../job-projected-date/job-projected-date.component';
import { ModalUpdateSOComponent } from '../modal-update-so/modal-update-so.component';
import { JobForm } from '../../models/job-form';
import { isDateFuture } from 'libs/helpers/job-helper';
import * as moment from 'moment-mini'

const USA_COUNTRY = 'USA';

@AutoUnsubscribe()
@Component({
  selector: 'gs-job-meta-data',
  templateUrl: './job-meta-data.component.html',
  styleUrls: ['./job-meta-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobMetaDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() collapsed = true;
  @Input() jobId: any;
  @Input() job: any;
  @Input() jobForm: JobForm;
  @Input() cpOptions?: any = null;
  @Input() requiredFields = [];
  @Input() controlpointState: ControlPointState;
  @Input() openEditJob: false;
  @Input() controlpointType: ControlPointType;
  @Input() countries: any;
  @Input() states: any;
  @Input() rigLocationTypes: any;

  @ViewChild('billing_info_panel') billing_info_panel;
  @ViewChild('jobProjectedDate') jobProjectedDate: JobProjectedDateComponent;
  @ViewChild('actualDateToolTip') actualDateToolTip: OverlayPanel;
  @ViewChild('actualDateMsg') actualDateMsg: OverlayPanel;
  @ViewChild('soToolTip') soToolTip: OverlayPanel;

  public dateprojected: string;
  public projectedDate: string;
  today = moment().endOf('day').toDate()
  isShowEditJob = false;
  isSaveVisible = false;
  statusProjectedDate = false;
  // isOverdue = false;
  jobList: any;
  unitType: number = 1;
  riskLevels = PDropdownDataPumpScheduleHelper('name', 'id', 'disabled',
    [{ name: '1', id: 1, disabled: true }, { name: '2', id: 2 }, { name: '3', id: 3 }, { name: '4', id: 4 }, { name: '5', id: 5 }]);
  // TODO: refactor to interface unitOfDistance
  unitOfDistance: string;
  unitOfSD: string;
  UnitType = UnitType;
  private destroy$ = new Subject();
  private subscription = new Subscription();
  private _bomTypesLoadingSubscription: Subscription;
  headerDataForm: UntypedFormGroup;
  userSettings: IUserSettingModel;
  readonly validSaleOrderLength: number = 10;
  isActualDateValid: boolean = true;
  isActualDateRequired: boolean = false;
  proposalNumberHDFLink: string;
  salesOrderNumberHDFLink: string;
  linkToProposalHdf = environment.linkToProposalHDF;
  linkToHDFWithoutProposal = environment.linkToHDFWithoutProposal;
  loadingSubscription: Subscription;
  currentSONumer: string = '';
  currebtCeSoNumber: string = '';
  apiSaleOrders: ISaleOrderNumberModel[] = [];
  soCustomer: ICustomerModel;
  isUpdateSOModal: boolean = false;
  _jobState: JobClassificationState;
  actulJobDateDefaultTime: Time = { hours: 23, minutes: 59 };
  previousSelectedCountry: string;
  optionRemedialWorkRequired: any; //US 527227
  optionsRemdialWorkRequired = [
    { label: 'Unknown', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]; //US 527227
  private _currentCeSalesOrderNumber: string;
  readonly DATE_TIME_CALL_STANDARD: string = DATE_TIME_CALL_STANDARD;

  salesOrderBomTypes: ISalesOrderLineItemBomType[] = [];
  salesOrderBomTypesSelectItems: SelectItem<ISalesOrderLineItemBomType>[] = [];
  salesOrderBomTypesBlocked: boolean = false;
  jobType: string;
  salesOrderNumberKeyFilter: RegExp = /[\d\n\r]/;

  invalidSalesOrderMessage = 'Invalid Sales Order: Enter valid Sales Order number.';
  public isCeSalesOrderVisible$: Observable<boolean>;

  private _salesOrderBomTypesUpdated: boolean = false;
  isIcemUpdated = false;

  get needUpdateCEFromPrimarySO(): boolean {
    return this.job.cmtSvc && this.job.ceSvc && this._ceSoIsPrimarySo || !this.job.cmtSvc;
  }

  constructor(
    private unitConversionService: UnitConversionService,
    private eventHub: EventHubService,
    private appState: ApplicationStateService,
    private jobModalManagementService: JobModalManagementService,
    private customerService: CustomerService,
    public saleOrderNumberService: SaleOrderNumberService,
    private fb: UntypedFormBuilder,
    private jobService: JobsService,
    private userSettingService: UserSettingService,
    public messageService: MessageService,
    private confirmDialogService: ConfirmDialogService,
    private hdfService: HdfService,
    private cd: ChangeDetectorRef,
    private bhctRiskLevelService: BhctRiskLevelService,
    private dialogService: DialogService,
    private httpCommanderService: HttpCommanderService,
    private _tooltipService: ToolTipService,
    viewContainerRef: ViewContainerRef
  ) {

    this._tooltipService.viewContainerRef = viewContainerRef;
  }

  private get _ceSoIsPrimarySo(): boolean {

    return this.headerDataForm.controls.ceSoIsPrimarySo.value;
  }

  public get ceSalesOrderNumberPlaceholder(): string {

    return this._ceSoIsPrimarySo ? '' : 'Enter CE Sales Order Number';
  }

  public get isSONumberCleared(): boolean {
    return this.saleOrderNumberService.isSONumberCleared$.value;
  }

  public get soExplanationRequired(): boolean {

    const selectedBomType: ISalesOrderLineItemBomType =
      this.headerDataForm.controls.salesOrderLineItemBomType.value;

    if (!selectedBomType) {
      return false;
    }

    return this._isNonCementingSOAllowed() && !selectedBomType.isCementing;
  }

  get isCountryUSA() {
    return this.headerDataForm.controls.jobCountryId.value === USA_COUNTRY;
  }

  get isLand() {
    return this.job.isLand;
  }

  get isEditJobDisabled() {
    return this.controlpointState === ControlPointState.Submitted || this.controlpointState === ControlPointState.Approved;
  }

  get countryStateIdControl() {
    return this.headerDataForm.controls.countryStateId;
  }

  get rigLocationTypeIdControl() {
    return this.headerDataForm.controls.rigLocationTypeId;
  }

  ngOnInit() {
    // this.isDateValid = true;
    this.saleOrderNumberService.isSONumberCleared$.next(false);

    this.jobService.sharedisIcemJobUpdate?.subscribe(isIcem => this.isIcemUpdated = isIcem);
    if (this.job.originalJobId != null) {
        this.riskLevels[0].disabled = false;
    }
    this.convertUnit();
    this.eventHub.updateWell$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((well: WellModel) => {
      this.job.wellName = well.wellName;
      this.cd.markForCheck();
    });

    this.subscription.add(
      merge(
        this.appState.jobCreated$,
        this.jobModalManagementService.$saveSuccess)
      .pipe(
        withLatestFrom(this.appState.projectedDate$)
        // eslint-disable-next-line
      ).subscribe(([_, data]) => {
        this.headerDataForm.get('projectedDate')?.setValue(data);
      })
    );

    this.eventHub.onSoInvalidInCp4$.next(this.headerDataForm.controls.salesOrderNumber);
    this.userSettings = this.userSettingService.userSettings;
    this.prepareHeaderFormData();
    this.getStatusProjectedDate();
    this.proposalNumberHDFLink = this.job.proposalNumber ? this.job.proposalNumber.padStart(10, '0') : null;
    this.salesOrderNumberHDFLink = this.job.salesOrderNumber ? this.job.salesOrderNumber.padStart(10,'0') : null;

    if (this.job.originalJobId) {
      this.enabledRiskLevlOne();
    }
    this.subscription.add(this.saleOrderNumberService.updatedSalesOrder$?.subscribe(x => {
      const isJobEditOpened = this.appState.jobEditOpened$.value;
      if (isJobEditOpened) {
        this.updateControlDataWithSO(x);
      }
    }));
    this.subscription.add(this.saleOrderNumberService.updateFromSalesOrder$?.subscribe(x => {
      const isJobEditOpened = this.appState.jobEditOpened$.value;
      if (isJobEditOpened) {
        return;
      }

      if (!x.isSaleOrderUpdateConfirmed) {
        this.headerDataForm.controls.salesOrderNumber.setValue(x.model.saleDocumentId, {onlySelf:true, emitEvent: false});
        this.currentSONumer = x.model.saleDocumentId;
        return;
      }

      if (x.model.wellId && this.job.wellId !== x.model.wellId) {
        this.loadingSubscription = this.httpCommanderService.moveJobToOtherWell(this.job.id, this.job.wellId, x.model.wellId).subscribe({
          next: () => {
            this.onUpdateFromSalesOrder(x.model, x.isSaleOrderUpdateConfirmed),
              this.job.wellId = x.model.wellId;
          },
          error: () => this.messageService.add({ severity: 'error', detail: `Update failed.` })
        });
      } else {
        this.onUpdateFromSalesOrder(x.model, x.isSaleOrderUpdateConfirmed);
      }
    }));

      const selectedBomType = this._getSalesOrdelLineItemBomType();
      if (selectedBomType) {
        this.headerDataForm.controls.salesOrderLineItemBomType.setValue(selectedBomType);
        this.cd.markForCheck();
      }

    this.appState.actualDate$.subscribe(data => {
      if (this.headerDataForm && !this.headerDataForm.controls.actualDate.dirty) {
        this.headerDataForm.controls.actualDate.setValue(data);
        this.cd.markForCheck();
      }
    });

    this.isCeSalesOrderVisible$ = concat(
      of(this.job.cmtSvc && this.job.ceSvc),
      this.appState.jobClassification$
      .pipe(
        map(c => {
          return c.cmtSvc && c.ceSvc;
        })
      )
    );

    this.subscription.add(
      this.headerDataForm.controls.ceSoIsPrimarySo?.valueChanges.subscribe(value => {

        if (!this.job.cmtSvc || !this.job.ceSvc) {
          return;
        }

        const silent = {
          onlySelf: true,
          emitEvent: false
        };

        this.headerDataForm.controls.ceSoIsPrimarySo.setValue(value, silent);

        if (value) {

          this._currentCeSalesOrderNumber = this.headerDataForm.controls.salesOrderNumber.value;
          this.headerDataForm.controls.ceSalesOrderNumber.disable(silent);

        } else {

          this._currentCeSalesOrderNumber = null;
          this.headerDataForm.controls.ceSalesOrderNumber.enable(silent);
        }

        if (this._currentCeSalesOrderNumber) {
          this.updateCeSalesOrderNumber(this._currentCeSalesOrderNumber);
        }

        if (!this.headerDataForm.controls.ceSoIsPrimarySo.dirty) {

          // update triggered by form patch after initializing by backend data
          return;
        }

        this.headerDataForm.controls.ceSalesOrderNumber.setValue(
          this._currentCeSalesOrderNumber,
          silent
        );

        // update Sales Order fields in Job structure because they might be read in Job Edit view if that will be open later
        this.job.ceSalesOrderNumber = this._currentCeSalesOrderNumber;
        this.job.ceSoIsPrimarySo = value;
      })
    );

    this.subscription.add(
      this.headerDataForm.controls.allowNonCementingSalesOrder?.valueChanges.subscribe(allow => {

        this._enableNonCementingBomTypes(allow);
        this._setNonCementingSalesOrderCommentState(allow);
      })
    );

    this._jobState = {
      cmtSvc: this.job.cmtSvc,
      ceSvc: this.job.ceSvc,
      ceDirectSales: this.job.ceDirectSales
    }

    this.subscription.add(
      this.appState.jobClassification$.subscribe((jobState) => {
        this._jobState = jobState;
        this.simpleSOValidation();
      })
    );
  }

  private createExistingSOdata(): SaleOrderNumberModel {

    let bomTypePartNumber: string = '';
    let bomTypeDescription: string = '';

    if (this.job.bomType) {

        bomTypePartNumber = this.job.bomTypeNumber;
        bomTypeDescription = this.job.bomTypeDescription;
    }

    return new SaleOrderNumberModel(
      this.headerDataForm.controls.customerId.value,
      this.job.customerName,
      this.job.shipToNumber,
      this.job.shipToLocation,
      this.job.wellId,
      this.job.wellName,
      this.job.wellNumber,
      this.job.wellApiUwi,
      !this.job.ceDirectSales,
      this.job.bomType,
      bomTypePartNumber,
      bomTypeDescription,
      this.job.accountRepresentative,
      this.job.salesOrderNumber,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this._bomTypesLoadingSubscription) {
      this._bomTypesLoadingSubscription.unsubscribe();
    }
  }

  showUpdateSOModal(
    existingData: ISaleOrderNumberModel,
    newData: ISaleOrderNumberModel,
    newSalesOrderBomTypes: ISalesOrderLineItemBomType[]) {

    if (newSalesOrderBomTypes.length === 0) {
      newData.bomTypePartNumber = null;
      newData.bomTypeDescription = null;
    } else if (newSalesOrderBomTypes.length === 1) {

      const newSalesOrderBomType = newSalesOrderBomTypes[0];

      newData.bomTypePartNumber = newSalesOrderBomType.partNumber;
      newData.bomTypeDescription = newSalesOrderBomType.bomType;
    } else {

      // multiple BOM Types
      newData.bomTypePartNumber = '';
      newData.bomTypeDescription = '';
    }

    const ref = this.dialogService.open(ModalUpdateSOComponent, {
      header: 'Confirm Sales Order Update',
      width: '900px',
      style: { "margin": "10px" },
      baseZIndex: 100000000,
      data: { existingData: existingData, newData: newData },
    });


    ref.onClose.subscribe((res) => {
      if (res) {
        this.headerDataForm.controls.salesOrderNumber.setErrors(null);

      }

      this.isUpdateSOModal = false;
    });
  }

  getStatusProjectedDate() {
    if (this.job.actualDate == null && this.job.isJobDateAlert) {
      this.statusProjectedDate = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if(this.job.ceDirectSales) this.jobType = "ceDirectSales";
    if (this._bomTypesLoadingSubscription) {
      this._bomTypesLoadingSubscription.unsubscribe();
    }

    this.convertUnit();
    const { job, openEditJob } = changes;

    if (openEditJob && openEditJob.currentValue) {
      this.billing_info_panel.hide();
    }

    if (job && job.currentValue) {

      this.prepareHeaderFormData();
      if (this.headerDataForm) {

        if (this.job.salesOrderNumber !== this.headerDataForm.controls.salesOrderNumber.value) {
          // possible when sales order number changed on Job Edit view.

          // allow click on BOM Types dropdown trigger read of SO BOM Types again.
          this._salesOrderBomTypesUpdated = false;
        }

        const selectedBomType = this._getSalesOrdelLineItemBomType();

        if(this._jobState.ceDirectSales) {
          if(!selectedBomType || !this.job.salesOrderNumber) {
            if(selectedBomType) {
              this.headerDataForm.patchValue({
                salesOrderLineItemBomType: selectedBomType,
                isBOMTypeRequired: true
              });
            } else if(this.job.salesOrderNumber) {
              this.headerDataForm.patchValue({
                salesOrderNumber: this.job.salesOrderNumber
              });
            }
          }
          this.headerDataForm.patchValue({
            riskLevel: this.job.riskLevel,
            customerId: this.job.customer,
            ceSalesOrderNumber: this.job.ceSalesOrderNumber,
            ceSoIsPrimarySo: this.job.ceSoIsPrimarySo,
            shipToLocation: this.job.shipToLocation,
            shipToNumber: this.job.shipToNumber,
            projectedDate: this.job.projectedDate,
            jobMd: this.job.jobMd,
            actualJobMD: this.job.actualJobMd,
            actualDate: this.job.actualDate,
            isManualActualDate: this.job.isManualActualDate,
            hdfJobId: this.job.hdfJobId,
            extractedSoFlag: !!this.job.extractedSoFlag,
            jobCountryId: this.job.jobCountryId,
            countryStateId: this.job.countryStateId,
            rigLocationTypeId: this.job.rigLocationTypeId
          }, {
            onlySelf: true,
            emitEvent: false
          });
        } else {
          this.headerDataForm.patchValue({
            riskLevel: this.job.riskLevel,
            customerId: this.job.customer,
            salesOrderNumber: this.job.salesOrderNumber,
            salesOrderLineItemBomType: selectedBomType,
            ceSalesOrderNumber: this.job.ceSalesOrderNumber,
            ceSoIsPrimarySo: this.job.ceSoIsPrimarySo,
            shipToLocation: this.job.shipToLocation,
            shipToNumber: this.job.shipToNumber,
            projectedDate: this.job.projectedDate,
            jobMd: this.job.jobMd,
            actualJobMD: this.job.actualJobMd,
            actualDate: this.job.actualDate,
            isManualActualDate: this.job.isManualActualDate,
            hdfJobId: this.job.hdfJobId,
            extractedSoFlag: !!this.job.extractedSoFlag,
            jobCountryId: this.job.jobCountryId,
            countryStateId: this.job.countryStateId,
            rigLocationTypeId: this.job.rigLocationTypeId
          }, {
            onlySelf: true,
            emitEvent: false
          });
        }

        (this.job.ceSoIsPrimarySo || !this.job.canEdit)
         ? this.headerDataForm.get('ceSalesOrderNumber').disable({onlySelf: true, emitEvent: false})
         : this.headerDataForm.get('ceSalesOrderNumber').enable({onlySelf: true, emitEvent: false});

        if (!!this.job.actualDate || !this.job.canEdit)
          this.headerDataForm.controls.projectedDate.disable();
        else
          this.headerDataForm.controls.projectedDate.enable();
      }

      if(this.isIcemUpdated && (!job.previousValue.ceSvc && job.currentValue.ceSvc)) {

        this.messageService.add({
          life: environment.messagePopupLifetimeMs,
          severity: 'success',
          summary: 'Casing Equipment classification',
          detail: 'iCem file includes Casing Equipment. Job Classification changed to include CE Svc',
        });
      }
      this.proposalNumberHDFLink = this.job.proposalNumber ? this.job.proposalNumber.padStart(10, '0') : null;
      this.salesOrderNumberHDFLink = this.job.salesOrderNumber ? this.job.salesOrderNumber.padStart(10,'0') : null;
      this.getActualDate();
    }
  }

  riskLevelChange(event) {
    const newRiskLevel = +event.value;
    if (this.cpOptions.type === 1 && newRiskLevel === 1) {
      this.bhctRiskLevelService.sendRiskValue(newRiskLevel);
    }
  }

  onUpdateFromSalesOrder(model: ISaleOrderNumberModel, isSaleOrderUpdateConfirmed: boolean) {
    if (model) {
      model.salesOrderNumber = this.currentSONumer;
      model.controlpointType = +this.controlpointType;
      if (model.shipToLocation && model.shipToLocation.startsWith(',')) {
        model.shipToLocation = model.shipToLocation.substring(1);
      }

      this._salesOrderBomTypesUpdated = true;

      this.salesOrderBomTypesSelectItems = this.salesOrderBomTypes
        .map(bt => this._bomTypeToSelectItem(bt));

      this.headerDataForm.markAsPristine();
      this.loadingSubscription = timer(0, 1000).subscribe(_ => _);
      this.cd.markForCheck();
      forkJoin([
        of(this.needUpdateCEFromPrimarySO).pipe(switchMap(needUpdate => !needUpdate ? of(false)
        : this.saleOrderNumberService.updateCasingEquipment(this.job, model.salesOrderNumber, isSaleOrderUpdateConfirmed))),
        this.jobService.updateFromSalesOrder(this.jobId, model)
        // eslint-disable-next-line
      ]).subscribe(([_, x]) => {

        this.updateControlDataWithSO(model);

        this.cd.markForCheck();
        this.loadingSubscription.unsubscribe();
        this.cd.markForCheck();
        this.messageService.clear();
        if (x) {
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `Job has been updated successfully.` });
          // this.jobModalManagementService.$saveSuccess.next(true); //Fix Bug 403525
        } else {

          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `Update failed.` });
        }
      }, () => {
        this.loadingSubscription.unsubscribe();
        this.cd.markForCheck();
        // TODO: this is a temporary error handler which does nothing. Fix an error 500 on server instead which occures when changing SO on CP4.
      });
    }
  }

  private updateControlDataWithSO(model: ISaleOrderNumberModel) {
    this.job.salesOrderNumber = model.saleDocumentId;
    this.headerDataForm.controls.salesOrderNumber.setValue(model.saleDocumentId, {onlySelf:true, emitEvent: false});
    this.job.customerName = model.customerName;
    this.job.customer = model.customerId;
    this.job.shipToNumber = model.shipTo;
    this.job.bomTypePartNumber = model.bomTypePartNumber;
    let shipToLocation =  model.shipToLocation ? model.shipToLocation.trim() : null;
    if (shipToLocation && shipToLocation.startsWith(','))
    {
      shipToLocation = shipToLocation.substring(1);
    }
    this.job.shipToLocation = shipToLocation;
    this.job.accountRepresentative = {...model.accountRepresentative};

    const selectableBomTypes = this.salesOrderBomTypes
      .filter(bt => bt.isCementing || this._isNonCementingSOAllowed());

    // Update BOM Type in dropdown from selectable sales order BOM Types
    if (selectableBomTypes.length === 1) {

      // if only 1 BOM Type in Sales order, then set this as a default.
      this.headerDataForm.controls.salesOrderLineItemBomType.setValue(
        selectableBomTypes[0], {
        onlySelf: true,
        emitEvent: false
      });
    } else {

      this.headerDataForm.controls.salesOrderLineItemBomType.setValue(
        null, {
        onlySelf: true,
        emitEvent: false
      });
    }

    this.headerDataForm.controls.shipToNumber.setValue(model.shipTo);
    this.headerDataForm.controls.shipToLocation.setValue(model.shipToLocation);

    if (model.wellNumber) {
        this.job.wellNumber = model.wellNumber;
    }
    if (model.wellApiUwi) {
        this.job.wellApiUwi = model.wellApiUwi;
    }
    if (model.wellId) {
        this.job.wellName = model.wellName;
    }
  }

  onProjectedDateChange(event) {
    if (event.type == 'select') {
      return;
    }
    this.messageService.clear();
    if (this.headerDataForm && this.headerDataForm.controls.actualDate.value != null) {
      this.jobService.updateActualDate(this.jobId, event).subscribe(() => {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Actual Date has been updated successfully.` });
      });
    } else {
      this.jobService.updateProjectedDate(this.jobId, event).subscribe(() => {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Projected Date has been updated successfully.` });
      });
    }
  }

  onDeleteProjectedDate() {
    this.confirmDialogService.show({
      message: `Are you sure to delete Projected Date?`,
      header: 'Message',
      accept: () => {
        this.messageService.clear();
        if (this.job.actualDate != null) {
          this.jobService.updateActualDate(this.jobId, null).subscribe(() => {
            this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Projected Date has been deleted successfully.` });
            this.headerDataForm.controls.actualDate.setValue(null);
            this.headerDataForm.controls.isManualActualDate.setValue(false);
            this.job.actualDate = null;
            this.job.isManualActualDate = false;
            this.cd.markForCheck();
          });
        }
      },
      reject: () => {

      }
    });

  }

  onActualDateClear() {
    this.isActualDateValid = true;
    this.headerDataForm.controls.actualDate.setValue('');
    this.headerDataForm.controls.isManualActualDate?.setValue(false);
  }

  prepareHeaderFormData() {
    if (!this.headerDataForm) {

      const ceSoIsPrimarySo = !this.job.cmtSvc || !this.job.ceSvc || this.job.ceSoIsPrimarySo;

      this.headerDataForm = this.fb.group({
        salesOrderNumber: {
          value: this.job ? this.job.salesOrderNumber : null,
          disabled: !this.job.canEdit
        },
        salesOrderLineItemBomType: {
          value: null,
          disabled: !this.job.canEdit
        },
        ceSalesOrderNumber: {
          value: this.job ? (this.job.cmtSvc && this.job.ceSvc && !this.job.ceSoIsPrimarySo ? this.job.ceSalesOrderNumber : this.job.salesOrderNumber) : null,
          disabled: !this.job.canEdit || ceSoIsPrimarySo
        },
        ceSoIsPrimarySo: [{
          value: ceSoIsPrimarySo,
          disabled: !this.job.canEdit
        }],
        allowNonCementingSalesOrder: [{
          value: this.job.allowNonCementingSalesOrder,
          disabled: !this.job.canEdit
        }],
        nonCementingSalesOrderComment: [{
            value: this.job.nonCementingSalesOrderComment,
            disabled: !this.job.canEdit || !this.job.allowNonCementingSalesOrder
        }],
        riskLevel: [this.job ? this.job.riskLevel : null],
        shipToNumber: [this.job ? this.job.shipToNumber : null],
        shipToLocation: [this.job ? this.job.shipToLocation : null],
        customerId: [this.job ? this.job.customer : null],
        projectedDate: this.job ? new UntypedFormControl({ value: this.job.projectedDate, disabled: !!this.job.projectedDate || !this.job.canEdit }) : null,
        jobMd: this.job ? new UntypedFormControl({ value: this.job.jobMd, disabled: !!this.job.jobMd || !this.job.canEdit }) : null,
        actualDate: this.job ? new UntypedFormControl({ value: this.job.actualDate, disabled: !this.job.canEdit }) : null,
        isManualActualDate: this.job ? new UntypedFormControl({ value: this.job.isManualActualDate, disabled: !this.job.canEdit }) : false,
        actualJobMD: this.job ? new UntypedFormControl({ value: this.job.actualJobMd, disabled: !!this.job.actualJobMd || !this.job.canEdit }) : null,
        hdfJobId: [this.job ? this.job.hdfJobId : null],
        extractedSoFlag: [this.job ? this.job.extractedSoFlag : false],
        plantCode: [this.job ? this.job.plantCode : null],
        jobCountryId: [this.job ? this.job.jobCountryId : null, [Validators.required]],
        countryStateId: [this.job ? this.job.countryStateId : null, [Validators.required]],
        rigLocationTypeId: [this.job ? this.job.rigLocationTypeId : null, [Validators.required]],
        isRemedialWorkRequired: [{
          value: this.job.isRemedialWorkRequired,
          disabled: !(this.job.jobStatusDescription === 'Active' ||  this.job.jobStatusDescription === 'Completed' || this.job.jobStatusDescription === 'Closed')
        }],
        remedialWorkRequiredComments: [{
          value: this.job.remedialWorkRequiredComments,
          disabled: !(this.job.jobStatusDescription === 'Active' || this.job.jobStatusDescription === 'Completed' || this.job.jobStatusDescription === 'Closed')
        }]
      });

      this.currentSONumer = this.job ? this.job.salesOrderNumber : '';

      this.subscription.add(this.headerDataForm.controls.riskLevel.valueChanges.subscribe(riskLevel => {
        this.appState.riskLevelChange$.next(riskLevel);
      }));

      this.dateprojected = this.headerDataForm.controls.projectedDate.value;
      this.subscription.add(
        this.headerDataForm.controls.projectedDate.valueChanges.subscribe(projectedDate => {
          this.dateprojected = projectedDate;
          this.appState.jobProjectedDateChange$.next(projectedDate);
        })
      );

      this.subscription.add(
        this.headerDataForm.controls.actualDate.valueChanges.subscribe(actualDate => {
          this.appState.jobActualDateChange$.next(actualDate);
        })
      );

      this._jobState = {
        ceDirectSales: this.job.ceDirectSales,
        ceSvc: this.job.ceSvc,
        cmtSvc: this.job.cmtSvc
      };
    }

    const controlOptions = {
      emitEvent: false,
      onlySelf: true
    };

    if (!this.job.canEdit || this.controlpointState === ControlPointState.Submitted || this.controlpointState === ControlPointState.Approved) {
      this.headerDataForm.disable(controlOptions);
    } else if (!this.headerDataForm.enabled) {
        this.headerDataForm.enable();
    }

    //US 527227
    this.headerDataForm.controls['isRemedialWorkRequired'].setValue(this.job.isRemedialWorkRequired);
    this.headerDataForm.controls['remedialWorkRequiredComments'].setValue(this.job.remedialWorkRequiredComments);

    this.getCountryData();
  }

  getFormData() {

    const formValue = this.headerDataForm.getRawValue();

    if (!formValue.riskLevel) {
       formValue.riskLevel = this.job.riskLevel;
    }

    if (!formValue.isRemedialWorkRequired) {
      formValue.isRemedialWorkRequired = this.job.isRemedialWorkRequired;
   }

   if (formValue.remedialWorkRequiredComments == undefined) {
    formValue.remedialWorkRequiredComments = this.job.remedialWorkRequiredComments;
    }

    formValue.cmtSvc = this.job.cmtSvc;
    formValue.ceSvc = this.job.ceSvc;
    formValue.ceDirectSales = this.job.ceDirectSales;

    if (!formValue.rigLocationTypeId) {
      formValue.rigLocationTypeId = this.headerDataForm.controls.rigLocationTypeId.value;
    }

    if (!formValue.ceSalesOrderNumber &&
      (!formValue.cmtSvc || !formValue.ceSvc || formValue.ceSoIsPrimarySo)) {

      // CE Sales Order Number can be different from Sales Order Number
      // only if job clssifies as both Cementing Services and Casing Equioment Services.
      formValue.ceSalesOrderNumber = formValue.salesOrderNumber;
    }

    // Flatten BOM type dropdown value.
    formValue.salesOrderLineItem = null;
    formValue.bomTypeId = null;
    formValue.bomTypePartNumber = null;
    formValue.bomTypeDescription = null;
    formValue.profitCenter = null;

    const salesOrderLineItemBomType: ISalesOrderLineItemBomType = this.headerDataForm.controls.salesOrderLineItemBomType?.value;
    if (salesOrderLineItemBomType) {
      formValue.salesOrderLineItem = salesOrderLineItemBomType.lineItem;
      formValue.bomTypeId = salesOrderLineItemBomType.bomTypeId;
      formValue.bomTypePartNumber = salesOrderLineItemBomType.partNumber;
      formValue.bomTypeDescription = salesOrderLineItemBomType.bomType;
      formValue.profitCenter = salesOrderLineItemBomType.profitCenter;
    }

    if (isDateFuture(formValue.actualDate)) {
      formValue.actualDate = '';
      this.onActualDateClear();
    }

    if (this.controlpointType === ControlPointType.ControlPoint1 || this.controlpointType === ControlPointType.ControlPoint2) {
      const { projectedDate } = this.jobForm.jobDateFormGroup.getRawValue();
      formValue.projectedDate = projectedDate;
    }

    return formValue;
  }

  convertUnit() {
    this.unitOfDistance = this.unitConversionService.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === UnitType.Distance).unitMeasure.name;
    this.unitOfSD = this.unitConversionService.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === UnitType.SmallDistance).unitMeasure.name;
  }

  checkNumber(data): boolean {
    return typeof data === 'number';
  }

  checkValidate(): boolean {
    return ((+this.job.holeSizeValue !== 0) && (+this.job.casingSizeValue !== 0));
  }

  public onChange(event: Date) {
    this.messageService.clear();
    this.jobService.updateProjectedDate(this.jobId, event).subscribe(() => {
      this.job.projectedDate = event;
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The projected date has been updated successfully.` });
    });
    // this.checkIsOverdue();
  }

  onActualDateChange(date: Date) {
    if (typeof(date) == 'object') {
      this.isActualDateValid = true;
      return;
    }
    // Check invalid date in future

    this.isActualDateValid = !isDateFuture(date);
    // Hide header errors message for CP4
    const isValidActualDate = this.checkValidActualDate();
    this.appState.actualDateState$.next(isValidActualDate && !this.isActualDateRequired);

    this.headerDataForm.controls.isManualActualDate?.setValue(true);
    this.isActualDateRequired = !date && Number(this.controlpointType) === 4;
  }

  onActualDateChangeCP3(date: Date): void {
    if (date) {
      this.messageService.clear();
      const newActualDateInMs = new Date(date).getTime();
      const jobActualDateInMs = new Date(this.job.actualDate).getTime();

      if (this.headerDataForm && this.headerDataForm.controls.actualDate.value != null && newActualDateInMs !== jobActualDateInMs) {
        this.jobService.updateActualDate(this.jobId, date).subscribe(() => {
          this.job.actualDate = new Date(date).toISOString(); // this will help us to compare previous date
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Actual Date has been updated successfully.` });
        });
      }
    }
  }

  onActualDateClearCP3(): void {
    this.messageService.clear();
    if (this.job.actualDate != null) {
      this.jobService.updateActualDate(this.jobId, null).subscribe(() => {
        this.job.actualDate = null; // this will help us to compare previous date
        this.headerDataForm.controls.actualDate.setValue(null);
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Actual Date has been deleted successfully.` });
      });
    }
  }

  private getActualDate() {
    this.dateprojected = this.job.actualDate ? this.job.actualDate : this.job.projectedDate;
    // this.checkIsOverdue();
  }

  public updateDate(value) {
    this.jobService.updateDate(this.jobId, value).subscribe();
  }

  projectDateChanged(event: any) {
    this.headerDataForm.controls.projectedDate.setValue(event);
    this.job.projectedDate = event;
  }

  checkSalesOrder() {

    const saleOrder = this.headerDataForm.get('salesOrderNumber').value;

    const validation$ =
        this.saleOrderNumberService.validateSalesOrderNumber(saleOrder, this.job.customer, this._jobState)?.pipe(
            tap(result => {
              if (!result.isValid) {
                this.headerDataForm.controls.salesOrderNumber.setErrors({ validSaleOrderNumber: result.errMessage });
                this.headerDataForm.controls.salesOrderNumber.markAsTouched();
              }
            })
      );

    return validation$;
  }

  checkSalesOrderAndBom() {

    const saleOrder = this.headerDataForm.get('salesOrderNumber').value;

    if(saleOrder) {
      return true;
    } else {
      this.headerDataForm.controls.salesOrderNumber.setErrors({ validSaleOrderNumber: this.invalidSalesOrderMessage });
      this.headerDataForm.controls.salesOrderNumber.markAsTouched();
      return false;
    }
  }

  public checkBomTypeSelected(): boolean {
    if (this._jobState.ceDirectSales) {
      return true;
    }
    const selectedBomType: ISalesOrderLineItemBomType = this.headerDataForm.get('salesOrderLineItemBomType').value;
    return !!selectedBomType;
  }

  public checkNonCementingSoExplained(): boolean {

    const nonCementingSoComment: string = this.headerDataForm.get('nonCementingSalesOrderComment').value;
    return !this.soExplanationRequired || !!nonCementingSoComment;
  }

  checkCeSalesOrder() {

    if (!this.job.cmtSvc || !this.job.ceSvc) {
      return of({isValid: true, errMessage: ''});
    }

    const ceSalesOrder = this.headerDataForm.get('ceSalesOrderNumber').value;

    const validation$ = this.saleOrderNumberService.validateSalesOrderNumber(ceSalesOrder, this.job.customer, this._jobState)
      .pipe(
        tap(result => {

          if (!result.isValid) {
            this.headerDataForm.controls.ceSalesOrderNumber.setErrors({ validSaleOrderNumber: result.errMessage });
            this.headerDataForm.controls.ceSalesOrderNumber.markAsTouched();
          }
        }));

    return validation$;
  }

  public checkBomTypeValid(): Observable<boolean> {
    const selectedBomType: ISalesOrderLineItemBomType = this.headerDataForm.get('salesOrderLineItemBomType').value;

    if (this.salesOrderBomTypes.length !== 0) {
      const valid = this._validateBomType(this.salesOrderBomTypes, selectedBomType);
      return of(valid);
    }

    const salesOrderNumber = this.headerDataForm.get('salesOrderNumber').value;

    if (!salesOrderNumber) {

      // If no Sales Order, then no BOM Type. There is a separate required validation for empty BOM Type.
      return of(true);
    }

    return this.saleOrderNumberService.getSaleOrderNumber(salesOrderNumber)
      .pipe(
        map((res) => {
          if (res && res.length > 0) {
            //check for Product Sale (ZOR) SO Document type
            if (this._jobState.ceDirectSales) {
              return true;
            }

            const bomSalesOrderItems = res.filter(x => x.bomTypeId != null);
            const salesOrderBomTypes = bomSalesOrderItems
              .sort((so1, so2) => this._bomTypeSortFunc(so1, so2))
              .map(so => this._soToLineItemBomType(so));

            return this._validateBomType(salesOrderBomTypes, selectedBomType);
          }
        }));
  }

  checkEmptyActualDate() {
    return this.isActualDateRequired;
  }

  checkValidActualDate() {
    return this.isActualDateValid;
  }

  updateRequiredFields(required: boolean) {
    this.isActualDateRequired = required && !this.headerDataForm.controls.actualDate.value;
    if (required) {
      if(this.cpOptions == 4 || (this.cpOptions == 2 && this.job.ceDirectSales == true)) {
        if (!this.headerDataForm.controls.salesOrderNumber.value) {
          this.headerDataForm.controls.salesOrderNumber.setErrors({ required: 'Sales Order required' });
          this.headerDataForm.controls.salesOrderNumber.markAsTouched();
        }
      }

      if(this.cpOptions == 4 || (this.cpOptions == 2 && this.job.ceDirectSales == true)) {
        if (!this.headerDataForm.controls.salesOrderLineItemBomType.value) {
          this.headerDataForm.controls.salesOrderLineItemBomType.setErrors({ required: 'BOM Type required' });
          this.headerDataForm.controls.salesOrderLineItemBomType.markAsTouched();
        }
      }

      if (this.soExplanationRequired && !this.headerDataForm.controls.nonCementingSalesOrderComment.value) {

        this.headerDataForm.controls.nonCementingSalesOrderComment.setErrors({ required: 'SO Explanation required'});
        this.headerDataForm.controls.nonCementingSalesOrderComment.markAsTouched();
      }

      if(this.cpOptions.type !== 2) {
        if (this.job.ceSvc && !this.headerDataForm.controls.ceSalesOrderNumber.value) {
          this.headerDataForm.controls.ceSalesOrderNumber.setErrors({ required: 'CE Sales Order required' });
          this.headerDataForm.controls.ceSalesOrderNumber.markAsTouched();
        }

      }
    } else {
      const salesOrderControlErrors = this.headerDataForm.controls.salesOrderNumber.errors;
      if (salesOrderControlErrors) {

        delete salesOrderControlErrors.required;
      }

      const bomTypeControlErrors = this.headerDataForm.controls.salesOrderLineItemBomType.errors;
      if (bomTypeControlErrors) {

        delete bomTypeControlErrors.required;
      }

      const soExplanationErrors = this.headerDataForm.controls.nonCementingSalesOrderComment.errors;
      if (soExplanationErrors) {

        delete soExplanationErrors.required;
      }

      const ceSalesOrderErrors = this.headerDataForm.controls.ceSalesOrderNumber.errors;
      if (ceSalesOrderErrors) {

        delete ceSalesOrderErrors.required;
      }
    }
    this.cd.markForCheck();
  }

  confirmFromModal(jobOrigin) {
    if (jobOrigin) {
      this.job.originalJobId = jobOrigin.jobId;
      this.job.originalJobCode = jobOrigin.jobCode;
      this.job.originalJobName = jobOrigin.jobName;
      this.job.originalJobCp1Approved = jobOrigin.cp1IsApproved;
      this.enabledRiskLevlOne();
    }
    else {
      this.job.originalJobId = null;
      this.job.originalJobCode = null;
      this.job.originalJobName = null;
      this.job.originalJobCp1Approved = null;
    }
  }

  enabledRiskLevlOne() {
    if (this.riskLevels[0].disabled) {
      this.riskLevels[0].disabled = false;
    }
  }

  extractSo() {
    const proposalNumber = this.job.proposalNumber.trim().padStart(10, '0');
    this.hdfService.getExcutionByProposalNumberAndHDFJobId(proposalNumber, this.job.hdfJobId)
      .subscribe((data: JobHDFInfo) => {
        const hdfExecutionJobSO = data && data.salesOrderSection.length ? data.salesOrderSection.shift() : null;
        if (!hdfExecutionJobSO) {
          this.showMsgExtractSO(false);
          return;
        }

        this.currentSONumer = '';
        this.headerDataForm.controls.salesOrderNumber.markAsTouched();
        this.headerDataForm.controls.salesOrderNumber.setValue(hdfExecutionJobSO.salesOrder);
        this.headerDataForm.controls.hdfJobId.setValue(data.jobId);
        this.headerDataForm.controls.extractedSoFlag.setValue(true);
        this.headerDataForm.controls.salesOrderNumber.updateValueAndValidity();


        this.showMsgExtractSO(true);
      });
  }

  showMsgExtractSO(isLinkSuccess) {
    this.messageService.clear();
    if (isLinkSuccess) {
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'VIDA is now linked to the execution job.' });
    } else {
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'info', detail: 'Sales Order not found in HDF.' });
    }
  }

  autoFillSalesOrderNumber(ceSalesOrder: boolean = false): void {

    const control = ceSalesOrder
                    ? this.headerDataForm.controls.ceSalesOrderNumber
                    : this.headerDataForm.controls.salesOrderNumber;

    const zeroPaddedValue = FillFullSOwithZeros(control.value);
    if (zeroPaddedValue !== control.value) {
      this.currentSONumer = control.value;
    }
    control.setValue(zeroPaddedValue);

    if (ceSalesOrder) {
      this.validateCeSalesOrderNumber();
    } else {
      if (this._ceSoIsPrimarySo) {
        this.headerDataForm.controls.ceSalesOrderNumber.setValue(zeroPaddedValue);
      }

      this.saleOrderNumberService.validateSalesOrderNumber(control.value, this.job.customer, this._jobState)
        .subscribe(salesOrder => {
          this.appState.soCP4Validate$.next(salesOrder?.isValid);
        });
      this.validateSalesOrderNumber();
    }
  }

  setEmptySalesOrder(withErrors: boolean = false) {
    this.job.salesOrderNumber = null;
    this.soCustomer = null;
    this.currentSONumer = '';
    this.isUpdateSOModal = false;
    this._clearBomType();

    if (withErrors) {
      this.headerDataForm.controls.salesOrderNumber.setErrors(
        { validSaleOrderNumber: this.invalidSalesOrderMessage });
    }

    if (this._ceSoIsPrimarySo) {
      this._currentCeSalesOrderNumber = '';
      this.job.ceSalesOrderNumber = null;
      this._currentCeSalesOrderNumber = this.job.ceSalesOrderNumber;

      this.headerDataForm.controls.ceSalesOrderNumber.setValue(
        this.job.ceSalesOrderNumber, {onlySelf: true, emitEvent: false});
    }
    this.cd.markForCheck();
  }

  validateSalesOrderNumber() {

    if (this.isUpdateSOModal) {
      return;
    }

    const validateSONumber = this.headerDataForm.controls.salesOrderNumber.value;

    if (!validateSONumber && this.currentSONumer) {
      if (this.needUpdateCEFromPrimarySO) {
        this.clearCasingEquipmentFromSalesOrder();
      }
    }

    if (!validateSONumber) {
      this.setEmptySalesOrder();
      return;
    }

    if (validateSONumber !== this.currentSONumer) {
      this.currentSONumer = validateSONumber;
      this.isUpdateSOModal = true;
      this.loadingSubscription = this.saleOrderNumberService.getSaleOrderNumber(validateSONumber)
        .pipe(
          catchError(err => {
            this.setEmptySalesOrder(true);
            return throwError(err);
          }),
        )
        .subscribe((saleOrders: ISaleOrderNumberModel[]) => {
          const ctrlSONumber: string = this.headerDataForm.controls.salesOrderNumber.value;
          if (ctrlSONumber != validateSONumber)
            return;

          const bomSalesOrderItems = saleOrders.filter(x => x.bomTypeId != null);
          const isBOMTypeRequired = saleOrders.length == 0 || saleOrders[0].isBOMTypeRequired;

          if (!this._jobState.ceDirectSales || isBOMTypeRequired){
            saleOrders = bomSalesOrderItems;
          }

          if (saleOrders.length === 0) {
            this.setEmptySalesOrder(true);
          }

          if (saleOrders.length > 0) {
            this.apiSaleOrders = bomSalesOrderItems;
            const soCustomerId = saleOrders[0].customerId;
            let soCustomer: ICustomerModel = null;

            this.salesOrderBomTypes = bomSalesOrderItems
              .sort((so1, so2) => this._bomTypeSortFunc(so1, so2))
              .map(so => this._soToLineItemBomType(so));

            const selectableBomTypes = this.salesOrderBomTypes
              .filter(bt => bt.isCementing || this._isNonCementingSOAllowed());

            this._updateProfitCenter();

            this.customerService.getCustomerById(soCustomerId).subscribe((res: ICustomerModel) => {
              soCustomer = res;
              this.soCustomer = res;

              if (saleOrders[0].customerId !== this.headerDataForm.controls.customerId.value) {

                this._setSoCutomerMismatchError(this.headerDataForm.controls.salesOrderNumber, res.customerName, res.policyId, false);

                if (this._ceSoIsPrimarySo) {
                  this._setSoCutomerMismatchError(this.headerDataForm.controls.ceSalesOrderNumber, res.customerName, res.policyId);
                }
              }
              saleOrders[0].customerName = soCustomer.customerName;
              saleOrders[0].salesOrderNumber = this.currentSONumer;
              saleOrders[0].isBOMTypeRequired = isBOMTypeRequired;
              this.showUpdateSOModal(this.createExistingSOdata(), {...saleOrders[0]}, selectableBomTypes);
            })
          }
        });

    } else {
      this.isUpdateSOModal = false;
      this.simpleSOValidation();
    }
  }

  updateCeSalesOrderNumber(val) {
    this.loadingSubscription = this.saleOrderNumberService.getSaleOrderNumber(val)
      .pipe(
        catchError(err => {
          this.headerDataForm.controls.ceSalesOrderNumber.setErrors({ noresponse: true });
          return throwError(err);
        }),
      )
    .subscribe((salesOrders: ISaleOrderNumberModel[]) => {
      const bomSalesOrderItems = salesOrders ? salesOrders.filter(x => x.bomTypeId != null) : [];
      const isBOMTypeRequired = !salesOrders || salesOrders.length == 0 || salesOrders[0].isBOMTypeRequired;
      if (!this._jobState.ceDirectSales || isBOMTypeRequired){
        salesOrders = bomSalesOrderItems;
      }

      if (salesOrders.length > 0) {

        const soCustomerId = salesOrders[0].customerId;

        this.customerService.getCustomerById(soCustomerId).subscribe((res: ICustomerModel) => {

          if (res.policyId !== this.headerDataForm.controls.customerId.value) {

            this._setSoCutomerMismatchError(this.headerDataForm.controls.ceSalesOrderNumber, res.customerName, res.policyId);
          }

        });
      } else if (val) {
        this.headerDataForm.controls.ceSalesOrderNumber.setErrors({ validSaleOrderNumber: this.invalidSalesOrderMessage });
      }
      this.saleOrderNumberService.updateCasingEquipment(this.job, val, true).subscribe();
    });
  }

  validateCeSalesOrderNumber() {

    const val = this.headerDataForm.controls.ceSalesOrderNumber.value;

    if (val === this._currentCeSalesOrderNumber) {
      return;
    }

    if (!val && this._currentCeSalesOrderNumber) {
      this.clearCasingEquipmentFromSalesOrder();
    }

    this._currentCeSalesOrderNumber = val;

    if (this.headerDataForm.controls.ceSalesOrderNumber.invalid) {
      return;
    }

    if (!val) {

      this.headerDataForm.controls.ceSalesOrderNumber.setErrors(null);
      return;
    }

    if (!this.headerDataForm.controls.ceSalesOrderNumber.dirty) {
      return;
    }

    this.loadingSubscription = this.saleOrderNumberService.getSaleOrderNumber(val)
      .pipe(
        catchError(err => {
          this.headerDataForm.controls.ceSalesOrderNumber.setErrors({ noresponse: true });
          return throwError(err);
        }),
      )
    .subscribe((salesOrders: ISaleOrderNumberModel[]) => {
      const bomSalesOrderItems = salesOrders ? salesOrders.filter(x => x.bomTypeId != null) : [];
      const isBOMTypeRequired = !salesOrders || salesOrders.length == 0 || salesOrders[0].isBOMTypeRequired;
      if (!this._jobState.ceDirectSales || isBOMTypeRequired){
        salesOrders = bomSalesOrderItems;
      }

      if (salesOrders.length === 0) {

        this.headerDataForm.controls.ceSalesOrderNumber.setErrors({ validSaleOrderNumber: this.invalidSalesOrderMessage });
        return;
      }

      if (salesOrders.length > 0) {

        const soCustomerId = salesOrders[0].customerId;

        this.customerService.getCustomerById(soCustomerId).subscribe((res: ICustomerModel) => {

          if (res.policyId !== this.headerDataForm.controls.customerId.value) {

            this._setSoCutomerMismatchError(this.headerDataForm.controls.ceSalesOrderNumber, res.customerName, res.policyId);
          }

          this.saleOrderNumberService.updateCasingEquipment(this.job, val, true).subscribe();
        });
      }
    });
  }

  simpleSOValidation() {

    const validateSONumber = this.headerDataForm.controls.salesOrderNumber.value;
    const customerId = this.job.customer;
    this.currentSONumer = validateSONumber;
    if (!validateSONumber) {
      return;
    }

    this.saleOrderNumberService.validateSalesOrderNumber(validateSONumber, customerId, this._jobState).subscribe(
      (error: any) => {
        if (error.isValid) {
          this.headerDataForm.controls.salesOrderNumber.setErrors(null);
        }
        else{
          this.headerDataForm.controls.salesOrderNumber.setErrors({validSaleOrderNumber: error.errMessage });
        }
        this.cd.markForCheck();
    });
  }

  public refreshSalesOrderBomTypes(): void {

    const currentSalesOrder = this.headerDataForm.controls.salesOrderNumber.value;
    if (!this.job.salesOrderNumber || !currentSalesOrder || this._salesOrderBomTypesUpdated
    ) {

      return;
    }

    // To prevent reading BOM types after each time dropdown is clicked.
    this._salesOrderBomTypesUpdated = true;
    this.salesOrderBomTypesBlocked = true;
    this._bomTypesLoadingSubscription = this.saleOrderNumberService.getSaleOrderNumber(currentSalesOrder)
      .subscribe((res: ISaleOrderNumberModel[]) => {
        const bomSalesOrderItems = res.filter(x => x.bomTypeId != null);

        if (res) {
          this.salesOrderBomTypesBlocked = false;
          if (bomSalesOrderItems.length > 0) {
            this.salesOrderBomTypes = bomSalesOrderItems
              .sort((so1, so2) => this._bomTypeSortFunc(so1, so2))
              .map(so => this._soToLineItemBomType(so));

            this.salesOrderBomTypesSelectItems = this.salesOrderBomTypes
              .map(bt => this._bomTypeToSelectItem(bt));
          }
          else {
            this.salesOrderBomTypes = [];
            this.salesOrderBomTypesSelectItems = [];
          }
          this.cd.markForCheck();
        }
      });
  }

  public showBomTypeHint(event): void {
    if (this._tooltipService.isShown) {
      this._tooltipService.close();
      return;
    }

    this._tooltipService.show({ htmlText: 'Item# - BOM Type (Part Number)', left: event.currentTarget.offsetLeft, top: event.currentTarget.offsetTop });
  }

  public onBomTypeChange():void {
    this.appState.bomTypeChanged$.next(null);
  }

  onCountryChange(event: any) {
    if (event.value !== USA_COUNTRY && this.previousSelectedCountry === USA_COUNTRY) {
      this.setValidators();
      this.countryStateIdControl.setValue(null);
      this.rigLocationTypeIdControl.setValue(null);
    }
    else if (event.value === USA_COUNTRY ) {
      this.setValidators(true);
      this.countryStateIdControl.updateValueAndValidity();
      this.countryStateIdControl.markAllAsTouched();

      if (this.isLand) {
        this.rigLocationTypeIdControl.updateValueAndValidity();
        this.rigLocationTypeIdControl.markAllAsTouched();
      }
    }

    this.previousSelectedCountry = event.value;
  }

  getStateName() {
    return this.states?.find(x => x.value === this.headerDataForm.controls.countryStateId.value)?.label;
  }

  getRigLocationTypeName() {
    return this.rigLocationTypes?.find(x => x.value === this.headerDataForm.controls.rigLocationTypeId.value)?.label;
  }

  getCountryName() {
    return this.countries?.find(x => x.value === this.headerDataForm.controls.jobCountryId.value)?.label;
  }

  public updateFlagRemedialWorkRequired()
  {
    const result = this.headerDataForm.controls.isRemedialWorkRequired.value;
    this.job.isRemedialWorkRequired = result;
  }


  private _getSalesOrdelLineItemBomType(): ISalesOrderLineItemBomType {

    let salesOrderChanged = false;

    const currentSalesOrder = this.headerDataForm.controls.salesOrderNumber.value;

    if (this.job.salesOrderNumber !== currentSalesOrder) {
      // possible when sales order number changed on Job Edit view.

      salesOrderChanged = true;

      // clear SO BOM Types, because different SO may have different BOM Types.
      this.salesOrderBomTypesSelectItems = [];
      this._salesOrderBomTypesUpdated = false;
    }

    if (this.salesOrderBomTypesSelectItems.some(si => si.value.bomTypeId !== this.job.bomType)) {
      // current BOM Type dropdown does not contain job BOM Type

      this.salesOrderBomTypesSelectItems = [];
      this._salesOrderBomTypesUpdated = false;
    }

    if (!this.job.salesOrderNumber || !this.job.bomType || !this.job.salesOrderLineItem) {

      return null;
    }

    if (this.salesOrderBomTypes.length === 0 || salesOrderChanged) {

      const savedValue = {
        lineItem: this.job.salesOrderLineItem,
        bomTypeId: this.job.bomType,
        bomType: this.job.bomTypeDescription,
        partNumber: this.job.bomTypeNumber,
        isCementing: this.job.isCementingBomType,
        profitCenter: this.job.profitCenter
      };

      const selectItem = this._bomTypeToSelectItem(savedValue);
      this.salesOrderBomTypesSelectItems = [selectItem];
    } else {

      this.salesOrderBomTypesSelectItems = this.salesOrderBomTypes.map(bt => this._bomTypeToSelectItem(bt));
    }

    const selectedSalesOrderLineItemBomType = this.salesOrderBomTypesSelectItems.find(bt =>
      bt.value.lineItem === this.job.salesOrderLineItem
      && bt.value.partNumber === this.job.bomTypeNumber
    );

    return selectedSalesOrderLineItemBomType.value;
  }

  private _bomTypeSortFunc(so1: ISaleOrderNumberModel, so2: ISaleOrderNumberModel): number {

    if (so1.isCementingBomType && !so2.isCementingBomType) {

      return -1;
    }

    if (!so1.isCementingBomType && so2.isCementingBomType) {

      return 1;
    }

    return Number(so1.lineItem) - Number(so2.lineItem);
  }

  private _soToLineItemBomType(so: ISaleOrderNumberModel): ISalesOrderLineItemBomType {
    const itemValue: ISalesOrderLineItemBomType = {
      lineItem: so.lineItem,
      bomTypeId: so.bomTypeId,
      bomType: so.bomTypeDescription || 'Other',
      partNumber: so.bomTypePartNumber,
      isCementing: so.isCementingBomType,
      profitCenter: so.profitCenter
    };

    return itemValue;
  }

  private _bomTypeToSelectItem(itemValue: ISalesOrderLineItemBomType): SelectItem<ISalesOrderLineItemBomType> {

    const label = `${itemValue.lineItem.replace(/^0*/, '')} - ${itemValue.bomType} (${itemValue.partNumber.replace(/^0*/, '')})`;

    return {
      value: itemValue,
      label: label,
      disabled: !itemValue.isCementing && !this._isNonCementingSOAllowed()
    };
  }

  private _isNonCementingSOAllowed(): boolean {

    return !!this.headerDataForm.controls.allowNonCementingSalesOrder.value;
  }

  private _clearBomType(): void {

    this.headerDataForm.controls.salesOrderLineItemBomType?.reset(
      null, {
      onlySelf: true,
      emitEvent: false
    });

    this.salesOrderBomTypesSelectItems = [];
  }

  private _validateBomType(
    salesOrderBomTypes: ISalesOrderLineItemBomType[],
    selectedBomType: ISalesOrderLineItemBomType
  ): boolean {

    const valid = !selectedBomType || salesOrderBomTypes
      .filter(bt => bt.isCementing || this._isNonCementingSOAllowed())
      .some(bt => bt.lineItem === selectedBomType.lineItem
        && bt.partNumber === selectedBomType.partNumber
        && bt.profitCenter === selectedBomType.profitCenter);

    if (!valid) {
      this.headerDataForm.controls.salesOrderLineItemBomType.setErrors({
        invalidBomType: Controlpoint4SectionValidateMessage.BomTypeValid
      });

      // After BOM Type validation force reading valid BOM Type values from SAP again.
      this._salesOrderBomTypesUpdated = false;

      this.cd.markForCheck();
    }

    return valid;
  }

  private _enableNonCementingBomTypes(enable: boolean): void {

    this.job.allowNonCementingSalesOrder = enable;

    this.salesOrderBomTypesSelectItems.forEach(i => {
      if (!i.value.isCementing) {
        i.disabled = !enable;
      }
    });

    if (!enable) {

      // clear BOM Type dropdown if non-cementing SO are not allowed and selected value is non-cementing
      const selectedBomTypeItem: ISalesOrderLineItemBomType = this.headerDataForm.controls.salesOrderLineItemBomType.value;
      if (!!selectedBomTypeItem && !selectedBomTypeItem.isCementing)

        this.headerDataForm.controls.salesOrderLineItemBomType.setValue(
          null, {
            onlySelf: true,
            emitEvent: false
          }
        );

        this.appState.bomTypeChanged$.next(null);
    }
  }

  private _updateProfitCenter(): void {

    // Profit Center field is invisible for user and should not participate in validation
    // of consistency of SO and BOM Type (for user).
    // At the same time we need correct Profit Center from SO.
    // If user decides to keep existing data with new SO
    // and selected BOM Type in existing SO is the same as in new SO,
    // but profit centers are different, then we will save incorrect Profit Center for SO
    // Here we substitute Profit Center for the same Line Item and BOM Type.

    this.salesOrderBomTypesSelectItems
      .map(si => si.value)
      .forEach(existing => {
        const foundInNewOrder = this.salesOrderBomTypes.find(updated =>
          existing.lineItem === updated.lineItem && existing.partNumber === updated.partNumber
        );

        if (foundInNewOrder) {
          existing.profitCenter = foundInNewOrder.profitCenter;
        }
      });
  }

  private _setSoCutomerMismatchError(control: AbstractControl, customerName: string, customerId: string, ceSo: boolean = true): void {

    let messagePrefix = `Invalid Sales Order:`;

    if (ceSo) {
      messagePrefix = `Invalid CE Sales Order:`;
    }

    control.setErrors({
      customerMismatch: `${messagePrefix} SAP Customer Name ${customerName} (${customerId}) does not match VIDA Customer Name.`
    });
  }

  private _setNonCementingSalesOrderCommentState(enable: boolean): void {

    const silent = {
      onlySelf: true,
      emitEvent: false
    };

    if (enable) {

      this.headerDataForm.controls.nonCementingSalesOrderComment.enable(silent);

    } else {

      this.headerDataForm.controls.nonCementingSalesOrderComment.disable(silent);
      this.headerDataForm.controls.nonCementingSalesOrderComment.setValue(null, silent);
    }
  }

  private clearCasingEquipmentFromSalesOrder() {
    this.saleOrderNumberService.isSONumberCleared$.next(true);
  }

  private getCountryData() {
    this.previousSelectedCountry = this.headerDataForm.controls.jobCountryId.value;
    if (this.previousSelectedCountry !== USA_COUNTRY) {
      this.countryStateIdControl.setErrors(null);
      this.rigLocationTypeIdControl.setErrors(null);
      this.setValidators();
    }
    else if (this.previousSelectedCountry === USA_COUNTRY && !this.isLand) {
      this.rigLocationTypeIdControl.setErrors(null);
      this.rigLocationTypeIdControl.setValidators(null);
    }
  }

  private setValidators(hasValidation = false) {
    this.countryStateIdControl.setValidators(hasValidation ? [Validators.required] : null);
    this.rigLocationTypeIdControl.setValidators(hasValidation && this.isLand ? [Validators.required] : null);
  }

  openEditWell(){
    const editWellParameters = {
      wellId: this.job.wellId
    };
    this.appState.openWellEdit$.next(editWellParameters);
    
  }
}
