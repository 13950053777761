import { ControlPointStatus, ControlPointType, JobComplienceStatus, JobStatus } from 'apps/vida/src/modules/shared/constant';
import { Job } from 'libs/models';
import * as moment from 'moment-mini';

export function validationProjectedDate(response: any) {
  // handle validate for response in table model
  // eslint-disable-next-line
  if (response && response.hasOwnProperty('data')) {
    response.data = response.data.map((job: Job) => {
      job.isJobDateAlert = alertProjectedDateOverdue(job.projectedDate, job.actualDate);
      return job;
    });
    return response;
  }
  // hanlde in type is job model
  // eslint-disable-next-line
  if (response && response.hasOwnProperty('jobName')) {
    return validationProjectedDateJobOnly(response);
  }
  return response;
}

export function validationProjectedDateJobOnly(job: Job) {
  job.isJobDateAlert = alertProjectedDateOverdue(job.projectedDate, job.actualDate);
  return job;
}

export function alertProjectedDateOverdue(projectedDate, actualDate) {
  if (actualDate == null && projectedDate == null) return false;
  const maxOverdueDayAllow = 3;
  const dateNow = moment({ h: 0, m: 0, s: 0, ms: 0 });

  const projDate = moment(projectedDate);
  const overdueDays = projDate.diff(dateNow, 'days', true);
  return overdueDays <= maxOverdueDayAllow;
}

export function validateAlertJob(job: Job) {
  const nonAlertStatuses = ['Closed', 'Completed', 'Cancelled'];
  return job.isJobDateAlert && nonAlertStatuses.indexOf(job.jobStatusDescription) < 0;
}

export function isJobOutOfComplience(job: Job){
  return getJobComplienceStatus(job) === JobComplienceStatus.OutOfComplience;
}

export function isJobDueSoon(job: Job){
  return getJobComplienceStatus(job) === JobComplienceStatus.DueSoon;
}

function getJobComplienceStatus(job: Job) {
  if (job.jobStatusDescription === JobStatus.Cancelled
    || job.jobStatusDescription === JobStatus.Completed
    || job.jobStatusDescription === JobStatus.Draft
    || job.jobStatusDescription === JobStatus.Closed) {
    return JobComplienceStatus.None;
  }

  let isDueSoon: boolean = false;
  let cpStatus: JobComplienceStatus = getCPComplienceStatus(job, ControlPointType.ControlPoint1);
  if (cpStatus == JobComplienceStatus.OutOfComplience) return JobComplienceStatus.OutOfComplience;
  isDueSoon = cpStatus == JobComplienceStatus.DueSoon;

  cpStatus = getCPComplienceStatus(job, ControlPointType.ControlPoint2);
  if (cpStatus == JobComplienceStatus.OutOfComplience) return JobComplienceStatus.OutOfComplience;
  isDueSoon = isDueSoon || cpStatus == JobComplienceStatus.DueSoon;

  if (!job.ceDirectSales) {
    cpStatus = getCPComplienceStatus(job, ControlPointType.ControlPoint3);
    if (cpStatus == JobComplienceStatus.OutOfComplience) return JobComplienceStatus.OutOfComplience;
    isDueSoon = isDueSoon || cpStatus == JobComplienceStatus.DueSoon;

    cpStatus = getCPComplienceStatus(job, ControlPointType.ControlPoint4);
    if (cpStatus == JobComplienceStatus.OutOfComplience) return JobComplienceStatus.OutOfComplience;
    isDueSoon = isDueSoon || cpStatus == JobComplienceStatus.DueSoon;
  }

  return isDueSoon ? JobComplienceStatus.DueSoon : JobComplienceStatus.None;
}

//User Story 470379: CP General: ATF Status on Active Jobs List
export function getCPComplienceStatus(job: Job, cpType: ControlPointType): JobComplienceStatus{
  let result: JobComplienceStatus = JobComplienceStatus.None;
  if (job.jobStatusDescription === JobStatus.Cancelled
    || job.jobStatusDescription === JobStatus.Completed
    || job.jobStatusDescription === JobStatus.Draft
    || job.jobStatusDescription === JobStatus.Closed) {
    return result;
  }

  if (job.ceDirectSales
    && ((cpType === ControlPointType.ControlPoint3) || (cpType === ControlPointType.ControlPoint4))) {
      return result;
  }

  let jobDate: moment.Moment = job.actualDate ?? job.projectedDate
                ? moment(job.actualDate ?? job.projectedDate)
                : job.createdDateUTC ? moment.utc(job.createdDateUTC)
                : moment(job.createdDate);
  jobDate = jobDate.startOf('date');
  jobDate.set('hours', 23);
  jobDate.set('minutes', 59);
  jobDate.set('seconds', 59);
  const dateNow: moment.Moment = moment();
  const cpStatuses = job.cpStatuses;
  if (cpType ===  ControlPointType.ControlPoint1) {
      const status = cpStatuses.cP1StatusName;
      if (status !== ControlPointStatus.Completed
          && status !== ControlPointStatus.Approved) {
        const hoursDiff = jobDate.diff(dateNow, 'hours');
        //CP 1 goes out of compliance when it is Completed after the Job Date/Time
        //If CP 1 is within 24 hours of going out of compliance,
        //  then indicate so on the CP stoplights on the Active Jobs list with a yellow icon
        result = hoursDiff < 0 ? JobComplienceStatus.OutOfComplience :
              hoursDiff < 24 ? JobComplienceStatus.DueSoon :
              JobComplienceStatus.None;
      }
    }
  else if (cpType === ControlPointType.ControlPoint2) {
      const status = cpStatuses.cP2StatusName;
      if (status !== ControlPointStatus.Completed) {
        const hoursDiff = jobDate.diff(dateNow, 'hours');
        //CP 2 goes out of compliance when it is Prepared after the Job Date/Time,
        //  or if it is Completed 12 hours after the Job Date
        //If CP 2 is within 24 hours of going out of compliance,
        //  then indicate so on the CP stoplights on the Active Jobs list with a yellow icon
        if (status !== ControlPointStatus.Prepared) {
          result = hoursDiff < 0 ? JobComplienceStatus.OutOfComplience :
              hoursDiff < 24 ? JobComplienceStatus.DueSoon :
              JobComplienceStatus.None;
        }
        else {
          result = hoursDiff < -12 ? JobComplienceStatus.OutOfComplience :
              hoursDiff < 24-12 ? JobComplienceStatus.DueSoon :
              JobComplienceStatus.None;
        }
      }
    }
  else
    {
      const status = cpType === ControlPointType.ControlPoint3 ? cpStatuses.cP3StatusName
              : cpStatuses.cP4StatusName;
      if (status !== ControlPointStatus.Completed) {
        const hoursDiff = jobDate.diff(dateNow, 'hours');
        //CP 3 and CP 4 both go out of compliance if they are not completed within 30 days of the job date
        //If CP 3, 4 is within 7 days of going out of compliance,
        //  then indicate so on the CP stoplights on the Active Jobs list with a yellow icon
        result = hoursDiff < - 24 * 30 ? JobComplienceStatus.OutOfComplience :
            hoursDiff < - 24 * 23 ? JobComplienceStatus.DueSoon :
            JobComplienceStatus.None;
      }
    }
  return result;
}

export function isDateFuture(date: string|Date) {
  if (date === null || date === "") {
    return false;
  }

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return moment(date).isAfter(tomorrow);
}
