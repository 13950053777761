export function AutoUnsubscribe(exclude = []) {

  return function (target) {

    const original = target.prototype.ngOnDestroy;
    target.prototype.ngOnDestroy = function () {
      for (const prop in this) {
        const property = this[prop];
        if (!exclude.includes(prop)) {
          if (property && (typeof property.unsubscribe === 'function')) {
            try {
              if (property.closed) {
                continue;
              }

              property.unsubscribe();
            } catch (err) {
              console.error(err);
            }
          }
        }
      }
      // eslint-disable-next-line
      original && typeof original === 'function' && original.apply(this, arguments);
    };
  };
}

export function ManualUnsubscribe(include = []) {
  include.forEach(property => {
    if (property && (typeof property.unsubscribe === 'function')) {
      try {
        property.unsubscribe();
      } catch (err) {
        console.error(err);
      }
    }
  });
}
