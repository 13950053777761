import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ToolTipService } from 'libs/shared/services/tool-tip.service';

@Component({
  selector: 'custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomTooltipComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() htmlText: string;
  @Input() left: number;
  @Input() top: number;

  @ViewChild('main') mainElement: ElementRef;

  private readonly minWidth: number = 100;
  private readonly iconTopOffset: number = 12;
  private readonly iconLeftOffset: number = 6;

  private isShown: boolean = false;

  private onClick: () => void = () => {
    if (!this.isShown)
      this.isShown = !this.isShown;
    else
      this.toolTipService.close();
  };

  constructor(
    private renderer: Renderer2,
    private toolTipService: ToolTipService
  ) { }

  ngAfterViewChecked(): void {
    document.addEventListener('click', this.onClick);
  }

  ngAfterViewInit(): void {
    const div = this.mainElement.nativeElement;
    const height = div.clientHeight
    const width = div.clientWidth > this.minWidth ? div.clientWidth : this.minWidth;

    this.renderer.setStyle(this.mainElement.nativeElement, 'min-width', `${width}px`);
    this.renderer.setStyle(this.mainElement.nativeElement, 'height', `${height}px`);
    this.renderer.setStyle(this.mainElement.nativeElement, 'left', `${this.left - width + this.iconTopOffset}px`);
    this.renderer.setStyle(this.mainElement.nativeElement, 'top', `${this.top - height - this.iconLeftOffset}px`);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.onClick);
  }
}
