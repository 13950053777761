import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MailService } from 'libs/shared/services/mail.service';
@Component({
  selector: 'heading-element',
  templateUrl: './heading-element.component.html',
  styleUrls: ['./heading-element.component.scss']
})
export class HeadingElementComponent implements OnInit {
  @Input() canEdit: boolean;
  @Input() disabled: boolean = false;
  @Input() type: string;
  // eslint-disable-next-line
  @Output() onShowIfactSearchPage = new EventEmitter();
  // eslint-disable-next-line
  @Output() onAddNewFluid = new EventEmitter();
  // eslint-disable-next-line
  @Output() onShowFluidSettings = new EventEmitter();
  // eslint-disable-next-line
  @Output() onShowFluidEditParameter = new EventEmitter();
  @Output() onshowCopyRequest = new EventEmitter();
  isDashboard: boolean = true;

  constructor(private mailService: MailService) {}

  data: any;
  ngOnInit() {
    this.isDashboard = this.type === 'dashboard';
  }
  addNewFluid() {
   // TODO
   this.onshowCopyRequest.emit();
  }

  showIfactSearchPage() {
    this.onShowIfactSearchPage.emit();
  }

  showFluidSettings() {
    this.onShowFluidSettings.emit();
  }
  showFluidEditParameter() {
    this.onShowFluidEditParameter.emit();
  }

  onReportMail() {
    this.mailService.sendReportSAPMismap();
  }
}
