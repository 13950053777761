import { Directive, ElementRef, Input, forwardRef, Injector, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnitType } from 'libs/constants';
import { FormValueAccessorDirective } from '../form-value-accessor.directive';
import { UnitConversionService } from './unit-conversion.service';
import { getDisplayValue, getSourceValue } from './unit-helper';

@Directive({
  selector: '[dUnitConversion]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitConversionDirective),
      multi: true
    }
  ]
})
export class UnitConversionDirective extends FormValueAccessorDirective implements OnChanges {

  readonly DEFAULT_DECIMAL_PLACES = 2;
  _sourceValue: number;
  _targetValue: number;
  _formattedValue: string;
  @Input() dUnitConversion: UnitType;
  @Input() unitDecimalPlaces: number;
  @Input() sackWeight: number;

  constructor(
    protected inj: Injector,
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    protected unitConversionService: UnitConversionService
  ) {
    super(inj, renderer, elementRef);
    this.unitConversionService.onSaveUnit$.subscribe(() => {
      this.renderViewValue(this._sourceValue);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { sackWeight } = changes;
    if (sackWeight) {
      this.renderViewValue(this._sourceValue);
    }
  }

  renderViewValue(value: any) {
    this._sourceValue = value;
    this._targetValue = getDisplayValue(this._sourceValue, this.unitConversionService.getApiUnitMeasure(this.dUnitConversion), this.unitConversionService.getCurrentUnitMeasure(this.dUnitConversion), this.sackWeight);
    this._formattedValue = this._formatNumber(this._targetValue, this.unitDecimalPlaces >= 0 ? this.unitDecimalPlaces : this.DEFAULT_DECIMAL_PLACES);
    if (this.elementRef.nativeElement.localName == "span") {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', this._formattedValue);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._formattedValue);
    }
  }

  writeValueFromViewToModel(value: any) {
    this._targetValue = value;
    this._sourceValue = getSourceValue(this._targetValue, this.unitConversionService.getCurrentUnitMeasure(this.dUnitConversion), this.unitConversionService.getApiUnitMeasure(this.dUnitConversion), this.sackWeight);
    this._propagateChange(this._sourceValue);
  }

  renderViewFocus(isFocus: boolean): void {
    if (isFocus) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._targetValue);
    } else {
      this._formattedValue = this._formatNumber(this._targetValue, this.unitDecimalPlaces >= 0 ? this.unitDecimalPlaces : this.DEFAULT_DECIMAL_PLACES);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._formattedValue);
    }
  }

  private _formatNumber(value, decimalPlaces): string {
    if (value === '' || value == null)
      return null;
    if (isNaN(value))
      return value;
    const result = +value;
    return result.toFixed(decimalPlaces);
  }
}
