import { Directive, Input, ComponentRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingIndicatorContainer } from './loading-indicator.container';
import { isIE } from 'libs/ui/helpers/browser.helper';
import { SubscriptionArray } from './loading-indicator.model';

// PrimeNG issue on IE 11
// https://github.com/primefaces/primeng/blob/6.1.4/src/app/components/blockui/blockui.ts#L55
// Assigning style object is not allowed in IE 11
class WrappedTarget {
  private _target: any;
  private _loadingTarget: any;

  constructor(loadingTarget: any, target: any) {
    this._target = target;
    this._loadingTarget = loadingTarget;
  }

  public getBlockableElement() {
    return this._loadingTarget || this._target;
  }

  set style(style: object) {
    if (isIE()) {
      const keys = Object.keys(style);
      keys.forEach(attribute => {
        this._target.setAttribute(attribute, style[attribute]);
      });
    } else {
      this._target.style = style;
    }
  }
}

@Directive({
  selector: '[loadingIndicator]'
})
export class LoadingIndicatorDirective implements OnInit, OnDestroy {
  @Input() set loadingIndicator(value: Subscription) {
    this.subscriptions.push(value);
    this.createIndicator();
  }

  @Input() loadingTarget: any;
  @Input() loadingMessage: string;

  containerRef: ComponentRef<LoadingIndicatorContainer>;

  private subscriptions = new SubscriptionArray();

  constructor(
    private vcRef: ViewContainerRef,
  ) { }

  ngOnDestroy(): void {
    this.destroyIndicator();
    this.subscriptions.destroy();
  }

  ngOnInit(): void {
    this.createIndicator();
  }

  private createIndicator() {
    if (!this.subscriptions.closed && !this.containerRef) {
      this.containerRef = this.vcRef.createComponent(LoadingIndicatorContainer);
      this.containerRef.instance.target = new WrappedTarget(this.loadingTarget, this.vcRef.element.nativeElement);
      this.containerRef.instance.message = this.loadingMessage;

      this.subscriptions.onComplete(() => this.destroyIndicator());
    }
  }

  private destroyIndicator() {
    if (this.containerRef) {
      this.containerRef.destroy();
      this.containerRef = null;
    }
  }
}
