import { UnitType, APIUnitOfMeasure, SIUnitOfMeasure } from 'libs/constants';

export function convertUnitToSI(value: number, unitType: UnitType) {
  return convertUnit(value, unitType, APIUnitOfMeasure[unitType], SIUnitOfMeasure[unitType]);
}

export function convertUnit(value: any, unitType: UnitType, originUnit: string, destinationUnit: string) {
  if (!value || originUnit === destinationUnit) {
    return value;
  }

  const converters = [
    convertDistance,
    convertInclination,
    convertTemperature,
    convertDensity,
    convertPressure,
    convertVolume,
    convertVolumeMedium,
    convertGasVolume,
    convertFlowRate,
    convertFlowRateGas,
    convertSmallDistance,
    convertWeightLength,
    convertYield,
    convertLiquidsSolidsRatio,
    convertWeight
  ];

  if (unitType === UnitType.WeightLength) {
    return convertWeightLength(value, destinationUnit, originUnit);
  }

  if (unitType === UnitType.LargeDistance) {
    return convertDistance(value, destinationUnit, originUnit);
  }

  return converters[unitType](value, destinationUnit, originUnit);
}

function convertDistance(value, destinationUnit, originUnit) {
  // const ratio = 0.3048;

  // if (destinationUnit === 'm') {
  //   return parseFloat((value * ratio).toFixed(8));
  // }

  // return parseFloat((value / ratio).toFixed(8));
  return convert(value, originUnit, destinationUnit).toFixed(8);

}
// eslint-disable-next-line
function convertInclination(value, destinationUnit, originUnit) {
  return value;
}

export function convertTemperature(value, destinationUnit, originUnit) {
  if (destinationUnit === '°C' && originUnit === '°F') {
    return (value - 32) * 5 / 9;
  }

  if (destinationUnit === '°F' && originUnit === '°C') {
    return (value * 9 / 5) + 32;
  }

  return value;

}

function convertDensity(value, destinationUnit, originUnit) {
  return convert(value, originUnit, destinationUnit);
}

function convertPressure(value, destinationUnit, originUnit) {
  // const ratio = 0.00689475728;

  // if (destinationUnit === 'Mpa') {
  //   return value * ratio;
  // }

  // return value / ratio;
  return convert(value, originUnit, destinationUnit);
}

function convertVolume(value, destinationUnit, originUnit) {
  // const ratio = 0.158987294928;

  // if (destinationUnit === 'm3') {
  //   return parseFloat((value * ratio).toFixed(8));
  // }

  // return parseFloat((value / ratio).toFixed(8));
  return convert(value, originUnit, destinationUnit).toFixed(8);

}

function convertVolumeMedium(value, destinationUnit, originUnit) {
  // const ratio = 3.785411784;

  // if (destinationUnit === 'L') {
  //   return value * ratio;
  // }

  // return value / ratio;
  return convert(value, originUnit, destinationUnit);
}
// eslint-disable-next-line
function convertGasVolume(value, destinationUnit, originUnit) {
  const ratio = 28316846592;

  if (destinationUnit === 'mm3') {
    return value * ratio;
  }

  return value / ratio;
}

function convertFlowRate(value, destinationUnit, originUnit) {
  // const ratio = 0.1589873;

  // if (destinationUnit === 'm3/min') {
  //   return parseFloat((value * ratio).toFixed(8));
  // }

  // return parseFloat((value / ratio).toFixed(8));
  return convert(value, originUnit, destinationUnit).toFixed(8);

}
// eslint-disable-next-line
function convertFlowRateGas(value, destinationUnit, originUnit) {
  return value;
}

function convertSmallDistance(value, destinationUnit, originUnit) {
  // const ratio = 2.54;

  // if (destinationUnit === 'cm') {
  //   return Math.round(value * ratio * 1000) / 1000;
  // }
  // return Math.round(value / ratio * 1000) / 1000;
  return Math.round(convert(value, originUnit, destinationUnit) * 1000) / 1000;
}

function convertWeightLength(value, destinationUnit, originUnit?) {
  const ratioPoundTokilo = 0.138254954376;
  const ratioPoundToNewton = 1.355817948;
  let result = value ? value : 0;

  if (destinationUnit === SIUnitOfMeasure[UnitType.WeightLength]) {
    result = value * ratioPoundTokilo;
  }

  if (originUnit === 'Newtons/mtr' && destinationUnit === APIUnitOfMeasure[UnitType.WeightLength]) {
    result = value / ratioPoundToNewton;
  }

  if (destinationUnit === APIUnitOfMeasure[UnitType.WeightLength]) {
    result = value / ratioPoundTokilo;
  }

  if (originUnit === APIUnitOfMeasure[UnitType.WeightLength] && destinationUnit === 'Newtons/mtr') {
    result = value * ratioPoundToNewton;
  }

  return result;
}

function convertYield(value, destinationUnit, originUnit) {
  return convert(value, originUnit, destinationUnit);
}

function convertLiquidsSolidsRatio(value, destinationUnit, originUnit) {
  return convert(value, originUnit, destinationUnit);
}

function convertWeight(value, destinationUnit, originUnit) {
  return convert(value, originUnit, destinationUnit);
}

function convert(value: number, fromMeasure: any, toMeasure: any): number {
  if (fromMeasure === toMeasure) {
    return value;
  }

  fromMeasure = findMeasure(fromMeasure);
  toMeasure = findMeasure(toMeasure);

  if (fromMeasure === undefined || toMeasure === undefined) {
    return null;
  }

  const a = value;
  const b = getMeasureScale(fromMeasure);
  const c = getMeasureOffset(fromMeasure);
  const d = getMeasureOffset(toMeasure);
  const e = getMeasureScale(toMeasure);
  const f = ((a / b) - c + d) * (e);

  // console.log(`CONVERSION of ${value} from ${fromMeasure} to ${toMeasure} giving ${f}`)

  return f;
}

function findMeasure(measure) {
  return unitMeasureData.get(measure);
}

// function getMeasureMultiplier(measureInfo: any[]) {
//   return measureInfo[0];
// }

function getMeasureScale(measureInfo: any[]) {
  return measureInfo[0];
}

function getMeasureOffset(measureInfo: any[]) {
  return measureInfo[1];
}
const unitMeasureData = new Map([
  // [ name_of_measure, [scale, offset]]
  ['ft3/lb', [1, 0]],
  // eslint-disable-next-line
  ['L/100kg', [6242.79605761446000000000, 0]], //This number literal will lose precision at runtime.
  ['ft3/sack', [94, 0]],
  // eslint-disable-next-line
  ['gal/sack', [703.16882883102300000000, 0]],
  // eslint-disable-next-line
  ['L/1000kg', [62427.96057614460000000000, 0]],
  // eslint-disable-next-line
  ['L/kg', [62.42796057614460000000, 0]],
  // eslint-disable-next-line
  ['m3/kg', [0.06242796057614460000, 0]],
  // eslint-disable-next-line
  ['US gal/100 lbm', [748.05194556491800000000, 0]],
  // eslint-disable-next-line
  ['US gal/LBM', [7.48051945564918000000, 0]],
  // eslint-disable-next-line
  ['m3/sk', [2.66178465465748000000, 0]],
  // eslint-disable-next-line
  ['L/sk', [2661.78465465748000000000, 0]],
  // eslint-disable-next-line
  ['m3/MT', [62.42796057614460000000, 0]],
  // eslint-disable-next-line
  ['Gal/sk', [703.16882883102300000000, 0]],
  // eslint-disable-next-line
  ['kg/m3', [16.01846342721630000000, 0]],
  // eslint-disable-next-line
  ['ppg', [0.13368055555555500000, 0]],
  // eslint-disable-next-line
  ['SG', [0.01601846342721630000, 0]],
  // eslint-disable-next-line
  ['kPa/m', [0.1570874643685100000, 0]],

  ['m', [0.3048, 1]],
  ['ft', [1, 1]],
  ['kPa', [6.8947573, 1]],
  ['Mpa', [0.0068947573, 1]],
  ['bar', [0.068947573, 1]],
  ['psi', [1, 1]],
  ['m3', [0.1589872949, 1]],
  ['bbl', [1, 1]],
  ['L', [0.2641720524, 1]],
  ['gal', [1, 1]],
  ['cm', [2.54, 1]],
  ['mm', [25.4, 1]],
  ['in', [1, 1]],
  ['m3/min', [0.1589873, 1]],
  ['bbl/min', [1, 1]],
  ['miles', [1, 1]],
  ['km', [1.609344, 1]],
  ['lbs', [1, 1]],
  ['kg', [0.45359237, 1]],
  ['metric ton', [0.000453592, 1]],
]);
