import { FluidModel, PumpScheduleStageModel } from "libs/models";
import { IFactsAvailableSlurry } from "libs/models/ifact/ifacts-available-slurry";
import { PumpScheduleStageTest, PumpScheduleStageTestField, PumpScheduleStageTestTable } from "libs/models/ifact/ifacts-request-tests";
import { TestTableNameTestNamesMapping, TestTableNames } from "./stage-test-results.constant";

export function UpdateStageTests(stage: PumpScheduleStageModel, fluid: FluidModel) {

    const slurries = fluid?.requestInfo?.availableSlurries;
    if (!slurries || !stage.pumpScheduleStageTestTables) return;

    const requestId = fluid?.requestInfo.id;

    const testTables = TestTableNames.map(tableName => convert(tableName, requestId, stage, slurries)).filter(t => t);

    stage.pumpScheduleStageTestTables = testTables;

    return stage;
}

function convert(testTableName: string, requestId: number, stage: PumpScheduleStageModel, slurries: IFactsAvailableSlurry[]) {

    const selectedSlurryNo = getSlurryNo(testTableName, stage, slurries);

    if (!selectedSlurryNo) return;

    const slurry = slurries.find(s => s.number === selectedSlurryNo);
    const testsFilteredByType = slurry.tests.filter(t => t.slurryId === slurry.id && TestTableNameTestNamesMapping[testTableName].includes(t.testName));
    const deletedTests = getDeletedTests(stage);


    const tests = testsFilteredByType.map(t => {
        const testResults = t.testResult?.map(f =>
            new PumpScheduleStageTestField(
                null,
                null,
                f.testFieldId,
                f.testFieldDesc,
                f.result)) || [];

        return new PumpScheduleStageTest(
            null,
            null,
            t.requestTestId,
            t.testName,
            t.testStatusDescription,
            t.testTempF,
            deletedTests.includes(t.requestTestId),
            requestId,
            t.slurryNumber,
            t.slurryId,
            testResults);
    }).sort((a, b) => a.requestTestId - b.requestTestId);

    const testTable = new PumpScheduleStageTestTable(null, stage.id, testTableName, selectedSlurryNo, tests);

    return testTable;

}

function getSlurryNo(testTableName: string, stage: PumpScheduleStageModel, slurries: IFactsAvailableSlurry[]): number {

    let selectedSlurryNo = stage.pumpScheduleStageTestTables.find(t => t.name === testTableName)?.selectedSlurryNo;

    if (!selectedSlurryNo || !slurries.filter(s => s.tests.some(t => TestTableNameTestNamesMapping[testTableName].includes(t.testName))).find(s => s.number === selectedSlurryNo)) {

        selectedSlurryNo = stage.slurry.slurryNo;
        const slurry = slurries.find(s => s.number === selectedSlurryNo && s.tests.some(t => TestTableNameTestNamesMapping[testTableName].includes(t.testName)));

        if (!slurry) {
            selectedSlurryNo = slurries.sort((a, b) => b.number - a.number)
                .filter(x => x.number <= selectedSlurryNo)
                .find(s => s.tests.some(t => TestTableNameTestNamesMapping[testTableName].includes(t.testName)))?.number;
        }
    }

    return selectedSlurryNo;
}

function getDeletedTests(stage: PumpScheduleStageModel) {
    const deletedTests = stage.pumpScheduleStageTestTables?.reduce((acc, psstt) => {
        const dt = psstt.pumpScheduleStageTests.filter(y => y.isDeleted);
        if (dt.length) {
            acc = acc.concat(dt.map(u => u.requestTestId));
        }
        return acc;
    }, []) || [];

    return deletedTests;
}
